import { Button, message, Modal, Select, Typography } from 'antd'
import React from 'react'
import debounce from 'src/utils/debounce'
import { getAll } from 'src/api/playlist'
import { updatePlaylists } from 'src/api/category'
import s from './index.module.scss'
import useForceRender from 'src/utils/ForceRender'

const { Option } = Select
const { Title } = Typography

interface AddingModalProps {
  category: any
  visible: boolean
  onDone: () => void
  onCancel: () => void
}

const AddingModal: React.FC<AddingModalProps> = ({
  category,
  visible,
  onDone,
  onCancel,
}) => {
  const forceRender = useForceRender()
  const selectedPlaylist = React.useRef<Playlist>(null)
  const selectLoading = React.useRef<boolean>(false)
  const addLoading = React.useRef<boolean>(false)
  const playlists = React.useRef<Playlist[]>([])

  const handleSearch = async (value) => {
    selectLoading.current = true
    forceRender()
    const { data } = await getAll({ name: value, size: 50 })
    playlists.current = data
    selectLoading.current = false
    forceRender()
  }

  const resetModal = React.useCallback(() => {
    selectedPlaylist.current = null
    selectLoading.current = false
    addLoading.current = false
    playlists.current = []
    forceRender()
  }, [])

  const handleSelect = React.useCallback((option) => {
    selectedPlaylist.current = {
      id: option.value,
      name: option.label,
    }
    forceRender()
  }, [])

  const handleAdd = React.useCallback(async () => {
    if (!selectedPlaylist.current) {
      message.warn('Select a playlist first !')
      return
    }
    addLoading.current = true
    forceRender()
    const playlistIds = category?.playlists.map((i) => i.id)
    await updatePlaylists(category?.id, [
      ...playlistIds,
      selectedPlaylist.current.id,
    ])
    message.success(
      `${selectedPlaylist.current?.name} is added to ${category.name}`
    )
    resetModal()
    onDone && onDone()
  }, [selectedPlaylist.current, category])

  const handleCancel = React.useCallback(() => {
    resetModal()
    onCancel && onCancel()
  }, [])

  const filteredResults = playlists.current?.filter((item) => {
    return !category?.playlists.find((i) => i.id === item.id)
  })

  return (
    <>
      <Modal
        title={`Add playlist to ${category?.name}`}
        visible={visible}
        onCancel={handleCancel}
        footer={[
          <Button
            type="primary"
            key="add"
            onClick={handleAdd}
            loading={addLoading.current}
          >
            Add
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <Title level={5}>{category?.playlists?.length} playlists added</Title>
        <Select
          allowClear
          showSearch
          autoFocus
          filterOption={false}
          onSearch={debounce(handleSearch, 500)}
          onSelect={handleSelect}
          size="large"
          placeholder="Search playlist"
          labelInValue={true}
          value={
            selectedPlaylist.current
              ? {
                  label: selectedPlaylist.current.name,
                  value: selectedPlaylist.current.id,
                }
              : null
          }
          className={s.searchSelect}
          onClear={resetModal}
          loading={selectLoading.current}
        >
          {filteredResults?.map((item) => (
            <Option key={item.id}>{item.name}</Option>
          ))}
        </Select>
      </Modal>
    </>
  )
}

AddingModal.displayName = 'AddingModal'
export default AddingModal
