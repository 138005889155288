import { message } from 'antd'
import { fetchUtil as fetch, buildQuery } from 'src/utils/fetch'
import { omit } from 'ramda'

export const getAll = async (params: Record<string, any>) => {
  const query = buildQuery(params)
  try {
    const { data } = await fetch(`/playlist/tags/?${query}`)
    return data
  } catch (e) {
    message.error('Tag getAll error')
  }
}

export const search = async (params) => {
  try {
    const query = buildQuery(params)
    const { data } = await fetch(`/playlist/tags/search/?${query}`)
    return data
  } catch (e) {
    message.error('Tag search error')
  }
}

export const getById = async (id) => {
  try {
    const { data } = await fetch(`/playlist/tag/${id}`)
    return data
  } catch (e) {
    message.error('Tag getById error')
  }
}

export const create = async (data) => {
  try {
    const body = new FormData()
    Object.entries(data)
      .filter(([name]) => ['image', 'name', 'type'].includes(name))
      .forEach(([name, value]: any) => {
        if (!value) return
        if (name === 'image') {
          body.append(name, value.file, value.file.name)
        } else {
          body.append(name, value)
        }
      })
    return await fetch(`/playlist/tag/`, {
      method: 'POST',
      body,
    })
  } catch (e) {
    message.error('Tag create error')
  }
}

export const deleteTag = async (id) => {
  try {
    return await fetch(`/playlist/tag/${id}`, {
      method: 'DELETE',
    })
  } catch (e) {
    message.error('Tag deleteTag error')
  }
}

export const fetchAllByGroup = async () => {
  try {
    const { data } = await getAll({
      page: 0,
      size: 1000,
    })
    return data?.reduce(
      (data, current) => {
        if (current.type === 'GENRE') {
          data.GENRE.push(current)
        } else if (current.type === 'ACTIVITY') {
          data.ACTIVITY.push(current)
        } else {
          data.MOOD.push(current)
        }
        return data
      },
      {
        GENRE: [],
        ACTIVITY: [],
        MOOD: [],
      }
    )
  } catch (e) {
    message.error('fetchAllByGroup error')
  }
}

export const updateOrCreate = async (
  id: string,
  payload: Record<string, any>
) => {
  try {
    let res = null
    if (id && id !== 'new') {
      res = await fetch(`/playlist/tag/${id}`, {
        method: 'PATCH',
        body: JSON.stringify(omit(['image'], payload)),
      })
    } else {
      res = await create(payload)
    }
    return res
  } catch (e) {
    message.error('updateOrCreate error !')
  }
}

export const uploadImage = async (id: string, file: any) => {
  try {
    const body = new FormData()
    body.append('image', file, file.name)
    return await fetch(`/playlist/tag/${id}/image`, {
      method: 'PUT',
      body,
    })
  } catch (e) {
    message.error('uploadImage error !')
  }
}
