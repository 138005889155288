import React, { useEffect, useCallback } from 'react'
import { Divider, Typography, Spin, message } from 'antd'
import { DndProvider } from 'react-dnd'
import CarouselItem from './carousel-item'
import Carousel from './carousel'
import { HTML5Backend } from 'react-dnd-html5-backend'
import useForceRender from 'src/utils/ForceRender'
import { clone } from 'ramda'
import * as api from 'src/api/metaverse'
import s from './index.module.scss'

const { Title } = Typography

const MetaverseDiscovery: React.FC = () => {
  const forceRender = useForceRender()
  const selectedMetaverse = React.useRef<Metaverse>(null)
  const showAdding = React.useRef<boolean>(false)
  const loading = React.useRef<boolean>(true)
  const data = React.useRef<any[]>([])

  const loadPageData = useCallback(() => {
    api.getAllRoomCategories().then((categories) => {
      loading.current = false
      data.current = categories
      forceRender()
    })
  }, [])

  useEffect(() => {
    loadPageData()
  }, [])

  const onModalCancel = React.useCallback(() => {
    selectedMetaverse.current = null
    showAdding.current = false
    forceRender()
  }, [])

  const onAddClick = React.useCallback((category: MetaverseRoomCategory) => {
    selectedMetaverse.current = category
    showAdding.current = true
    forceRender()
  }, [])

  const onModalDone = React.useCallback(() => {
    onModalCancel()
    loadPageData()
  }, [])

  const updateOrdering = React.useCallback(
    async (dragId: string, dropId: string, category: MetaverseRoomCategory) => {
      const categoryNew = data.current.find((cat: MetaverseRoomCategory) => cat.id === category.id) as MetaverseRoomCategory;

      const dragIndex = categoryNew.rooms.findIndex((i) => i.id === dragId);
      const dropIdIndex = categoryNew.rooms.findIndex((i) => i.id === dropId);

      let dropIndex: number;

      if (dropIdIndex === 0) {
        
        
        dropIndex = dropIdIndex;
      } else if (dragIndex > dropIdIndex) {
      
        dropIndex = dropIdIndex;
      } else {
        
        dropIndex = dropIdIndex+1;
      }
      
      const drapItem = categoryNew.rooms[dragIndex];
      const tempItem = { id: 'temp' } as any;
      const cloneItems = [...categoryNew.rooms];
      cloneItems[dragIndex] = tempItem;
      cloneItems.splice(dropIndex, 0, drapItem);
      const orderedItems = cloneItems.filter((i) => i.id !== 'temp');
  
      // Update sort indexes based on category type
      orderedItems.forEach((item, index) => {
        if (category.id === 'MUSIC') {
          
          item.musicSortIndex = index+1;
        } else if (category.id === 'COUNTRIES') {
          item.countrySortIndex = index+1;
        }    else if (category.id === 'TWINS') {
          item.twinSortIndex = index+1;
        } 
        
      });
  
      // Update the category with the ordered items
      const updatedCategory = {
        ...categoryNew,
        rooms: orderedItems,
      };
  
      loading.current = true;
      forceRender();
      await api.updateMetaverseOrder(categoryNew.id, updatedCategory);
      message.success(`Updated metaverse ordering of ${categoryNew.type}`);
      loadPageData();
    },
    [data]
  );
  

  return (
    <Spin spinning={loading.current}>
      <Title level={3}>Metaverese</Title>
      <Divider />
      <DndProvider backend={HTML5Backend}>
        {data.current?.map((category) => (
          <Carousel
            key={category.id}
            name={category.type}
            onAddClick={() => onAddClick(category)}
          >
            {category.rooms?.length ? (
              category.rooms.map((item) => (
                <CarouselItem
                  key={item.id}
                  metaverse={item}
                  categoryId={category.id}
                  onDrop={(drapId, dropId) =>
                    updateOrdering(drapId, dropId, category)
                  }
                  
                />
              ))
            ) : (
              <span className={s.noData}> No data</span>
            )}
          </Carousel>
        ))}
      </DndProvider>
     
    </Spin>
  )
}

MetaverseDiscovery.displayName = 'Discovery'

export default MetaverseDiscovery
