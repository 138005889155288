import { useState } from 'react'
import { Card, Spin } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import './Gamecard.css'
import { useNavigate } from 'react-router-dom'

const GameCard = ({
  id,
  img,
  thumbImg,
  title,
  titleFr,
  desc,
  descFr,
  instruction,
  instructionFr,
  categories,
  enabled,
}) => {
  const navigate = useNavigate()

  const params = new URLSearchParams()
  params.append('img', img)
  params.append('thumbImg', thumbImg)
  params.append('title', title)
  params.append('titleFr', titleFr)
  params.append('desc', desc)
  params.append('descFr', descFr)
  params.append('instruction', instruction)
  params.append('instructionFr', instructionFr)
  params.append('enabled', enabled)
  categories.forEach((category) => params.append('categories', category))

  const [dataLoaded, setDataLoaded] = useState(true)

  const handleClickOpen = () => {
    navigate(`/games/edit/${id}?${params.toString()}`)
  }

  return (
    <div>
      {dataLoaded ? (
        <Card
          hoverable
          className={`card-container ${enabled ? '' : 'faded-card'}`}
          cover={
            <div className="card-cover">
              <img alt={title} src={img} className="card-image" />
              <EditOutlined onClick={handleClickOpen} className="edit-icon" />
            </div>
          }
        >
          <Card.Meta
            title={title}
            description={<div className="card-description">{desc}</div>}
          />
        </Card>
      ) : (
        <div className="spinner-container">
          <Spin size="large" />
        </div>
      )}
    </div>
  )
}

export default GameCard
