import React from 'react'
import { Space, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import s from './index.module.scss'

interface CarouselProps {
  name: string
  onAddClick: (payload) => void
  children?: any
}

const Carousel: React.FC<CarouselProps> = ({ children, name, onAddClick }) => {
  const shouldShowButton = ['EXSCAPE CHOICES', 'CASUAL', 'MASTERMIND'].includes(name);
  return (
    <div className={s.carousel}>
      <div className={s.carouselTitle}>
        <p>{name}</p>
        {shouldShowButton && (   <Button type="primary" size="small" onClick={onAddClick}>
          <PlusOutlined /> Add Games
        </Button>
        )}
      </div>
      <Space size={[8, 16]} wrap className={s.carouselSpace} align="center">
        {children || <p className={s.noData}>No data</p>}
      </Space>
    </div>
  )
}

export default Carousel
