import { Button, Input, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { search } from 'src/api/user'
import { fetchCountryProvider } from 'src/api/authen'
import { countryCodes } from 'src/constants'

interface UserSearchProps {
  appendSearchForm: (obj) => void
  submitSearchForm: (obj?: any) => void
}

const UserSearch: React.FC<UserSearchProps> = ({
  appendSearchForm,
  submitSearchForm,
}) => {
  const [loading, setLoading] = useState(false)
  const [providerCountry, setProviderCountry] = useState('')
  const [countries, setCountries] = useState([])
  const handleSearch = React.useCallback(async (value) => {
    if (!value) return
    setLoading(true)
    const data = await search({ name: value, size: 50 })

    setLoading(false)
  }, [])
  const { Option } = Select
  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const data = await fetchCountryProvider()
        const countryOptions = data.data.map((item) => ({
          label: item.countryName,
          value: item.countryCode,
        }))
        setCountries(countryOptions)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchCountry()
  }, [])
  return (
    <div>
      <Input
        placeholder="Name"
        allowClear
        onChange={(e) => appendSearchForm({ name: e.target.value })}
        className="search-input"
      />
      <Input
        placeholder="User Name"
        allowClear
        onChange={(e) => appendSearchForm({ username: e.target.value })}
        className="search-input"
      />
      <Input
        placeholder="email"
        allowClear
        onChange={(e) => appendSearchForm({ email: e.target.value })}
        className="search-input"
      />
      <Input.Group compact>
        <Select
          placeholder="Country Code"
          onChange={(value) => appendSearchForm({ countryCode: value })}
          allowClear
          onClear={() => appendSearchForm({ countryCode: '' })}
          showSearch
          className="search-input"
        >
          {countryCodes.map(({ code, name }) => (
            <Option key={code} value={code}>
              {code} ({name})
            </Option>
          ))}
        </Select>
        <Input
          className="search-input"
          placeholder="Number"
          allowClear
          onChange={(e) => appendSearchForm({ phoneNumber: e.target.value })}
        />
      </Input.Group>
      <Select
        placeholder="Provider Country"
        onChange={(value) => appendSearchForm({ providerCountry: value })}
        allowClear
        onClear={() => appendSearchForm({ providerCountry: '' })}
        showSearch
        className="search-input"
      >
        {countries.map((country) => (
          <Select.Option value={country.value} key={country.value}>
            {country.label}
          </Select.Option>
        ))}
      </Select>

      <Button
        type="primary"
        icon={<SearchOutlined />}
        onClick={() => submitSearchForm()}
        className="search-btn"
      >
        Search
      </Button>
    </div>
  )
}

UserSearch.displayName = 'UserSearch'
export default UserSearch
