import { useCallback, useRef, useState } from 'react'
import { Button } from 'antd'
import { PlayCircleOutlined, PauseCircleTwoTone } from '@ant-design/icons'
import { manualFetch } from 'src/utils/fetch'

const StreamAudioPlayer = ({ src }) => {
  const [loading, setLoading] = useState(false)
  const [playing, setPlaying] = useState(false)

  const audioCtx = useRef<AudioContext>(null)

  const playChunk = useCallback(
    (reader: ReadableStreamDefaultReader) => {
      reader.read().then(({ done, value }) => {
        audioCtx.current.decodeAudioData(
          value.buffer,
          function (buffer) {
            const source = audioCtx.current.createBufferSource()
            source.connect(audioCtx.current.destination)
            source.buffer = buffer
            source.start(0)
            setPlaying(true)
            source.onended = () => {
              if (!done) {
                playChunk(reader)
              } else {
                disposeStream()
              }
            }
          },
          function (e) {
            console.error('playChunk error -- ', e)
            disposeStream()
          }
        )
      })
    },
    [src]
  )

  const disposeStream = useCallback(() => {
    audioCtx.current?.close()
    audioCtx.current = null
    setPlaying(false)
  }, [])

  const requestStream = useCallback(() => {
    setLoading(true)
    manualFetch(src, {
      headers: {
        accept: 'audio/*',
      },
    })
      .then((response) => {
        setLoading(false)
        audioCtx.current = new window.AudioContext()
        const reader = response.body.getReader()
        playChunk(reader)
      })
      .catch((e) => {
        console.error('requestStream error -- ', e)
        setLoading(false)
      })
  }, [src])

  return (
    <Button
      icon={playing ? <PauseCircleTwoTone /> : <PlayCircleOutlined />}
      loading={loading}
      onClick={playing ? disposeStream : requestStream}
    />
  )
}

export default StreamAudioPlayer
