import React, { useRef, useState } from 'react'
import { Button, Divider, Pagination, Spin, Typography } from 'antd'
import AddingModal from './adding-modal'
import Thumb from './thumb'
import s from './index.module.scss'
import { PlusOutlined } from '@ant-design/icons'
import { getAll } from 'src/api/image'
import usePage from 'src/hooks/usePage'

const { Title } = Typography

const PlaylistImages: React.FC = () => {
  const [showAdding, setShowAdding] = useState<boolean>()
  const { pageData, onQuery, fetchList } = usePage({
    getData: getAll,
    query: {
      size: 24,
      type: 'PLAYLIST',
    },
  })
  const selectedImage = useRef<any>({})

  const onAddClick = React.useCallback(() => {
    selectedImage.current = {}
    setShowAdding(true)
  }, [])

  const onEditClick = React.useCallback((image: Image) => {
    selectedImage.current = image
    setShowAdding(true)
  }, [])

  const onCancelAdding = React.useCallback(() => {
    selectedImage.current = {}
    setShowAdding(false)
  }, [])

  const onDoneAdding = React.useCallback(() => {
    selectedImage.current = {}
    setShowAdding(false)
    fetchList()
  }, [])

  return (
    <Spin spinning={pageData.loading}>
      <Title level={3} style={{ marginBottom: 0 }}>
        Playlist Images
      </Title>
      <Divider />
      <div className={s.topBar}>
        <Title level={5} style={{ marginBottom: 0 }}>
          Total: {pageData.total}
        </Title>
        {/* <Search
          placeholder="Search by name"
          onSearch={(v) => onQuery({ page: '0', name: v })}
          allowClear
          style={{ width: 250, marginRight: 24 }}
          defaultValue={queryObj['name']}
        /> */}
        <Button
          type="primary"
          shape="circle"
          icon={<PlusOutlined />}
          size="large"
          onClick={onAddClick}
        />
      </div>
      <div className={s.thumbWrapper}>
        {pageData.data?.length ? (
          pageData.data.map((item) => (
            <Thumb
              key={`${item.originalFileName}_${item.id}`}
              image={item}
              onEditClick={onEditClick}
              onDone={fetchList}
            />
          ))
        ) : (
          <p className={s.noData}>No data</p>
        )}
      </div>
      <Pagination
        defaultCurrent={6}
        current={parseInt(pageData.query.page) + 1}
        total={pageData.total}
        onChange={(p) => onQuery({ page: (p - 1).toString() })}
        pageSize={pageData.query?.size}
        className={s.pagination}
      />
      <AddingModal
        type="PLAYLIST"
        image={selectedImage.current}
        visible={showAdding}
        onDone={onDoneAdding}
        onCancel={onCancelAdding}
      />
    </Spin>
  )
}

PlaylistImages.displayName = 'PlaylistImages'
export default PlaylistImages
