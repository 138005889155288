import React, { useRef } from 'react'
import { Button, Modal, Typography } from 'antd'
import { useDrag, useDrop } from 'react-dnd'
import PrivateImage from 'src/components/PrivateImage'
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import s from './index.module.scss'

const { Text } = Typography

interface CarouselItemProps {
  playlist: Playlist
  categoryId: string
  onDrop: (drapId: string, dropId: string) => void
  onRemove: (playlist: Playlist) => void
}

const CarouselItem: React.FC<CarouselItemProps> = ({
  playlist,
  categoryId,
  onDrop = () => null,
  onRemove = () => null,
}) => {
  const ref = useRef(null)
  const dndType = `card_${categoryId}`
  const [, drag] = useDrag(() => ({
    type: dndType,
    item: {
      id: playlist.id,
    },
  }))
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: dndType,
      drop(item: any) {
        onDrop(item.id, playlist.id)
      },
      collect: (monitor) => {
        return {
          isOver: monitor.isOver({ shallow: true }),
        }
      },
    }),
    []
  )

  const onRemoveClick = React.useCallback(() => {
    Modal.confirm({
      title: (
        <p>
          You&apos;re deleting <strong>{playlist.name}</strong>
        </p>
      ),
      icon: <ExclamationCircleOutlined />,
      onOk: () => onRemove && onRemove(playlist),
    })
  }, [playlist])

  drop(drag(ref))

  

  return (
    <div
    
      className={s.carouselItem}
    >
      <div ref={ref} className={s.carouselItemCard}>
        <PrivateImage
          src={playlist.cover_url}
          width={'100%'}
          height={140}
          preview={false}
        />
        <div className={s.carouselItemMeta}>
          <Text strong>{playlist.name}</Text>
          <Text italic type="secondary">
            Tracks: {playlist.number_tracks}
          </Text>
        </div>
        <Button
          type="primary"
          danger
          shape="circle"
          icon={<DeleteOutlined />}
          size="small"
          className={s.carouselItemDelBtn}
          onClick={onRemoveClick}
        />
      </div>
    </div>
  )
}
export default CarouselItem
