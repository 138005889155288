import { Layout as AntLayout } from 'antd'
import { Outlet } from 'react-router-dom'
import Sidebar from 'src/components/Sidebar'

const { Content } = AntLayout

const Layout: React.FC<any> = ({ blank }) => {
  if (blank) {
    return <Outlet />
  }
  return (
    <AntLayout>
      <Sidebar />
      <AntLayout>
        <Content className="site-layout">
          <div className="site-layout--content">
            <Outlet />
          </div>
        </Content>
      </AntLayout>
    </AntLayout>
  )
}

Layout.displayName = 'Layout'
export default Layout
