import { fetchUtil as fetch } from 'src/utils/fetch'
import { message } from 'antd'

export const register = (name, username, email, password) => {
  return fetch(`/auth/registration`, {
    method: 'POST',
    body: JSON.stringify({
      name,
      username,
      email,
      hashedPassword: password,
    }),
    authorization: false,
  })
}

export const login = (username, password) => {
  return fetch(
    `/auth/oauth/token?grant_type=password&username=${username}&password=${password}&action=USERNAME`,
    {
      method: 'POST',
      authorize: true,
    }
  )
}
export const verifyOtp = (email, otp) => {
  return fetch(`/auth/verify-otp`, {
    method: 'POST',
    body: JSON.stringify({
      otp,
      email,
      action: 'email-verification',
    }),
    authorization: false,
  })
}

export const resendOtp = (name, email) => {
  return fetch(`/auth/resend-otp`, {
    method: 'POST',
    body: JSON.stringify({
      name,
      email,
    }),
    authorization: false,
  })
}

export const fetchUserInfo = async () => {
  try {
    const { data } = await fetch(`/auth/user/user-info`)
    return data
  } catch (e) {
    message.error('fetchUserInfo error')
  }
}

export const fetchCountryProvider = async () => {
  try {
    const { data } = await fetch(`/auth/country`)
    return data
  } catch (e) {
    message.error('fetchCountryProvider error')
  }
}

export const fetchAdminUsers = async (
  page: number,
  size: number,
  username = ''
) => {
  try {
    const requestBody: {
      page: number
      size: number
      username?: string
    } = {
      page,
      size,
    }
    if (username !== '') {
      requestBody.username = username
    }
    const { data } = await fetch('/auth/adminUsers', {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        role: 'ADMIN',
      },
    })
    return data
  } catch (e) {
    message.error('Admin Users Error!')
  }
}

export const editAdminUsers = async (username, permissions) => {
  return fetch(`/auth/permissions/${username}`, {
    method: 'PATCH',
    body: JSON.stringify(permissions),
  })
}
