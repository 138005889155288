import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Routes, Route, Navigate, Router } from 'react-router-dom'
import Layout from 'src/components/Layout'
import ProtectedRoute from 'src/components/ProtectedRoute'
import routes from '../routes'
import { getToken } from 'src/utils/accessToken'
import { fetchUserInfo } from 'src/api/authen'
import AppContext from 'src/hooks/AppContext'
import { message } from 'antd'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import history from 'src/utils/history'

type AppCtxData = Omit<AppContextData, 'restore'>

export const appLoad = async (): Promise<AppCtxData> => {
  const data = {
    userInfo: {} as any,
    appConfig: {} as any,
    isAuth: false,
  }
  try {
    if (getToken()) {
      const userInfo = await fetchUserInfo()
      data.userInfo = userInfo.data
      data.isAuth = data.userInfo?.role === 'ADMIN'
    }
    return data
  } catch (e) {
    message.error('appLoad failed', e.message)
  }
}

const App: React.FC = () => {
  const [appContextData, setAppContextData] = useState<AppCtxData>()

  const [router, setRouter] = useState({
    action: history.action,
    location: history.location,
  })

  useLayoutEffect(() => history.listen(setRouter), [history])

  useEffect(() => {
    appLoad().then((data) => {
      setAppContextData(data)
    })
  }, [])

  if (!appContextData) {
    return (
      <div className="loading-page">
        <Loading3QuartersOutlined className="loading-page__icon" spin />
      </div>
    )
  }

  return (
    <AppContext.Provider
      value={{
        ...appContextData,
        restore: setAppContextData,
      }}
    >
      <Router
        basename={process.env.REACT_APP_BASE_URL}
        location={router.location}
        navigationType={router.action}
        navigator={history}
      >
        <Routes>
          {routes.map((route, index) => (
            <Route
              key={index}
              element={
                <ProtectedRoute
                  withAuth={route.protected}
                  requiredprivileges={route.requiredprivileges}
                />
              }
            >
              <Route element={<Layout blank={route.blankLayout} />}>
                <Route path={route.path} element={<route.component />} />
              </Route>
            </Route>
          ))}
          <Route path="*" element={<Navigate to="/welcome" />} />
        </Routes>
      </Router>
    </AppContext.Provider>
  )
}

App.displayName = 'App'
export default App
