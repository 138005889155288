import { Button, Input, Select } from 'antd'
import React, { useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { CONTENT_PRIVACY } from 'src/constants'
import { search } from 'src/api/tag'
import debounce from 'src/utils/debounce'

interface PlaylistSearchProps {
  appendSearchForm: (obj) => void
  submitSearchForm: (obj?: any) => void
}

const PlaylistSearch: React.FC<PlaylistSearchProps> = ({
  appendSearchForm,
  submitSearchForm,
}) => {
  const [tags, setTags] = useState([])
  const [loading, setLoading] = useState(false)

  const handleSearch = React.useCallback(async (value) => {
    if (!value) return
    setLoading(true)
    const data = await search({ name: value, size: 50 })
    setTags(data.data)
    setLoading(false)
  }, [])

  const handleSelectTag = React.useCallback((opts: any[]) => {
    const tags = opts?.map((opt) => opt.value).toString()
    appendSearchForm({ tags })
  }, [])

  return (
    <div>
      <Input
        placeholder="Name"
        allowClear
        onChange={(e) => appendSearchForm({ name: e.target.value })}
        className="search-input"
      />
      <Select
        showSearch
        allowClear
        mode="multiple"
        defaultActiveFirstOption={false}
        filterOption={false}
        onSearch={debounce(handleSearch, 500)}
        onChange={handleSelectTag}
        size="middle"
        placeholder="Select tag"
        labelInValue={true}
        className="search-input"
        loading={loading}
      >
        {tags.map((item) => (
          <Select.Option key={item.id}>{item.name}</Select.Option>
        ))}
      </Select>
      <Select
        placeholder="Select privacy"
        allowClear
        onChange={(value) => appendSearchForm({ privacy: value })}
        className="search-input"
      >
        {CONTENT_PRIVACY.map((val) => (
          <Select.Option value={val} key={val}>
            {val}
          </Select.Option>
        ))}
      </Select>
      <Button
        type="primary"
        icon={<SearchOutlined />}
        onClick={() => submitSearchForm()}
        className="search-btn"
      >
        Search
      </Button>
    </div>
  )
}

PlaylistSearch.displayName = 'PlaylistSearch'
export default PlaylistSearch
