import React, { useState, useEffect, useCallback } from 'react'
import GameCard from './gamecards'
import { manualFetch } from 'src/utils/fetch'
import { getGamesByCategory } from 'src/api/game'
import { Spin, Pagination } from 'antd'
import './Sectiongame.css'

const SectionGame = ({ searchTerm }) => {
  const [games, setGames] = useState([])
  const [dataLoaded, setDataLoaded] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalGames, setTotalGames] = useState(0)
  const pageSize = 10

  const fetchImage = useCallback((url) => {
    return manualFetch(url, {
      headers: {
        accept: 'image/*',
      },
    })
      .then((response) => response.blob())
      .then((imageBlob) => {
        const imageUrl = URL.createObjectURL(imageBlob)
        return imageUrl
      })
  }, [])

  const fetchData = useCallback(
    async (page, term) => {
      setDataLoaded(false)
      try {
        const data = await getGamesByCategory(page, pageSize, term)
        const gamesData = await Promise.all(
          data.data.map(async (element) => {
            const imageUrl = await fetchImage(element.fullS3Path)
            const thumbnailImageUrl = await fetchImage(element.thumbnailS3Path)
            return {
              ...element,
              imageUrl,
              thumbnailImageUrl,
            }
          })
        )
        setGames(gamesData)
        setTotalGames(data.total_items)
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setDataLoaded(true)
      }
    },
    [fetchImage]
  )

  useEffect(() => {
    fetchData(currentPage - 1, searchTerm)
  }, [currentPage, searchTerm, fetchData])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  return (
    <div>
      {dataLoaded ? (
        games.length > 0 ? (
          <div>
            <div className="games-container">
              {games.map((room) => (
                <GameCard
                  key={room.id}
                  id={room.id}
                  img={room.imageUrl}
                  thumbImg={room.thumbnailImageUrl}
                  title={room.title}
                  titleFr={room.titleFr}
                  desc={room.description}
                  descFr={room.descriptionFr}
                  instruction={room.instruction}
                  instructionFr={room.instructionFr}
                  categories={room.gameCategories}
                  enabled={room.enabled}
                />
              ))}
            </div>
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={totalGames}
              onChange={handlePageChange}
              showSizeChanger={false}
              style={{ marginTop: 10 }}
            />
          </div>
        ) : null
      ) : (
        <div className="spinner-container">
          <Spin size="large" />
        </div>
      )}
    </div>
  )
}

export default SectionGame
