import { message } from 'antd'

import { fetchUtil as fetch, buildQuery } from 'src/utils/fetch'

export const getMetaverseCategory = async () => {
  try {
    const { data } = await fetch('/metaverse-rooms/rooms/categories')
    return data
  } catch (e) {
    message.error('Get Metaverse Category error!')
  }
}

export const getMetaverseRooms = async () => {
  try {
    const { data } = await fetch(`/metaverse-rooms/rooms/room-categories`)
    return data
  } catch (e) {
    message.error('Get Metaverse Rooms error!')
  }
}

export const getFeaturedMetaverseRooms = async () => {
  try {
    const { data } = await fetch(`/metaverse-rooms/rooms/featuredVenue`)
    return data
  } catch (e) {
    message.error('Get Featured Metaverse Rooms error!')
  }
}

export const setFeaturedMetaverseRooms = async (roomId) => {
  try {
    const { data } = await fetch(
      `/metaverse-rooms/rooms/featuredVenue/${roomId}`,
      {
        method: 'PATCH',
        headers: {
          role: 'ADMIN',
        },
      }
    )
    message.success('Success featured room edit!')
    return data
  } catch (e) {
    message.error('Fail to save featured metaverse rooms!')
  }
}

export const getMetaverseRoomsByCategory = async (category) => {
  try {
    const { data } = await fetch(`/metaverse-rooms/rooms/${category}`)
    return data
  } catch (e) {
    message.error('Get Metaverse Rooms by Category error!')
  }
}

export const saveEditMetaverse = async (
  id,
  title,
  titleFr,
  desc,
  img,
  thumbImage
) => {
  try {
    const input = new FormData()
    input.append(
      'roomEditDto',
      new Blob(
        [
          JSON.stringify({
            id: id,
            title: title,
            titleFr: titleFr,
            description: desc,
          }),
        ],
        {
          type: 'application/json',
        }
      )
    )
    input.append('image', img)
    input.append('thumbImage', thumbImage)

    const { data } = await fetch('/metaverse-rooms/rooms/edit', {
      method: 'POST',
      body: input,
      headers: {
        role: 'ADMIN',
      },
    })
    message.success('Success Metaverse Room Edit!')
    return 'success'
  } catch (e) {
    message.error('Edit Metaverse Room error!')
    return 'failed'
  }
}
export const getAllRoomCategories = async () => {
  try {
    const res = await fetch(`/metaverse-rooms/rooms/room-categories`)
    if (Array.isArray(res?.data)) {
      
      const categories = {
        MUSIC: [],
        COUNTRIES: [],
        TWINS: []
      };
      const twinsRooms = [];
      
      res.data.forEach(room => {
        if (room.type === "MUSIC" || room.type === "COUNTRIES") {
          categories[room.type].push(room);
        } else if (room.type === "CLUB" || room.type === "CASINO" || room.type === "STADIUM") {
         
          room.rooms.forEach(rooms => {
            twinsRooms.push({...rooms})
            
          })
         
          
        }
      });
      twinsRooms.sort((a, b) => Number(a.twinSortIndex) - Number(b.twinSortIndex))
      categories.TWINS.push({type: "TWINS" ,id:"TWINS", rooms:twinsRooms});
      
      
      const result = [...categories.MUSIC, ...categories.COUNTRIES, ...categories.TWINS];
      
    
      return result;
    }
    throw 'Get all categories response wrong format'
  } catch (e) {
    message.error('getAll categories error ')
  }
}
export const updateMetaverseOrder = async (
  categoryId: string,
  updatedCategory: MetaverseRoomCategory
) => {
  try {
    await fetch(`/metaverse-rooms/rooms/update-order/${categoryId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedCategory),
    });
   
  } catch (e) {
    message.error('updateMetaverses category error')
  }
}
export const getDiscovery = async () => {
  try {
    const { data } = await fetch('/metaverse-rooms/rooms/discovery')
    return data
  } catch (e) {
    message.error('Get Metaverse Category error!')
  }
}
