import React, { useState } from 'react'
import { Card, Button, Spin } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { saveEditMetaverse } from 'src/api/metaverse'
import './Metaversecard.css'
import { useNavigate } from 'react-router-dom'

const MetaverseCard = ({ id, img, thumbImg, title, titleFr, desc }) => {
  const navigate = useNavigate()

  const [dataLoaded, setDataLoaded] = useState(true)

  const handleClickOpen = () => {
    navigate(
      `/metaverse/edit/${id}/${encodeURIComponent(img)}/${encodeURIComponent(
        thumbImg
      )}/${encodeURIComponent(title)}/${encodeURIComponent(titleFr)}/${encodeURIComponent(desc)}`
    )
  }

  return (
    <div>
      {dataLoaded ? (
        <Card
          hoverable
          className="card-container"
          cover={
            <div className="card-cover">
              <img alt={title} src={img} className="card-image" />
              <EditOutlined onClick={handleClickOpen} className="edit-icon" />
            </div>
          }
        >
          <Card.Meta title={title} description={desc} />
        </Card>
      ) : (
        <div className="spinner-container">
          <Spin size="large" />
        </div>
      )}
    </div>
  )
}

export default MetaverseCard
