import { useState, useCallback, useEffect } from 'react'
import { Button, Divider, Typography, Select, Spin, Modal, Form } from 'antd'
import { useNavigate } from 'react-router-dom'
import {
  getMonthlyMarketplace,
  getMarketplace,
  saveMonthlyMarketplace,
} from 'src/api/marketplace'
import { fetchCountryProvider } from 'src/api/authen'
import { manualFetch } from 'src/utils/fetch'
import './MonthlyMarketplace.css'
import { MONTHS, YEARS } from 'src/constants'

const { Title } = Typography

const MonthlyMarketplace = () => {
  const navigate = useNavigate()
  const [month, setMonth] = useState(null)
  const [year, setYear] = useState(null)
  const [country, setCountry] = useState('TN')

  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [marketPlaceMap, setMarketPlaceMap] = useState({})
  const [marketPlaces, setMarketPlaces] = useState([])
  const [countries, setCountries] = useState([])

  const [places, setPlaces] = useState({
    place1: null,
    place2: null,
    place3: null,
  })

  const [placeIds, setPlaceIds] = useState({
    place1Id: null,
    place2Id: null,
    place3Id: null,
  })

  const handleMonthChange = (value) => {
    setMonth(value)
    if (year) fetchData(country, year, value)
  }

  const handleYearChange = (value) => {
    setYear(value)
    if (month) fetchData(country, value, month)
  }

  const handleCountryChange = (value) => {
    setCountry(value)
    if (month) fetchData(value, year, month)
  }

  const handlePlaceChange = (place, value) => {
    setPlaces((prev) => ({
      ...prev,
      [place]: marketPlaceMap[value]?.label || null,
    }))
    setPlaceIds((prev) => ({ ...prev, [`${place}Id`]: value }))
    arePlacesSelected()
  }

  const getCurrentMonth = () => {
    const date = new Date()
    const month = MONTHS[date.getMonth()].value
    setMonth(month)
    return month
  }

  const getCurrentYear = () => {
    const date = new Date()
    const year = date.getFullYear()
    setYear(year)
    return year
  }

  const onCancel = () => {
    navigate('/marketplace')
  }

  const fetchImage = async (url) => {
    const response = await manualFetch(url, { headers: { accept: 'image/*' } })
    const imageBlob = await response.blob()
    return URL.createObjectURL(imageBlob)
  }

  const fetchData = async (country, year, month) => {
    setLoading(true)
    try {
      const data = await getMonthlyMarketplace(country, year, month)
      const images = await Promise.all([
        data.data.first?.images[0]
          ? fetchImage(data.data.first.images[0])
          : null,
        data.data.second?.images[0]
          ? fetchImage(data.data.second.images[0])
          : null,
        data.data.third?.images[0]
          ? fetchImage(data.data.third.images[0])
          : null,
      ])
      setImages(images)
      setPlaces({
        place1: data.data.first?.name || '',
        place2: data.data.second?.name || '',
        place3: data.data.third?.name || '',
      })
      setPlaceIds({
        place1Id: data.data.first?.id || '',
        place2Id: data.data.second?.id || '',
        place3Id: data.data.third?.id || '',
      })
    } catch (error) {
      console.error('Error fetching data:', error)
    }
    setLoading(false)
  }

  const createMarketMap = (data) => {
    const map = data.reduce((map, item) => {
      map[item.value] = item
      return map
    }, {})

    setMarketPlaceMap(map)
  }

  const fetchMarketplace = useCallback(async () => {
    try {
      const data = await getMarketplace(0, 100, '', country)
      const marketPlacesData = data.data
        .map((element) => ({
          value: element.id,
          label: element.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
      setMarketPlaces(marketPlacesData)
      createMarketMap(marketPlacesData)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }, [country])

  useEffect(() => {
    const year = getCurrentYear()
    const month = getCurrentMonth()
    fetchData(country, year, month)
    fetchMarketplace()
  }, [fetchMarketplace])

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const data = await fetchCountryProvider()
        const countryOptions = data.data.map((item) => ({
          label: item.countryName,
          value: item.countryCode,
        }))
        setCountries(countryOptions)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchCountry()
  }, [])

  const handleEdit = () => {
    setIsModalVisible(true)
  }

  const handleModalOk = async () => {
    setIsModalVisible(false)
    await save(
      placeIds.place1Id,
      placeIds.place2Id,
      placeIds.place3Id,
      year,
      month
    )
    fetchData(country, year, month)
  }

  const save = async (place1Id, place2Id, place3Id, year, month) => {
    setLoading(true)
    try {
      if (place1Id)
        await saveMonthlyMarketplace(year, month, 1, place1Id, country)
      if (place2Id)
        await saveMonthlyMarketplace(year, month, 2, place2Id, country)
      if (place3Id)
        await saveMonthlyMarketplace(year, month, 3, place3Id, country)
    } catch (error) {
      console.error('Error saving data:', error)
    }
    setLoading(false)
  }

  const handleModalCancel = () => {
    setIsModalVisible(false)
  }

  const isPastDate = () => {
    const today = new Date()
    const selectedYear = parseInt(year, 10)
    const selectedMonth = MONTHS.findIndex((m) => m.value === month)

    return (
      selectedYear < today.getFullYear() ||
      (selectedYear === today.getFullYear() && selectedMonth < today.getMonth())
    )
  }

  const arePlacesSelected = () => {
    return placeIds.place1Id && placeIds.place2Id && placeIds.place3Id
  }

  return (
    <div className="monthly-metaverse-container">
      <Title level={3}>Monthly Marketplace</Title>
      <Divider />
      <div>
        <div className="inline-form-item">
          <label>Country :</label>
          <Select
            style={{ width: '25%' }}
            placeholder="Select Country"
            value={country}
            onChange={handleCountryChange}
            options={countries}
          />
        </div>
        <div className="inline-form-item">
          <label>Year :</label>
          <Select
            style={{ width: '25%' }}
            placeholder="Select Year"
            value={year}
            onChange={handleYearChange}
            options={YEARS}
          />
        </div>
        <div className="inline-form-item">
          <label>Month :</label>
          <Select
            style={{ width: '25%' }}
            placeholder="Select Month"
            value={month}
            onChange={handleMonthChange}
            options={MONTHS}
          />
        </div>
        <div className="inline-form-item">
          <Button
            type="primary"
            style={{ width: '25%' }}
            onClick={handleEdit}
            disabled={isPastDate()}
          >
            Edit
          </Button>
        </div>
        <div className="inline-form-item">
          {loading ? (
            <Spin size="large" />
          ) : (
            <div>
              <div className="image-row">
                {images.map((url, index) => (
                  <div key={index} className="image-box">
                    <span>Place {index + 1}</span>
                    {url && (
                      <img
                        src={url}
                        alt={`Marketplace ${index + 1}`}
                        className="marketplace-image"
                      />
                    )}
                    <span>{places[`place${index + 1}`]}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="inline-form-item"></div>
      <div style={{ marginTop: 16 }}>
        <Button style={{ width: '21%' }} onClick={onCancel}>
          Cancel
        </Button>
      </div>
      <Divider />
      <Modal
        title="Select Monthly Marketplace"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        width={800}
        footer={[
          <Button key="cancel" onClick={handleModalCancel}>
            Cancel
          </Button>,
          <Button
            key="ok"
            type="primary"
            onClick={handleModalOk}
            disabled={!arePlacesSelected()}
          >
            OK
          </Button>,
        ]}
      >
        <Form>
          <Form.Item>
            <div className="inline-form-item">
              <label>Place 1 :</label>
              <Select
                style={{ width: '100%' }}
                showSearch
                placeholder="Select Place 1"
                value={places.place1}
                onChange={(value) => handlePlaceChange('place1', value)}
                options={marketPlaces}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
            </div>
            <div className="inline-form-item">
              <label>Place 2 :</label>
              <Select
                style={{ width: '100%' }}
                showSearch
                placeholder="Select Place 2"
                value={places.place2}
                onChange={(value) => handlePlaceChange('place2', value)}
                options={marketPlaces}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
            </div>
            <div className="inline-form-item">
              <label>Place 3 :</label>
              <Select
                style={{ width: '100%' }}
                placeholder="Select Place 3"
                showSearch
                value={places.place3}
                onChange={(value) => handlePlaceChange('place3', value)}
                options={marketPlaces}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default MonthlyMarketplace
