import React, { useEffect, useCallback } from 'react'
import { Divider, Typography, Spin, message } from 'antd'
import { DndProvider } from 'react-dnd'
import CarouselItem from './carousel-item'
import Carousel from './carousel'
import { HTML5Backend } from 'react-dnd-html5-backend'
import useForceRender from 'src/utils/ForceRender'
import { clone } from 'ramda'
 import * as api from 'src/api/game'
import AddingModal from './addGames-modal'
import s from './index.module.scss'

const { Title } = Typography

const GameDiscovery: React.FC = () => {
  const forceRender = useForceRender()
  const selectedCategory = React.useRef<GamesCategory>(null)
  const showAdding = React.useRef<boolean>(false)
  const loading = React.useRef<boolean>(true)
  const data = React.useRef<any[]>([])

  const loadPageData = useCallback(() => {
    api.getNewCategories().then((categories) => {
      loading.current = false
      data.current = categories
      forceRender()
    })
  }, [])

  useEffect(() => {
    loadPageData()
  }, [])

  const onModalCancel = React.useCallback(() => {
    selectedCategory.current = null
    showAdding.current = false
    forceRender()
  }, [])

  const onAddClick = React.useCallback((category: GamesCategory) => {
    selectedCategory.current = category
    showAdding.current = true
    forceRender()
  }, [])

  const onModalDone = React.useCallback(() => {
    loading.current = true
    onModalCancel()
    loadPageData()
  }, [])

  const updateOrdering = React.useCallback(
    async (dragId: string, dropId: string, category: GamesCategory) => {
     


      const categoryNew = data.current.find((cat: GamesCategory) => cat.id === category.id) as GamesCategory;

      if(category.type ==='RECOMMENDED'
  || category.type === 'CASUAL'
  || category.type === 'MASTERMIND'){
      const dragIndex = categoryNew.games.findIndex((i) => i.id === dragId)
      const dropIdIndex = categoryNew.games.findIndex((i) => i.id === dropId);

      let dropIndex: number;

      if (dropIdIndex === 0) {
        
        
        dropIndex = dropIdIndex;
      } else if (dragIndex > dropIdIndex) {
      
        dropIndex = dropIdIndex;
      } else {
        
        dropIndex = dropIdIndex+1;
      }
      const dragItem = categoryNew.games[dragIndex]
      const tempItem = { id: 'temp' } as any
      
      const cloneItems = clone(categoryNew.games)
      cloneItems[dragIndex] = tempItem
      cloneItems.splice(dropIndex, 0, dragItem)
      const orderedItems = cloneItems.filter((i) => i.id !== 'temp')
      const gameIds = orderedItems.map((i) => i.id)
      loading.current = true
      forceRender()
      
      await api.updateGamesCategory(categoryNew.id, gameIds)
      message.success(`Updated games ordering of ${category.type === "RECOMMENDED"?"EXSCAPE'S CHOICES":category.type}`)
      loadPageData()
  } else {
    

    const dragIndex = categoryNew.games.findIndex((i) => i.id === dragId);
    const dropIdIndex = categoryNew.games.findIndex((i) => i.id === dropId);

    let dropIndex: number;

    if (dropIdIndex === 0) {
      
      
      dropIndex = dropIdIndex;
    } else if (dragIndex > dropIdIndex) {
    
      dropIndex = dropIdIndex;
    } else {
      
      dropIndex = dropIdIndex+1;
    }
      const dragItem = categoryNew.games[dragIndex];
      const tempItem = { id: 'temp' } as any;
     
      const cloneItems = [...categoryNew.games];
      cloneItems[dragIndex] = tempItem;
      cloneItems.splice(dropIndex, 0, dragItem);
      const orderedItems = cloneItems.filter((i) => i.id !== 'temp');


      orderedItems.forEach((item, index) => {
        
        if (categoryNew.id.toUpperCase() === 'CARDS') {
          item.cardSortIndex = index + 1;
        
        } else if (categoryNew.id.toUpperCase() === 'PUZZLE') {
          item.puzzleSortIndex = index + 1;
       
        } else if (categoryNew.id.toUpperCase() === 'QUIZ') {
          item.quizSortIndex = index + 1;
      
        } else if (categoryNew.id.toUpperCase() === 'ACTION') {
          item.actionSortIndex = index + 1;
        
        } else if (categoryNew.id.toUpperCase() === 'ADVENTURE') {
          item.adventureSortIndex = index + 1;
        
        } else if (categoryNew.id.toUpperCase() === 'SPORTS') {
          item.sportsSortIndex = index + 1;
        } 

      });


      const updatedCategory = {
       
        ...categoryNew,
        games: orderedItems,
      };

      loading.current = true;
      forceRender();
      
      await api.updateGamesOrder(categoryNew.id, updatedCategory);
      message.success(`Updated game ordering of ${categoryNew.type}`);
      loadPageData();

  }
    },
    [data]
  )

  const handleRemoving = React.useCallback(
    async (game: Games, category: GamesCategory) => {
      loading.current = true
      forceRender()
      const gameIds = category.games?.reduce((IDs, current) => {
        if (current.id !== game.id) {
          IDs.push(current.id)
        }
        return IDs
      }, [])
      await api.updateGamesCategory(category.id, gameIds)
      message.success(`${game.name} is removed from ${category.type === "RECOMMENDED"?"EXSCAPE'S CHOICES":category.type}`)
      loadPageData()
    },
    []
  )
  function reorderItems(clonedItems, dragIndex, dropIndex, type) {
    
    const sortIndexMap = {
      'CARDS': 'cardSortIndex',
      'PUZZLE': 'puzzleSortIndex', 
      'QUIZ': 'quizSortIndex',
      'ACTION': 'actionSortIndex',
      'ADVENTURE': 'adventureSortIndex',
      'SPORTS': 'sportsSortIndex'
    };
  
    
    const sortIndexProperty = sortIndexMap[type.toUpperCase()];
  
    if (sortIndexProperty) {
    
      const temp = clonedItems[dragIndex][sortIndexProperty];
      clonedItems[dragIndex][sortIndexProperty] = clonedItems[dropIndex][sortIndexProperty];
      clonedItems[dropIndex][sortIndexProperty] = temp;
    }
  }

  return (
    <Spin spinning={loading.current}>
      <Title level={3}>Games</Title>
      <Divider />
      <DndProvider backend={HTML5Backend}>
        {data.current?.map((category) => (
          <Carousel
            key={category.id}
            name={category.type === "RECOMMENDED"?"EXSCAPE'S CHOICES":category.type}
            onAddClick={() => onAddClick(category)}
          >
            {category.games?.length ? (
              category.games.map((item) => (
                <CarouselItem
                  key={item.id}
                  game={item}
                  categoryId={category.id}
                  onDrop={(drapId, dropId) =>
                    updateOrdering(drapId, dropId, category)
                  }
                  onRemove={(game) => handleRemoving(game, category)}
                />
              ))
            ) : (
              <span className={s.noData}> No data</span>
            )}
          </Carousel>
        ))}
      </DndProvider>
      <AddingModal
        visible={showAdding.current}
        category={selectedCategory.current}
        onDone={onModalDone}
        onCancel={onModalCancel}
      />
    </Spin>
  )
}

GameDiscovery.displayName = 'Discovery'

export default GameDiscovery
