import { Divider, Table, Tag } from 'antd'
import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import DragTableRow from 'src/components/DragTableRow'
import usePage from 'src/hooks/usePage'
import {  searchPlaylistReport } from 'src/api/reports'
import PlaylistReportSearch from './playlistReportSearch'

const { Column } = Table

const PlaylistReportPage: React.FC = () => {
  const { pageData, onQuery, appendSearchForm, submitSearchForm, fetchList } =
    usePage({
      getData: searchPlaylistReport,
      searchData: searchPlaylistReport,
      searchType: 'POST',
    })


  return (
    <>
     
      <Divider />
      <DndProvider backend={HTML5Backend}>
        <Table
          className="table-sorting"
          rowKey="eventId"
          dataSource={pageData.data}
          loading={pageData.loading}
          pagination={{
            current: parseInt(pageData.query.page) + 1,
            total: pageData.total,
            pageSize: parseInt(pageData.query.pageSize) || 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '25', '50', '100'],
            onChange: (p,ps) =>{
              appendSearchForm({ buttonAction: '' });
             onQuery({ page: (p - 1).toString(),pageSize: ps.toString() });
            },
          }}
          title={() => (
            <PlaylistReportSearch
              appendSearchForm={appendSearchForm}
              submitSearchForm={submitSearchForm}
            />
          )}
          components={{
            body: {
              row: DragTableRow,
            },
          }}
          
        >
          <Column
            width={120}
            dataIndex="playlistId"
            title="Playlist Id"
            
          />
          <Column
            dataIndex="name"
            title="Name"
           
          />
          <Column dataIndex="trackTitle" title="Tracks Title" />
          <Column
            dataIndex="privacy"
            title="Privacy"
            render={(data) => (
              <Tag color={data === 'PRIVATE' ? 'blue' : 'green'}>{data}</Tag>
            )}
            filters={[
              {
                text: 'PUBLIC',
                value: 'PUBLIC',
              },
              {
                text: 'PRIVATE',
                value: 'PRIVATE',
              },
            ]}
            filterMode="tree"
            onFilter={(value: string, record: any) => record.privacy === value}
          />
          <Column dataIndex="userName" title="User" />
          <Column
            dataIndex="age"
            title="Age"
            
          />
          <Column dataIndex="gender" title="Gender" filters={[
              {
                text: 'MALE',
                value: 'MALE',
              },
              {
                text: 'FEMALE',
                value: 'FEMALE',
              },
            ]}
            filterMode="tree"
            onFilter={(value: string, record: any) => record.gender === value} />
             <Column
            dataIndex="deviceType"
            title="Device Type"
            
          />
          <Column
            dataIndex="country"
            title="Country"
            
          />
          <Column
           dataIndex="eventDuration" title="Event Duration" 
           render={(text) => <span>{text} sec</span>}
          />
          <Column
           dataIndex="eventDate" title="Event Date" 
          />
          <Column
           dataIndex="clientName" title="Client Name" 
          />
          <Column
           dataIndex="providerCountry" title="Provider" 
          />
        </Table>
      </DndProvider>
    </>
  )
}

PlaylistReportPage.displayName = 'PlaylistReportPage'
export default PlaylistReportPage
