import React, { useState } from 'react'
import { Card, Spin, Carousel, Modal, message } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import './Marketplacecard.css'

import { useNavigate } from 'react-router-dom'

const { confirm } = Modal

const MarketplaceCard = ({
  id,
  img,
  name,
  nameFr,
  desc,
  descFr,
  enabled,
  points,
  price,
  subTitle,
  subTitleFr,
  category,
  originalImage,
  country,
}) => {
  const navigate = useNavigate()
  const [dataLoaded, setDataLoaded] = useState(true)
  const params = new URLSearchParams()
  params.append('img', img)
  params.append('name', name)
  params.append('nameFr', nameFr)
  params.append('desc', desc)
  params.append('descFr', descFr)
  params.append('enabled', enabled)
  params.append('points', points)
  params.append('price', price)
  params.append('subTitle', subTitle)
  params.append('subTitleFr', subTitleFr)
  params.append('category', category)
  params.append('originalImages', originalImage)
  params.append('country', country)

  const handleClickOpen = () => {
    navigate(`/marketplace/edit/${id}?${params.toString()}`)
  }

  return (
    <div>
      {dataLoaded ? (
        <Card
          hoverable
          className={`card-container ${enabled ? '' : 'faded-card'}`}
          cover={
            <div className="card-cover">
              <Carousel
                autoplaySpeed={3000}
                autoplay
                dots={{ className: 'carousel-dots' }}
              >
                {img.map((imageUrl) => (
                  <img
                    key={imageUrl}
                    alt={name}
                    src={imageUrl}
                    className="card-image"
                  />
                ))}
              </Carousel>
              <EditOutlined onClick={handleClickOpen} className="edit-icon" />
            </div>
          }
        >
          <Card.Meta title={name} description={desc} />
        </Card>
      ) : (
        <div className="spinner-container">
          <Spin size="large" />
        </div>
      )}
    </div>
  )
}

export default MarketplaceCard
