import React, { useEffect, useState } from 'react'
import { Upload, message, Space, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import PrivateImage from './PrivateImage'

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!')
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!')
  }
  return isJpgOrPng && isLt2M
}

interface UploaderProps {
  defaultImageUrl?: string
  onChange: (payload: any) => void
  onRemove: () => void
}

const Uploader: React.FC<UploaderProps> = ({
  onChange,
  onRemove,
  defaultImageUrl = '',
}) => {
  const [imageUrl, setImageUrl] = useState<string>(defaultImageUrl)

  const handleChange = React.useCallback((info) => {
    getBase64(info.file.originFileObj, (imageUrl) => {
      setImageUrl(imageUrl)
      onChange({ imageUrl, file: info.file.originFileObj })
    })
  }, [])

  useEffect(() => {
    setImageUrl(defaultImageUrl)
  }, [defaultImageUrl])

  const handleRemove = React.useCallback(() => {
    setImageUrl('')
    onRemove()
  }, [])

  return (
    <>
      {imageUrl ? (
        <Space direction="vertical" align="center">
          <PrivateImage
            src={imageUrl}
            width={200}
            height={200}
            preview={false}
          />
          <Button danger type="primary" onClick={handleRemove}>
            Upload new
          </Button>
        </Space>
      ) : (
        <Upload
          name="coverImage"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={beforeUpload}
          customRequest={() => null}
          onChange={handleChange}
        >
          <div>
            <PlusOutlined />
            <div>Upload</div>
          </div>
        </Upload>
      )}
    </>
  )
}

Uploader.displayName = 'Uploader'
export default Uploader
