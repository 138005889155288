import { message } from 'antd'
import { fetchUtil as fetch } from 'src/utils/fetch'

export const getGamesCategory = async () => {
  try {
    const { data } = await fetch('/metaverse-rooms/games/categories')
    return data
  } catch (e) {
    message.error('Get Games Category error!')
  }
}

export const getGamesByCategory = async (page, size, title = '') => {
  try {
    const { data } = await fetch('/metaverse-rooms/games/searchAdmin', {
      method: 'POST',
      body: JSON.stringify({
        size: size,
        page: page,
        name: title,
      }),
      headers: {
        role: 'ADMIN',
      },
    })
    return data
  } catch (e) {
    message.error('Search Games Error !')
  }
}

export const saveEditGames = async (
  id,
  title,
  titleFr,
  desc,
  descFr,
  instruction,
  instructionFr,
  img,
  thumbImage,
  categories,
  enabled
) => {
  try {
    const input = new FormData()
    input.append(
      'gameEditDto',
      new Blob(
        [
          JSON.stringify({
            id: id,
            title: title,
            titleFr: titleFr,
            description: desc,
            descriptionFr: descFr,
            categories: categories,
            enabled: enabled,
            instruction: instruction,
            instructionFr: instructionFr,
          }),
        ],
        {
          type: 'application/json',
        }
      )
    )
    input.append('image', img)
    input.append('thumbImage', thumbImage)

    const { data } = await fetch('/metaverse-rooms/games/edit', {
      method: 'POST',
      body: input,
      headers: {
        role: 'ADMIN',
      },
    })
    message.success('Success Games Room Edit!')
    return 'success'
  } catch (e) {
    message.error('Edit Games Room error!')
    return 'failed'
  }
}

export const getAllCustomCategories = async () => {
  try {
    const res = await fetch(`/metaverse-rooms/games/custom-categories`)
    if (Array.isArray(res?.data)) {
      return res.data
    }
    throw 'Get all categories response wrong format'
  } catch (e) {
    message.error('getAll categories error ')
  }
}
export const updateGamesCategory = async (
  categoryId: string,
  games: string[]
) => {
  try {
    await fetch(`/metaverse-rooms/games/custom-category/${categoryId}`, {
      method: 'POST',
      body: JSON.stringify({ games }),
      headers: {
        role: 'ADMIN',
      },
    })
  } catch (e) {
    message.error('update Games category error ')
  }
}

export const getGameofTheDay = async () => {
  try {
    const { data } = await fetch('/metaverse-rooms/games/gameOfTheDay')
    return data
  } catch (e) {
    message.error('Get Game of the day error!')
  }
}

export const saveGameofTheDay = async (userType, gameid) => {
  try {
    fetch(`/metaverse-rooms/games/gameOfTheDay/${userType}/${gameid}`, {
      method: 'PATCH',

      headers: {
        role: 'ADMIN',
      },
    })
    return 'success'
  } catch (e) {
    message.error('Get Game of the day error!')
    return 'failed'
  }
}
export const getByCategories = async () => {
  try {
    const res = await fetch(`/metaverse-rooms/games/game-categories`)
    if (Array.isArray(res?.data)) {
      const validTypes = new Set([
        'CARDS',
        'PUZZLE',
        'QUIZ',
        'ACTION',
        'ADVENTURE',
        'SPORTS',
      ])
      const filtered = res.data.filter((room) => validTypes.has(room.type))
      return filtered
    }
    throw 'Get all categories response wrong format'
  } catch (e) {
    message.error('getAll categories error ')
  }
}
export const updateGamesOrder = async (
  categoryId: string,
  updatedCategory: GamesCategory
) => {
  try {
    await fetch(`/metaverse-rooms/games/sort/${categoryId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedCategory),
    })
  } catch (e) {
    message.error('update Games Order error ')
  }
}
export const getNewCategories = async () => {
  try {
    const [customCategories, gameCategories] = await Promise.all([
      getAllCustomCategories(),
      getByCategories(),
    ])

    if (Array.isArray(customCategories) && Array.isArray(gameCategories)) {
      return [...customCategories, ...gameCategories]
    }

    throw new Error('One or both responses have incorrect format')
  } catch (e) {
    message.error('Error getting new categories')
    return []
  }
}
