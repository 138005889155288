import { parseCookies, setCookie, destroyCookie } from 'nookies'

export function getToken() {
  let token = parseCookies().token
  if (!token) {
    token = sessionStorage.getItem('token')
  }
  return token
}

export function setToken(remember, token) {
  if (remember) {
    const in1days = new Date()
    in1days.setSeconds(86400)

    setCookie({} as any, 'token', token, {
      expires: in1days,
      path: '/',
      secure: true,
    })
  } else {
    sessionStorage.setItem('token', token)
  }
}

export function deleteToken() {
  sessionStorage.removeItem('token')
  destroyCookie({} as any, 'token', { path: '/', secure: true })
}
