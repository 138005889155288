import React, { useState, useEffect } from 'react'
import { AppstoreAddOutlined, TrophyOutlined } from '@ant-design/icons'
import { Divider, Input, Button, Select } from 'antd'
import SectionMarketplace from './sectionmarketplace'
import './Marketplaceindex.css'
import { useNavigate } from 'react-router-dom'
import { fetchCountryProvider } from 'src/api/authen'

const { Search } = Input

const Marketplace: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [country, setCountry] = useState('TN')
  const [countries, setCountries] = useState([])

  const navigate = useNavigate()

  const handleSearch = (term) => {
    setSearchTerm(term)
  }

  const handleAddMarketPlace = () => {
    navigate('/marketplace/add')
  }

  const gotoMonthlysWinners = () => {
    navigate('/marketplace/monthly/winners')
  }

  const handleMonthlyMarketPlace = () => {
    navigate('/marketplace/monthly')
  }

  const handleCountryChange = (value) => {
    setCountry(value)
  }

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const data = await fetchCountryProvider()
        const countryOptions = data.data.map((item) => ({
          label: item.countryName,
          value: item.countryCode,
        }))
        setCountries(countryOptions)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchCountry()
  }, [])
  return (
    <div>
      <div className="header">
        <Select
          placeholder="Select Country"
          value={country}
          onChange={handleCountryChange}
          options={countries}
          style={{ width: '12%' }}
        />
        <Search
          placeholder="search by name"
          onSearch={handleSearch}
          enterButton
          style={{ width: '18%', marginLeft: '10px' }}
          allowClear
        />

        <div className="button-group">
          <Button
            type="primary"
            onClick={gotoMonthlysWinners}
            className="add-button"
          >
            <TrophyOutlined />
            Monthly Winners
          </Button>
          <Button type="primary" onClick={handleMonthlyMarketPlace}>
            <AppstoreAddOutlined />
            Set Monthly Marketplace
          </Button>
          <Button
            type="primary"
            onClick={handleAddMarketPlace}
            className="add-button"
          >
            <AppstoreAddOutlined />
            Add Marketplace
          </Button>
        </div>
      </div>
      <Divider style={{ margin: '10px 0' }} />
      <div className="sections-container">
        <SectionMarketplace country={country} searchTerm={searchTerm} />
      </div>
    </div>
  )
}

Marketplace.displayName = 'Marketplace'

export default Marketplace
