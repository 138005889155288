import { useState, useEffect, useRef } from 'react'
import {
  Button,
  Divider,
  Input,
  Typography,
  Switch,
  Select,
  Upload,
  message,
  Spin,
} from 'antd'
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons'
import { saveAddMarketplace, getMarketplaceCategory } from 'src/api/marketplace'
import { fetchCountryProvider } from 'src/api/authen'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import './AddMarketplace.css'

const { Title, Text } = Typography

interface FormErrors {
  editedName?: string
  editedNameFr?: string
  editedPoint?: string
  editedPrice?: string
  editedTitle?: string
  editedTitleFr?: string
  editedDesc?: string
  editedDescFr?: string
  categories?: string
  images?: string
  country?: string
}

const AddMarketplace = () => {
  const { id } = useParams()
  const location = useLocation()
  const [editedTitle, setEditedTitle] = useState('')
  const [editedTitleFr, setEditedTitleFr] = useState('')
  const [editedDesc, setEditedDesc] = useState('')
  const [editedDescFr, setEditedDescFr] = useState('')
  const [editedName, setEditedName] = useState('')
  const [editedNameFr, setEditedNameFr] = useState('')
  const [editedPoint, setEditedPoint] = useState('')
  const [editedPrice, setEditedPrice] = useState('')
  const [categories, setCategories] = useState('')
  const [country, setCountry] = useState('')
  const [countries, setCountries] = useState([])
  const [editImages, setEditImages] = useState<string[]>([])
  const newImages = useRef<File[]>([])
  const [category, setCategory] = useState<{ label: string; value: string }[]>(
    []
  )
  const [enabled, setEnabled] = useState(true)
  const [errors, setErrors] = useState<FormErrors>({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getMarketplaceCategory()
        const categoryOptions = data.map((item: string) => ({
          label: removeUnderscores(item),
          value: item.toUpperCase(),
        }))
        setCategory(categoryOptions)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
    const fetchCountry = async () => {
      try {
        const data = await fetchCountryProvider()
        const countryOptions = data.data.map((item) => ({
          label: item.countryName,
          value: item.countryCode,
        }))
        setCountries(countryOptions)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchCountry()
  }, [])

  const removeUnderscores = (text: string) => {
    return text.replace(/_/g, ' ')
  }

  const navigate = useNavigate()

  const validateFields = () => {
    const newErrors: FormErrors = {}
    if (!editedName) newErrors.editedName = 'Name is required'
    if (!editedNameFr) newErrors.editedNameFr = 'French Name is required'
    if (!editedPoint) newErrors.editedPoint = 'Points are required'
    if (!editedPrice) newErrors.editedPrice = 'Price is required'
    if (!editedTitle) newErrors.editedTitle = 'Sub Title is required'
    if (!editedTitleFr) newErrors.editedTitleFr = 'French Sub Title is required'
    if (!editedDesc) newErrors.editedDesc = 'Description is required'
    if (!editedDescFr) newErrors.editedDescFr = 'French Description is required'
    if (!categories) newErrors.categories = 'Category is required'
    if (!country) newErrors.country = 'Country is required'

    if (editImages.length === 0)
      newErrors.images = 'At least one image is required'
    return newErrors
  }

  const handleSave = () => {
    const validationErrors = validateFields()
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
      message.error('Please fill in all required fields')
      return
    }

    setLoading(true)
    saveAddMarketplace(
      id,
      editedName,
      editedNameFr,
      editedPoint,
      editedPrice,
      editedTitle,
      editedTitleFr,
      editedDesc,
      editedDescFr,
      enabled,
      categories,
      newImages.current,
      country
    )
      .then(() => {
        navigate('/marketplace')
      })
      .catch((error) => {
        console.error('Error saving marketplace:', error)
        message.error('Failed to save marketplace')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onCancel = () => {
    navigate('/marketplace')
  }

  const handleChange = (value: string) => {
    setCategories(value)
  }

  const handleCountry = (value: string) => {
    setCountry(value)
  }

  const handleDeleteImage = (index: number) => {
    const file = editImages.filter((_, i) => i !== index)
    newImages.current = newImages.current.filter((_, i) => i !== index)
    setEditImages(file)
  }

  const handleAddImage = (event: any) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      setEditImages([...editImages, URL.createObjectURL(event.file)])
      newImages.current = [...newImages.current, event.file]
    }
    reader.readAsDataURL(event.file)
    setErrors((prev) => ({ ...prev, images: '' }))
  }

  return (
    <div className="edit-metaverse-container">
      <Title level={3}>Add Marketplace</Title>
      <Divider />
      <div>
        <div className="inline-form-item">
          <label>Name :</label>
          <Input
            placeholder="Name"
            value={editedName}
            onChange={(e) => {
              setEditedName(e.target.value)
              setErrors((prev) => ({ ...prev, editedName: '' }))
            }}
          />
          {errors.editedName && (
            <span className="error">{errors.editedName}</span>
          )}
        </div>

        <div className="inline-form-item">
          <label>French Name :</label>
          <Input
            placeholder="French Name"
            value={editedNameFr}
            onChange={(e) => {
              setEditedNameFr(e.target.value)
              setErrors((prev) => ({ ...prev, editedNameFr: '' }))
            }}
          />
          {errors.editedNameFr && (
            <span className="error">{errors.editedNameFr}</span>
          )}
        </div>

        <div className="inline-form-item">
          <label>Points :</label>
          <Input
            placeholder="Points"
            type="number"
            value={editedPoint}
            onChange={(e) => {
              const value = e.target.value
              const newValue = value.replace(/[^\d]/g, '')
              setEditedPoint(newValue)
              setErrors((prev) => ({ ...prev, editedPoint: '' }))
            }}
          />
          {errors.editedPoint && (
            <span className="error">{errors.editedPoint}</span>
          )}
        </div>
        <div className="inline-form-item">
          <label>Price :</label>
          <Input
            placeholder="Price"
            type="number"
            value={editedPrice}
            onChange={(e) => {
              setEditedPrice(e.target.value)
              setErrors((prev) => ({ ...prev, editedPrice: '' }))
            }}
          />
          {errors.editedPrice && (
            <span className="error">{errors.editedPrice}</span>
          )}
        </div>
        <div className="inline-form-item">
          <label>Sub Title :</label>
          <Input.TextArea
            placeholder="Sub Title"
            value={editedTitle}
            onChange={(e) => {
              setEditedTitle(e.target.value)
              setErrors((prev) => ({ ...prev, editedTitle: '' }))
            }}
          />
          {errors.editedTitle && (
            <span className="error">{errors.editedTitle}</span>
          )}
        </div>
        <div className="inline-form-item">
          <label>French Sub Title :</label>
          <Input.TextArea
            placeholder="French Sub Title"
            value={editedTitleFr}
            onChange={(e) => {
              setEditedTitleFr(e.target.value)
              setErrors((prev) => ({ ...prev, editedTitleFr: '' }))
            }}
          />
          {errors.editedTitleFr && (
            <span className="error">{errors.editedTitleFr}</span>
          )}
        </div>
        <div className="inline-form-item">
          <label>Description :</label>
          <Input.TextArea
            placeholder="Description"
            value={editedDesc}
            onChange={(e) => {
              setEditedDesc(e.target.value)
              setErrors((prev) => ({ ...prev, editedDesc: '' }))
            }}
          />
          {errors.editedDesc && (
            <span className="error">{errors.editedDesc}</span>
          )}
        </div>
        <div className="inline-form-item">
          <label>French Description :</label>
          <Input.TextArea
            placeholder="French Description"
            value={editedDescFr}
            onChange={(e) => {
              setEditedDescFr(e.target.value)
              setErrors((prev) => ({ ...prev, editedDescFr: '' }))
            }}
          />
          {errors.editedDescFr && (
            <span className="error">{errors.editedDescFr}</span>
          )}
        </div>
        <div className="inline-form-item">
          <label>Category :</label>
          <Select
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select Category"
            defaultValue={categories}
            onChange={(value) => {
              handleChange(value)
              setErrors((prev) => ({ ...prev, categories: '' }))
            }}
            options={category}
          />
          {errors.categories && (
            <span className="error">{errors.categories}</span>
          )}
        </div>
        <div className="inline-form-item">
          <label>Country :</label>
          <Select
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select Country"
            defaultValue={country}
            onChange={(value) => {
              handleCountry(value)
              setErrors((prev) => ({ ...prev, categories: '' }))
            }}
            options={countries}
          />
          {errors.country && <span className="error">{errors.country}</span>}
        </div>

        <div className="inline-form-item image-row">
          <div className="images-wrapper">
            {editImages.map((imageUrl, index) => (
              <div key={index} className="image-container">
                <img
                  src={imageUrl}
                  className="card-image"
                  alt={`Image ${index}`}
                />
                <DeleteOutlined
                  onClick={() => handleDeleteImage(index)}
                  className="delete-icon1"
                />
              </div>
            ))}
          </div>
        </div>
        <div className="add-image">
          <Upload
            beforeUpload={() => false}
            onChange={handleAddImage}
            showUploadList={false}
            accept="image/*"
          >
            <Button icon={<UploadOutlined />}>Add Image</Button>
          </Upload>
          {errors.images && <span className="error">{errors.images}</span>}
        </div>
        <div className="button-container" style={{ marginTop: 16 }}>
          <Button className="save-button" onClick={handleSave}>
            {loading ? <Spin /> : 'Submit'}
          </Button>
          <Button className="cancel-button" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AddMarketplace
