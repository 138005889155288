import React, { useState, useEffect, useCallback } from 'react'
import { Button, Tag, Row, Col, Modal, Select, Spin } from 'antd'
import { FireOutlined } from '@ant-design/icons'
import {
  getMetaverseCategory,
  getFeaturedMetaverseRooms,
  getMetaverseRooms,
  setFeaturedMetaverseRooms,
} from 'src/api/metaverse'
import SectionMetaverse from './sectionmetaverse'
import './Metaindex.css'

interface Room {
  title: string
  name: string
}

interface RoomData {
  rooms: Room[]
}

const Metaverse: React.FC = () => {
  const [chipData, setChipData] = useState<string[]>([])
  const [selectedChip, setSelectedChip] = useState<string | null>(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [featuredRoom, setFeaturedRoom] = useState<string | null>(null)
  const [featuredRoomOption, setFeaturedRoomOption] = useState<Room[]>([])
  const [loading, setLoading] = useState(false)

  const handleClick = useCallback((chip: string) => {
    setSelectedChip((prevChip) => (prevChip === chip ? null : chip))
  }, [])

  const fetchData = useCallback(async () => {
    try {
      const data = await getMetaverseCategory()
      setChipData(data.data.filter((room: string) => room !== 'MAIN'))
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }, [])

  const fetchFeaturedRoom = useCallback(async () => {
    try {
      const data = await getFeaturedMetaverseRooms()
      setFeaturedRoom(data.data.name)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }, [])

  const fetchRooms = useCallback(async () => {
    try {
      const data: RoomData[] = await getMetaverseRooms()
      let rooms: Room[] = []
      data.map((roomData) => {
        roomData.rooms.map((r) => {
          rooms = [{ title: r.title, name: r.name }, ...rooms]
        })
      })
      setFeaturedRoomOption(rooms)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }, [])

  const showModal = useCallback(async () => {
    setIsModalVisible(true)
    setLoading(true)
    try {
      await Promise.all([fetchRooms(), fetchFeaturedRoom()])
    } finally {
      setLoading(false)
    }
  }, [fetchRooms, fetchFeaturedRoom])

  const handleModalOk = useCallback(async () => {
    setLoading(true)
    try {
      if (featuredRoom) {
        await setFeaturedMetaverseRooms(featuredRoom)
      }
      setIsModalVisible(false)
    } catch (error) {
      console.error('Error setting featured room:', error)
    } finally {
      setLoading(false)
    }
  }, [featuredRoom])

  const handleModalCancel = useCallback(() => {
    setIsModalVisible(false)
  }, [])

  const handleSelectChange = useCallback((value) => {
    setFeaturedRoom(value)
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <div className="metaverse-container">
      <div className="row-container">
        <Row gutter={[8, 8]} className="chip-row">
          {chipData.map((chip) => (
            <Col key={chip}>
              <Tag
                className={`tag ${selectedChip === chip ? 'active' : ''}`}
                color={selectedChip === chip ? 'blue' : 'default'}
                onClick={() => handleClick(chip)}
              >
                {chip}
              </Tag>
            </Col>
          ))}
        </Row>
        <Button
          type="primary"
          onClick={showModal}
          className="set-featured-button"
        >
          <FireOutlined />
          Set Featured Room
        </Button>
      </div>

      <div className="sections-container">
        {chipData.map((chip) => (
          <div
            key={chip}
            className={`section ${
              selectedChip === null || selectedChip === chip
                ? 'active'
                : 'hidden'
            }`}
          >
            <SectionMetaverse name={chip} />
          </div>
        ))}
      </div>

      <Modal
        title="Set Featured Room"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Spin spinning={loading}>
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Please select room"
            value={featuredRoom}
            onChange={handleSelectChange}
            options={featuredRoomOption.map((room) => ({
              label: room.title,
              value: room.name,
            }))}
            filterOption={(input, option) =>
              option?.label.toLowerCase().includes(input.toLowerCase())
            }
          />
        </Spin>
      </Modal>
    </div>
  )
}

Metaverse.displayName = 'Metaverse'

export default Metaverse
