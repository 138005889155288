export const getImagePath = (url) => {
  let coverUrl = url
  if (url.includes('http')) {
    const uri = new URL(url)
    const params = Object.fromEntries(uri.searchParams.entries())
    if (params.imagePath) {
      coverUrl = params.imagePath
    }
  }
  return coverUrl
}
