import { message } from 'antd'
import { omit } from 'ramda'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useForceRender from 'src/utils/ForceRender'

interface UseDetailProps {
  data?: Record<string, any>
  updateOrCreate: (id: string, payload: Record<string, any>) => Promise<void>
  getById?: (id: string) => Promise<any>
  uploadImage?: (id: string, file: any) => Promise<any>
  backOnFinish?: boolean
}

const useDetail = ({
  data,
  getById,
  uploadImage,
  updateOrCreate,
  backOnFinish = true,
}: UseDetailProps) => {
  const navigate = useNavigate()
  const forceRender = useForceRender()
  const loading = React.useRef<boolean>(true)
  const { id: queryId } = useParams()
  const id = data?.id || queryId
  const form = React.useRef<Record<string, any>>({})

  const fetchDetail = React.useCallback(async () => {
    if (data) {
      form.current = data
    } else if (getById) {
      form.current = await getById(id)
    }
    loading.current = false
    forceRender()
  }, [id])

  useEffect(() => {
    if (id && id !== 'new') {
      fetchDetail()
    } else {
      loading.current = false
      forceRender()
    }
  }, [id])

  const onDeleteImage = React.useCallback(() => {
    form.current = omit(['image'], form.current)
    forceRender()
  }, [form.current])

  const onSelectImage = React.useCallback(
    ({ imageUrl, file }) => {
      form.current = { ...form.current, image: { imageUrl, file } }
      forceRender()
    },
    [form.current]
  )

  const setForm = React.useCallback(
    (value: Record<string, any>, render = false) => {
      form.current = value
      if (render) {
        forceRender()
      }
    },
    []
  )

  const appendForm = React.useCallback(
    (value: Record<string, any>, render = false) => {
      form.current = { ...form.current, ...value }
      if (render) {
        forceRender()
      }
    },
    []
  )

  const resetForm = React.useCallback(() => {
    form.current = {}
    loading.current = false
    forceRender()
  }, [])

  const onFinish = React.useCallback(async () => {
    loading.current = true
    forceRender()
    if (uploadImage && id && id !== 'new' && form.current.image?.file) {
      await uploadImage(id, form.current.image?.file)
    }
    try {
      await updateOrCreate(id, form.current)
      message.success(id === 'new' ? 'Created' : 'Updated')
      backOnFinish && navigate(-1)
    } catch (e) {
      loading.current = false
    }
    forceRender()
  }, [form.current])

  return {
    onFinish,
    onSelectImage,
    onDeleteImage,
    setForm,
    appendForm,
    resetForm,
    id,
    loading: loading.current,
    form: form.current,
  }
}

export default useDetail
