import { Button, message, Modal, Select, Typography } from 'antd'
import React from 'react'
import debounce from 'src/utils/debounce'
import {  getGamesByCategory, updateGamesCategory } from 'src/api/game'

import s from './index.module.scss'
import useForceRender from 'src/utils/ForceRender'

const { Option } = Select
const { Title } = Typography

interface AddingModalProps {
  category: any
  visible: boolean
  onDone: () => void
  onCancel: () => void
}

const AddingModal: React.FC<AddingModalProps> = ({
  category,
  visible,
  onDone,
  onCancel,
}) => {
  const forceRender = useForceRender()
  const selectedGame = React.useRef<Games>(null)
  const selectLoading = React.useRef<boolean>(false)
  const addLoading = React.useRef<boolean>(false)
  const games = React.useRef<Games[]>([])

  const handleSearch = async (value) => {
    selectLoading.current = true
    forceRender()
    const { data } = await getGamesByCategory(0,50,value)
    games.current = data
    selectLoading.current = false
    forceRender()
  }

  const resetModal = React.useCallback(() => {
    selectedGame.current = null
    selectLoading.current = false
    addLoading.current = false
    games.current = []
    forceRender()
  }, [])

  const handleSelect = React.useCallback((option) => {
    selectedGame.current = {
      id: option.value,
      name: option.label,
      title:option.label
    }
    forceRender()
  }, [])

  const handleAdd = React.useCallback(async () => {
    if (!selectedGame.current) {
      message.warn('Select a game first !')
      return
    }
    addLoading.current = true
    forceRender()
    const gameIds = category?.games.map((i) => i.id)
    await updateGamesCategory(category?.id, [
      ...gameIds,
      selectedGame.current.id,
    ])
    message.success(
      `${selectedGame.current?.name} is added to ${category.type}`
    )
    resetModal()
    onDone && onDone()
  }, [selectedGame.current, category])

  const handleCancel = React.useCallback(() => {
    resetModal()
    onCancel && onCancel()
  }, [])

  const filteredResults = games.current?.filter((item) => {
    return !category?.games.find((i) => i.id === item.id)
  })

  return (
    <>
      <Modal
        title={`Add game to ${category?.type}`}
        visible={visible}
        onCancel={handleCancel}
        footer={[
          <Button
            type="primary"
            key="add"
            onClick={handleAdd}
            loading={addLoading.current}
          >
            Add
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <Title level={5}>{category?.games?.length} Games added</Title>
        <Select
          allowClear
          showSearch
          autoFocus
          filterOption={false}
          onSearch={debounce(handleSearch, 500)}
          onSelect={handleSelect}
          size="large"
          placeholder="Search game"
          labelInValue={true}
          value={
            selectedGame.current
              ? {
                  label: selectedGame.current.name,
                  value: selectedGame.current.id,
                }
              : null
          }
          className={s.searchSelect}
          onClear={resetModal}
          loading={selectLoading.current}
        >
          {filteredResults?.map((item) => (
            <Option key={item.id}>{item.name}</Option>
          ))}
        </Select>
      </Modal>
    </>
  )
}

AddingModal.displayName = 'AddingModal'
export default AddingModal
