import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Spin,
  Tag,
  Typography,
} from 'antd'
import { useNavigate } from 'react-router-dom'
import Uploader from 'src/components/Uploader'
import AssignTag from 'src/components/AssignTag'
import AssignTrack from './AssignTrack'
import useDetail from 'src/hooks/useDetail'
import {
  getById,
  getTracksByPlaylistId,
  updateOrCreate,
} from 'src/api/playlist'
import { CONTENT_PRIVACY } from 'src/constants'
import React from 'react'

const { Title, Text } = Typography

const PlaylistDetail: React.FC = () => {
  const _getById = React.useCallback(async (id: string) => {
    try {
      const [data, { data: tracks }] = await Promise.all([
        getById(id),
        getTracksByPlaylistId(id),
      ])
      data.tracks = tracks
      return data
    } catch (e) {
      message.error('_getById playlist detail error')
    }
  }, [])

  const {
    onFinish,
    onSelectImage,
    onDeleteImage,
    appendForm,
    loading,
    id,
    form,
  } = useDetail({ getById: _getById, updateOrCreate })
  const navigate = useNavigate()

  const _onFinish = React.useCallback(() => {
    if (!form.tracks?.length) {
      message.warning('Please add at least 1 track !')
      return
    }
    onFinish()
  }, [form])

  const normalizeFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const handleAddTag = React.useCallback(
    (tag) => {
      const tags = [...(form.tags || [])]
      tags.push(tag)
      appendForm({ tags }, true)
    },
    [form]
  )

  const handleRemoveTag = React.useCallback(
    (index) => {
      const tags = [...(form.tags || [])]
      tags.splice(index, 1)
      appendForm({ tags }, true)
    },
    [form]
  )

  const setTracks = React.useCallback(
    (tracks: Track[]) => {
      appendForm({ tracks }, true)
    },
    [form]
  )

  return (
    <>
      <Title level={5}>
        <Text>You're {id === 'new' ? 'creating' : 'editing'} playlist</Text>
        <Spin spinning={loading} style={{ marginLeft: 20 }} />
      </Title>
      <Divider />
      {!loading && (
        <Form layout="vertical" onFinish={_onFinish}>
          <Row gutter={24}>
            <Col span={4}>
              <Form.Item label="Cover Image" getValueFromEvent={normalizeFile}>
                <Uploader
                  onChange={onSelectImage}
                  onRemove={onDeleteImage}
                  defaultImageUrl={form.cover_url || form.image?.imageUrl}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Name">
                <Input
                  defaultValue={form.name}
                  onChange={(e) => appendForm({ name: e.target.value })}
                  placeholder="Playlist name"
                />
              </Form.Item>

              <Form.Item label="Privacy">
                <Select
                  defaultValue={form.privacy}
                  onChange={(value) => appendForm({ privacy: value })}
                  placeholder="Select privacy"
                >
                  {CONTENT_PRIVACY.map((val) => (
                    <Select.Option value={val} key={val}>
                      {val}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label="Assigned Tags">
                {form.tags?.length > 0 && (
                  <Space size={[4, 4]} wrap>
                    {form.tags.map((tag, index) => (
                      <Tag
                        key={tag.id}
                        closable
                        onClose={(e) => {
                          e.preventDefault()
                          handleRemoveTag(index)
                        }}
                        style={{ margin: 0 }}
                        color={
                          tag.type === 'MOOD'
                            ? 'blue'
                            : tag.type === 'ACTIVITY'
                            ? 'orange'
                            : 'green'
                        }
                      >
                        {tag.name}
                      </Tag>
                    ))}
                  </Space>
                )}
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Available Tags">
                <AssignTag onAddTag={handleAddTag} selectedTags={form.tags} />
              </Form.Item>
            </Col>
          </Row>

          <Divider orientation="left" plain>
            Track List
          </Divider>

          <AssignTrack tracks={form.tracks} setTracks={setTracks} />

          <Divider />

          <Form.Item>
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={loading}
              >
                {id === 'new' ? 'Create' : 'Update'}
              </Button>
              <Button danger onClick={() => navigate(-1)}>
                Cancel
              </Button>
            </Space>
          </Form.Item>
        </Form>
      )}
    </>
  )
}

PlaylistDetail.displayName = 'PlaylistDetail'
export default PlaylistDetail
