import { useState, useMemo } from 'react'
import { Button } from 'antd'
import { PlayCircleOutlined } from '@ant-design/icons'
import { manualFetch } from 'src/utils/fetch'

import ReactAudioPlayer from 'react-audio-player'

export default function PrivateAudioPlayer({ src, controls, ...rest }) {
  const [audio, setAudio] = useState(src)
  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)

  async function onPlay() {
    if (loaded) return

    if (typeof src !== 'string' || src.includes('data:image')) {
      setAudio(src)
      return
    }

    const options = {
      headers: {
        accept: 'audio/*',
      },
    }
    setLoading(true)

    const response = await manualFetch(src, options)
    const chunks = []
    let blob = new Blob()
    const reader = response.body.getReader()
    let isPlaying = true

    while (isPlaying) {
      const { done, value } = await reader.read()
      if (done) {
        isPlaying = false
        setLoaded(true)
        setAudio(URL.createObjectURL(blob))
        break
      }
      chunks.push(value)
      blob = new Blob(chunks, { type: 'audio/mpeg' })
    }
  }

  if (!loaded) {
    return (
      <Button
        icon={<PlayCircleOutlined />}
        loading={loading}
        onClick={onPlay}
      />
    )
  }

  return (
    <ReactAudioPlayer
      autoPlay={true}
      src={audio}
      controls={controls}
      preload="none"
      {...rest}
    />
  )
}
