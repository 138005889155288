import React from 'react'
import { Button, List, Select, Space, Input, Divider } from 'antd'
import { useState } from 'react'
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons'
import debounce from 'src/utils/debounce'
import TrackItem from './TrackItem'
import { searchTracks } from 'src/api/playlist'

const { Option } = Select

interface AssignTrackProps {
  tracks: Track[]
  setTracks: (params: Record<string, any>) => void
}

const AssignTrack: React.FC<AssignTrackProps> = ({
  setTracks,
  tracks = [],
}) => {
  const [loading, setLoading] = useState(false)
  const [selectedTrack, setSelectedTrack] = useState<Track>(null)
  const [suggestionList, setSuggestionList] = useState([])
  const [searchQuery, setSearchQuery] = useState('')

  const handleSearch = async (value) => {
    setLoading(true)
    const data = await searchTracks({ text: value, page: 0, size: 50 })
    setSuggestionList(data?.data)
    setLoading(false)
  }

  const handleSelect = (value) => {
    const track = suggestionList.find((item) => item.id === value)
    setSelectedTrack(track)
  }

  const handleAddTrack = () => {
    if (!selectedTrack) return
    setTracks([...tracks, selectedTrack])
    setSelectedTrack(null)
  }

  const handleRemoveTrack = (index) => {
    const cloneTracks = [...tracks]
    cloneTracks.splice(index, 1)
    setTracks(cloneTracks)
  }

  const handleListSearch = (e) => {
    setSearchQuery(e.target.value)
  }

  const filteredSuggestionList = suggestionList.filter((item) => {
    return !tracks.find((i) => i.id === item.id)
  })

  const filteredTracks = tracks.filter((track) =>
    track.title.toLowerCase().includes(searchQuery.toLowerCase())
  )

  return (
    <>
      <Space>
        <Select
          showSearch
          autoClearSearchValue={false}
          defaultActiveFirstOption={false}
          filterOption={false}
          notFoundContent={null}
          onSearch={debounce(handleSearch, 500)}
          onSelect={handleSelect}
          style={{ width: 250 }}
          suffixIcon={loading ? <LoadingOutlined /> : <SearchOutlined />}
        >
          {filteredSuggestionList.map((item) => (
            <Option key={item.id}>{item.title}</Option>
          ))}
        </Select>
        <Button onClick={handleAddTrack}>Add Track</Button>
      </Space>
      {tracks?.length > 0 && (
        <div
          style={{
            marginTop: 10,
            maxHeight: 500,
            overflow: 'auto',
            padding: '0 16px',
            border: '1px solid rgba(140, 140, 140, 0.35)',
          }}
        >
          <Input
            placeholder="Search in playlist"
            value={searchQuery}
            onChange={handleListSearch}
            style={{ marginTop: 10, width: 230 }}
          />
          <Divider />
          <List
            itemLayout="horizontal"
            dataSource={filteredTracks}
            rowKey={'id'}
            renderItem={(track, index) => (
              <List.Item>
                <TrackItem
                  track={track}
                  key={track.id}
                  trackIndex={index}
                  onRemove={handleRemoveTrack}
                />
              </List.Item>
            )}
          />
        </div>
      )}
    </>
  )
}

AssignTrack.displayName = 'AssignTrack'
export default AssignTrack
