import { getToken, deleteToken } from './accessToken'
import history from './history'

export function createFetchHeaders(options = {} as any) {
  const { authorize = true } = options
  const headers = new Headers({})
  if (authorize) {
    const token = getToken()
    if (token) {
      headers.set('Authorization', 'Bearer ' + token)
    } else {
      headers.set('Authorization', 'Basic ' + process.env.REACT_APP_CLIENT_AUTH)
    }
  }

  if (options.body instanceof FormData) {
    headers.set('Accept', '*/*')
  } else {
    headers.set('Accept', 'application/json')
    headers.set('Content-Type', 'application/json')
  }

  if (options.headers) {
    for (const key in options.headers) {
      headers.set(key, options.headers[key])
    }
  }

  return headers
}

export async function fetchUtil(url, options = {} as any) {
  if (process.env.NODE_ENV === 'development') {
    document.cookie = 'XDEBUG_SESSION=PHPSTORM'
  }

  const requestURL = url.includes('https://')
    ? url
    : process.env.REACT_APP_API_URL + '/api' + url

  options.headers = createFetchHeaders(options)

  const response = await fetch(requestURL, {
    ...options,
    credentials: 'include',
  })
  const { status } = response
  if (options.responseType === 'blob') {
    const data = await response.arrayBuffer();
    let contentType = '';
  
    if (options.contentType) {
      contentType = options.contentType;
    } else if (response.headers.has('content-type')) {
      contentType = response.headers.get('content-type');
    }
  
    const blob = new Blob([data], { type: contentType });
       if (options.filename === undefined) {
      const fileUrl = URL.createObjectURL(blob)
      window.open(fileUrl, '_blank')
    } else {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = options.filename
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    }
  }
   else {
    let body = {} as any
    try {
      body = await response.json()
    } catch (error) {
      body = {}
    }

  if (status !== 200 && status !== 201 && status !== 204) {
    if (status === 401) {
      deleteToken()
      const basename = process.env.REACT_APP_BASE_URL
      history.push(basename + '/login')
    }
    const formattedError = JSON.stringify({ ...body, status }, null, 2)

    console.error(formattedError)

    throw { ...body, status }
  }

  return { data: body }
}
}
export async function manualFetch(url, options = {} as any) {
  const requestURL =
    url.includes('https://') || url.includes('http://')
      ? url
      : process.env.REACT_APP_API_URL + '/api' + url

  options.headers = createFetchHeaders(options)
  return fetch(requestURL, {
    ...options,
    credentials: 'include',
  })
}

export const buildQuery = (options) => {
  const params = new URLSearchParams()
  for (const key in options) {
    params.append(key, options[key])
  }
  return params
}

export function buildRequest(options) {
  const { pagination, filter } = options
  const params = new URLSearchParams()

  for (const key in pagination) {
    if (key !== 'total') {
      params.append(key, pagination[key])
    }
  }

  for (const key in filter) {
    params.append(key, filter[key])
  }

  return params
}

export default fetchUtil
