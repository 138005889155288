import React from 'react'
import { Button, Modal, Typography } from 'antd'
import PrivateImage from 'src/components/PrivateImage'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import s from './index.module.scss'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { remove } from 'src/api/image'

const { Text } = Typography

interface ThumbProps {
  image: Image
  onEditClick: (image: Image) => void
  onDone: () => void
}

const Thumb: React.FC<ThumbProps> = ({ image, onEditClick, onDone }) => {
  const onRemoveClick = React.useCallback(() => {
    Modal.confirm({
      title: <p>You&apos;re deleting</p>,
      content: (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <PrivateImage
            src={image.url}
            width={50}
            height={50}
            preview={false}
          />
        </div>
      ),
      icon: <ExclamationCircleOutlined />,
      onOk: () => onOkRemoving(),
    })
  }, [image])

  const onOkRemoving = React.useCallback(async () => {
    await remove(image.id)
    onDone && onDone()
  }, [image])

  return (
    <div className={s.thumb}>
      <PrivateImage src={image.url} width={170} height={170} preview={true} />
      <div className={s.thumbAction}>
        <Button
          type="primary"
          shape="circle"
          icon={<EditOutlined />}
          size="small"
          className={s.thumbBtn}
          onClick={() => onEditClick(image)}
        />
        {image.removable && (
          <Button
            type="primary"
            danger
            shape="circle"
            icon={<DeleteOutlined />}
            size="small"
            className={s.thumbBtn}
            onClick={onRemoveClick}
          />
        )}
      </div>
      <div className={s.thumbMeta}>
        <Text className="truncate-2">{image.originalFileName}</Text>
      </div>
    </div>
  )
}

Thumb.displayName = 'Thumb'
export default Thumb
