import React, { useState, useEffect, useCallback } from 'react'
import MarketplaceCard from './marketplacecards'
import { manualFetch } from 'src/utils/fetch'
import { getMarketplace } from 'src/api/marketplace'
import { Spin, Pagination } from 'antd'
import './Sectionmarketplace.css'

const SectionMarketplace = ({ country, searchTerm }) => {
  const [marketplace, setMarketplace] = useState([])
  const [dataLoaded, setDataLoaded] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const pageSize = 10

  const fetchImage = async (url) => {
    const response = await manualFetch(url, {
      headers: {
        accept: 'image/*',
      },
    })
    const imageBlob = await response.blob()
    return URL.createObjectURL(imageBlob)
  }

  const fetchData = useCallback(
    async (page, term) => {
      try {
        setDataLoaded(false)
        const data = await getMarketplace(page, pageSize, term, country)
        const marketplaceData = await Promise.all(
          data.data.map(async (element) => {
            const imageUrlArray = await Promise.all(
              element.images.map((image) => fetchImage(image))
            )
            return {
              ...element,
              imageUrlArray,
            }
          })
        )
        setMarketplace(marketplaceData)
        setTotalItems(data.total_items)
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setDataLoaded(true)
      }
    },
    [country]
  )

  useEffect(() => {
    setCurrentPage(1)
    fetchData(0, searchTerm)
  }, [country, searchTerm])

  useEffect(() => {
    fetchData(currentPage - 1, searchTerm)
  }, [currentPage])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  return (
    <div>
      {dataLoaded ? (
        <div>
          <div className="metaverse-container">
            {marketplace.map((market) => (
              <MarketplaceCard
                key={market.id}
                id={market.id}
                img={market.imageUrlArray}
                name={market.name}
                nameFr={market.nameFr}
                desc={market.description}
                descFr={market.descriptionFr}
                enabled={market.enabled}
                points={market.points}
                price={market.price}
                subTitle={market.subTitle}
                subTitleFr={market.subTitleFr}
                category={market.category}
                originalImage={market.images}
                country={country}
              />
            ))}
          </div>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={totalItems}
            onChange={handlePageChange}
            showSizeChanger={false}
            style={{ marginTop: 10 }}
          />
        </div>
      ) : (
        <div className="spinner-container">
          <Spin size="large" />
        </div>
      )}
    </div>
  )
}

export default SectionMarketplace
