import { Button, Space } from 'antd'
import PrivateImage from 'src/components/PrivateImage'
import PrivateAudioPlayer from 'src/components/PrivateAudioPlayer'

interface TrackItemProps {
  track: Track
  trackIndex: number
  onRemove?: (index: number) => void
}

const TrackItem: React.FC<TrackItemProps> = ({
  track,
  trackIndex,
  onRemove,
}) => {
  function handleRemove() {
    onRemove && onRemove(trackIndex)
  }

  return (
    <div key={track.id}>
      <Space size={16}>
        <PrivateImage
          src={track.image_s3_path}
          width={50}
          height={50}
          preview={false}
        />

        <div style={{ minWidth: 200 }}>{track.title}</div>

        <div
          style={{
            marginRight: 32,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <PrivateAudioPlayer src={track.s3_path} controls />
        </div>

        <Button danger type="primary" onClick={handleRemove}>
          Remove
        </Button>
      </Space>
    </div>
  )
}

TrackItem.displayName = 'TrackItem'
export default TrackItem
