import { Divider, Skeleton, Space, Tag } from 'antd'
import React, { Fragment, useEffect } from 'react'
import { TAG_COLOR } from 'src/constants/tag'
import { fetchAllByGroup } from 'src/api/tag'
import './assignTag.scss'
import useForceRender from 'src/utils/ForceRender'

interface AvailableTagProps {
  name: string
  tags: any[]
  selectedTags: any[]
  onAddTag: (args: any) => void
  type: TagType
  loading: boolean
}

const AvailableTags: React.FC<AvailableTagProps> = ({
  name,
  tags = [],
  selectedTags,
  onAddTag,
  type,
  loading,
}) => {
  const filteredTags = tags.filter(
    (item) => !selectedTags.find((selectedTag) => selectedTag.id === item.id)
  )

  return (
    <div>
      <Divider orientation="left" plain>
        {name} Tags
      </Divider>
      <Skeleton title={false} loading={loading} active>
        {filteredTags.length > 0 && (
          <Space size={[4, 4]} wrap>
            {filteredTags.map((item) => (
              <Tag
                onClick={() => onAddTag({ ...item, type })}
                key={item.id}
                color={TAG_COLOR[type]}
                style={{ margin: 0, cursor: 'pointer' }}
              >
                {item.name}
              </Tag>
            ))}
          </Space>
        )}
      </Skeleton>
    </div>
  )
}

function AssignTag(props) {
  const { onAddTag, selectedTags = [] } = props
  const forceRender = useForceRender()
  const tags = React.useRef<Record<TagType, Tag[]>>({
    MOOD: [],
    ACTIVITY: [],
    GENRE: [],
  })
  const loading = React.useRef<boolean>(true)

  useEffect(() => {
    fetchAllByGroup().then((res) => {
      tags.current = res
      loading.current = false
      forceRender()
    })
  }, [])

  return (
    <Fragment>
      <AvailableTags
        name="Mood"
        tags={tags.current.MOOD}
        selectedTags={selectedTags}
        onAddTag={onAddTag}
        type={'MOOD'}
        loading={loading.current}
      />
      <AvailableTags
        name="Activity"
        tags={tags.current.ACTIVITY}
        selectedTags={selectedTags}
        onAddTag={onAddTag}
        type={'ACTIVITY'}
        loading={loading.current}
      />
      <AvailableTags
        name="Genres"
        tags={tags.current.GENRE}
        selectedTags={selectedTags}
        onAddTag={onAddTag}
        type={'GENRE'}
        loading={loading.current}
      />
    </Fragment>
  )
}

export default AssignTag
