import { Button, Input, Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { STATESOPTIONS } from 'src/constants'
import { useEffect } from 'react'
import './SearchRedeemUser.css'

interface RedeemUserSearchProps {
  appendSearchForm: (params: any) => void
  submitSearchForm: () => void
  resetFields: boolean
  searchParams: {
    name: string
    phone: string
    email: string
    marketPlaceName: string
    country: string
    state?: string
  }
}

const RedeemUserSearch = ({
  appendSearchForm,
  submitSearchForm,
  resetFields,
  searchParams,
}: RedeemUserSearchProps) => {
  useEffect(() => {
    if (resetFields) {
      appendSearchForm({
        name: '',
        phone: '',
        email: '',
        marketPlaceName: '',
        country: '',
        state: '',
      })
    }
  }, [resetFields])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    appendSearchForm({ [name]: value })
  }

  const handleSelectChange = (name: string, value: string) => {
    appendSearchForm({ [name]: value })
  }

  return (
    <div className="search-container">
      <Input
        name="name"
        placeholder="Name"
        allowClear
        value={searchParams.name}
        onChange={handleInputChange}
        className="search-input"
      />
      <Input
        name="phone"
        placeholder="Phone"
        allowClear
        value={searchParams.phone}
        onChange={handleInputChange}
        className="search-input"
      />
      <Input
        name="country"
        placeholder="Country"
        allowClear
        value={searchParams.country}
        onChange={handleInputChange}
        className="search-input"
      />
      <Input
        name="email"
        placeholder="Email"
        allowClear
        value={searchParams.email}
        onChange={handleInputChange}
        className="search-input"
      />
      <Input
        name="marketPlaceName"
        placeholder="Marketplace Name"
        allowClear
        value={searchParams.marketPlaceName}
        onChange={handleInputChange}
        className="search-input"
      />
      <Select
        className="search-input"
        placeholder="State"
        value={searchParams.state || undefined}
        onChange={(value) => handleSelectChange('state', value)}
        options={STATESOPTIONS}
        allowClear
      />

      <Button
        type="primary"
        icon={<SearchOutlined />}
        onClick={submitSearchForm}
        className="search-btn"
      >
        Search
      </Button>
    </div>
  )
}

export default RedeemUserSearch
