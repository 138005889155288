import React, { useRef } from 'react'
import { Button, Modal, Typography } from 'antd'
import { useDrag, useDrop } from 'react-dnd'
import PrivateImage from 'src/components/PrivateImage'
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import s from './index.module.scss'

const { Text } = Typography

interface CarouselItemProps {
  game: Games
  categoryId: string
  onDrop: (drapId: string, dropId: string) => void
  onRemove: (game: Games) => void
}

const CarouselItem: React.FC<CarouselItemProps> = ({
  game,
  categoryId,
  onDrop = () => null,
  onRemove = () => null,
}) => {
  const shouldShowButton = ['ADVENTURE', 'QUIZ', 'PUZZLE','CARDS','ACTION','SPORTS'].includes(categoryId.toUpperCase());
  
  const ref = useRef(null)
  const dndType = `card_${categoryId}`
  const [, drag] = useDrag(() => ({
    type: dndType,
    item: {
      id: game.id,
    },
  }))
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: dndType,
      drop(item: any) {
        onDrop(item.id, game.id)
      },
      collect: (monitor) => {
        return {
          isOver: monitor.isOver({ shallow: true }),
        }
      },
    }),
    []
  )

  const onRemoveClick = React.useCallback(() => {
    Modal.confirm({
      title: (
        <p>
          You&apos;re deleting <strong>{game.name}</strong>
        </p>
      ),
      icon: <ExclamationCircleOutlined />,
      onOk: () => onRemove && onRemove(game),
    })
  }, [game])

  drop(drag(ref))

  

  return (
    <div
      
      className={s.carouselItem}
    >
      <div ref={ref} className={s.carouselItemCard}>
        <PrivateImage
          src={game.thumbnailS3Path}
          width={'100%'}
          height={140}
          preview={false}
        />
        <div className={s.carouselItemMeta}>
        <Text strong>
        {shouldShowButton ? game.name : game.title}
      </Text>
          <Text italic type="secondary">
            Rating: {game.rating}
          </Text>
        </div>
        {!shouldShowButton && (  <Button
        
          type="primary"
          danger
          
          shape="circle"
          icon={<DeleteOutlined />}
          size="small"
          className={s.carouselItemDelBtn}
          onClick={onRemoveClick}
        />
        )}
      </div>
    </div>
  )
}
export default CarouselItem
