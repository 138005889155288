import { message } from 'antd'
import { omit, pick } from 'ramda'
import { fetchUtil as fetch, buildQuery } from 'src/utils/fetch'

export const order = async (playlistId, newIndex) => {
  try {
    const body = {
      order_id: newIndex,
    }
    return fetch(`/playlist/${playlistId}/order`, {
      method: 'PATCH',
      body: JSON.stringify(body),
    })
  } catch (e) {
    message.error('onOrder playlist error')
  }
}

export const getAll = async (params: Record<string, any>) => {
  const query = buildQuery(pick(['page', 'size'], params))
  const body = omit(['page', 'size'], params)
    try {
    const query = buildQuery(params)
    const { data } = await fetch(`/playlist?${query}`)
    return data
  } catch (e) {
    message.error('getAll playlist error')
  }
}

export const remove = async (id) => {
  try {
    return fetch(`/playlist/${id}`, {
      method: 'DELETE',
    })
  } catch (error) {
    message.error('remove playlist error')
  }
}

export const update = (data) => {
  try {
    return fetch(`/playlist/${data.id}`, {
      method: 'PATCH',
      body: JSON.stringify(data),
    })
  } catch (error) {
    message.error('update playlist error')
  }
}

export const create = async (data) => {
  const body = new FormData()
  Object.entries(data)
    .filter(([name]) =>
      ['name', 'image', 'privacy', 'tracks', 'tags'].includes(name)
    )
    .forEach(([name, value]: any) => {
      if (!value) return
      switch (name) {
        case 'image':
          body.append('coverImage', value.file, value.file.name)
          break
        case 'tags':
          body.append(
            name,
            value.map((item) => item.id)
          )
          break
        case 'tracks':
          body.append(
            name,
            value.map((item) => item.id)
          )
          break
        default:
          body.append(name, value)
      }
    })

  return fetch(`/playlist`, {
    method: 'POST',
    body,
  })
}

export const updateTags = (data: { id: string; tags: { id: string }[] }) => {
  try {
    return update(data)
  } catch (e) {
    message.error('updateTags playlist error')
  }
}

export const getById = async (id: string) => {
  try {
    const { data } = await fetch(`/playlist/${id}`)
    return data
  } catch (e) {
    message.error('getById playlist error')
  }
}

export const uploadImage = async (id: string, file: any) => {
  try {
    const body = new FormData()
    body.append('coverImage', file, file.name)
    return await fetch(`/playlist/${id}/image`, {
      method: 'PUT',
      body,
    })
  } catch (e) {
    message.error('uploadImage playlist error')
  }
}

export const updateOrCreate = async (
  id: string,
  payload: Record<string, any>
) => {
  try {
    let res = null
    if (id && id !== 'new') {
      if (payload.image?.file) {
        await uploadImage(id, payload.image?.file)
      }
      const tracks = payload.tracks?.map((i) => i.id)
      res = await update({
        ...pick(['id', 'name', 'privacy', 'tags'], payload),
        tracks,
      })
    } else {
      res = await create(payload)
    }
    return res
  } catch (e) {
    message.error('updateOrCreate playlist error !')
    throw e
  }
}

export const searchTracks = async (params) => {
  try {
    const query = buildQuery(params)
    const { data } = await fetch(`/playlist/searchTracks?${query}`)
    return data
  } catch (e) {
    message.error('searchTracks playlist error !')
  }
}

export const getTracksByPlaylistId = async (playlistId) => {
  try {
    const query = buildQuery({ page: 0, size: 500 })
    const paramsStr = query ? `?${query}` : ''
    const { data } = await fetch(`/playlist/${playlistId}/track` + paramsStr)
    return data
  } catch (e) {
    message.error('getTracksByPlaylistId error !')
  }
}

export const search = async (params: Record<string, any>) => {
  const query = buildQuery(pick(['page', 'size'], params))
  const body = omit(['page', 'size'], params)
  if (Object.keys(body).length === 0) {
    message.error('No searching query !')
    return
  }
  if (body.tags) {
    body.tags = body.tags.split(',')
  }
  try {
    const { data } = await fetch(`/playlist/search?${query}`, {
      method: 'POST',
      body: JSON.stringify(body),
    })
    return data
  } catch (e) {
    message.error('search playlist error !')
  }
}
