import { Select, Space, Tag, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import React, { useRef, useState } from 'react'
import debounce from 'src/utils/debounce'
import useForceRender from 'src/utils/ForceRender'
import { TAG_COLOR } from 'src/constants/tag'
import { search } from 'src/api/tag'
import { update } from 'src/api/station'

const { Option } = Select

const TagColumn = ({ tags = [], id }) => {
  const [showInput, setShowInput] = useState(false)
  const addedTags = useRef<{ id: string; name: string; type: TagType }[]>(tags)
  const forceRender = useForceRender()

  const loading = React.useRef<boolean>(false)
  const suggestionList = React.useRef([])

  const handleSearch = React.useCallback(async (value) => {
    if (!value) return
    loading.current = true
    forceRender()
    const data = await search({ name: value, size: 50 })
    suggestionList.current = data.data
    loading.current = false
    forceRender()
  }, [])

  const handleSelect = async (option) => {
    update({
      id,
      tags: [
        ...addedTags.current.map((tag) => ({ id: tag.id })),
        { id: option.value },
      ],
    }).then(() => {
      const item = suggestionList.current.find(
        (item) => item.id === option.value
      )
      addedTags.current.push({ name: item.name, id: item.id, type: item.type })
      setShowInput(false)
    })
  }

  const handleBlur = () => {
    setShowInput(false)
  }

  const handleRemoveTag = async (tagId) => {
    update({
      id,
      tags: [
        ...addedTags.current
          .filter((tag) => tag.id !== tagId)
          .map((tag) => ({ id: tag.id })),
      ],
    }).then(() => {
      addedTags.current = addedTags.current.filter((tag) => tag.id !== tagId)
      forceRender()
    })
  }

  const filteredSuggestionList = suggestionList.current.filter((item) => {
    return !addedTags.current.find((i) => i.id === item.id)
  })

  return (
    <Space size={[4, 4]} wrap>
      {addedTags.current.map((item) => (
        <Tag
          key={item.id}
          style={{ margin: 0 }}
          closable
          color={TAG_COLOR[item.type]}
          onClose={(e) => {
            e.preventDefault()
            handleRemoveTag(item.id)
          }}
        >
          {item.name}
        </Tag>
      ))}

      {showInput ? (
        <Select
          showSearch
          autoFocus
          autoClearSearchValue={false}
          defaultActiveFirstOption={false}
          filterOption={false}
          notFoundContent={null}
          onSearch={debounce(handleSearch, 500)}
          onSelect={handleSelect}
          onBlur={handleBlur}
          style={{ width: 120 }}
          size="small"
          placeholder="Tag name"
          labelInValue={true}
          loading={loading.current}
        >
          {filteredSuggestionList.map((item) => (
            <Option key={item.id}>{item.name}</Option>
          ))}
        </Select>
      ) : (
        <Button type="primary" size="small" onClick={() => setShowInput(true)}>
          <PlusOutlined /> Add tag
        </Button>
      )}
    </Space>
  )
}

export default TagColumn
