import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import './dragTableRow.scss'

const type = 'DragTableRow'

const DragTableRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = useRef(null)
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {}

      if (dragIndex === index) {
        return {}
      }

      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      }
    },
    drop: (item) => {
      moveRow(item.index, index)
    },
  })

  const [, drag] = useDrag({
    type,
    item: {
      index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drop(drag(ref))

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{
        cursor: 'move',
        ...style,
      }}
      {...restProps}
    />
  )
}

export default DragTableRow
