import React, { useState, useEffect } from 'react'
import MetaverseCard from './metaversecards'
import { manualFetch } from 'src/utils/fetch'
import { getMetaverseRoomsByCategory } from 'src/api/metaverse'
import { Spin } from 'antd'
import './Sectionmetaverse.css'

const SectionMetaverse = ({ name }) => {
  const [metaverse, setMetaverse] = useState([])
  const [dataLoaded, setDataLoaded] = useState(false)

  const fetchImage = (url: string): Promise<string> => {
    return manualFetch(url, {
      headers: {
        accept: 'image/*',
      },
    })
      .then((response) => response.blob())
      .then((imageBlob) => {
        const imageUrl = URL.createObjectURL(imageBlob)
        return imageUrl
      })
  }

  const fetchData = async () => {
    try {
      const data = await getMetaverseRoomsByCategory(name)
      const metaverseData = await Promise.all(
        data.data.map(async (element) => {
          const imageUrl = await fetchImage(element.fullS3Path)
          const thumbnailImageUrl = await fetchImage(element.thumbnailS3Path)
          return {
            ...element,
            imageUrl,
            thumbnailImageUrl,
          }
        })
      )
      setMetaverse(metaverseData)
      setDataLoaded(true)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [name])

  return (
    <div>
      <h1
        style={{
          fontSize: '24px',
          margin: '20px 0',
          height: '20px',
        }}
      >
        {name}
      </h1>
      {dataLoaded ? (
        <div>
          <div className="metaverse-container">
            {metaverse.map((room) => (
              <MetaverseCard
                key={room.id}
                id={room.id}
                img={room.imageUrl}
                thumbImg={room.thumbnailImageUrl}
                title={room.title}
                titleFr={room.titleFr}
                desc={room.description}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="spinner-container">
          <Spin size="large" />
        </div>
      )}
    </div>
  )
}

export default SectionMetaverse
