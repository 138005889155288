import { message } from 'antd'
import { omit, pick } from 'ramda'
import { fetchUtil as fetch, buildQuery } from 'src/utils/fetch'
export const getAll = async (params: Record<string, any>) => {
    const query = buildQuery(pick(['page', 'pageSize'], params))
  const body = omit(['page', 'pageSize'], params)
    try {
      
      const { data } = await fetch(`/user-profile/search?${query}`,{
        method: 'POST',
        body: JSON.stringify(body),
      })
      return data
    } catch (e) {
      message.error('getAll user error')
    }
  }

  export const changeUserStatus = async (id,status) => {
    try {
      return fetch(`/auth/user/${id}/active`, {
        method: 'PATCH',
        body:JSON.stringify({
            active_status:status,

      })
      })
    } catch (error) {
      message.error('user status change error')
    }
  }


  export const search = async (params: Record<string, any>) => {
    const query = buildQuery(pick(['page', 'pageSize'], params))
    const body = omit(['page', 'pageSize'], params)
    // if (Object.keys(body).length === 0) {
    //   message.error('No searching query !')
    //   return
    // }
    
    try {
      const { data } = await fetch(`/user-profile/search?${query}`, {
        method: 'POST',
        body: JSON.stringify(body),
      })
      return data
    } catch (e) {
      message.error('search User error !')
    }
  }