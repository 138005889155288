import { Divider, Select, Input, Typography, Table, Spin, Button } from 'antd'
import { useEffect, useState } from 'react'
import { availablePermissions } from 'src/constants'
import { fetchAdminUsers, editAdminUsers } from 'src/api/authen'
import { DndProvider } from 'react-dnd'
import { SearchOutlined } from '@ant-design/icons'
import { HTML5Backend } from 'react-dnd-html5-backend'

const { Title } = Typography
const { Option } = Select

const Admin = () => {
  const [loading, setLoading] = useState(true)
  const [username, setUsername] = useState('')
  const [adminUserData, setAdminUserData] = useState({
    data: [],
    current_page: 0,
    number_of_elements: 0,
    total_pages: 0,
    total_items: 0,
  })
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalRecords, setTotalRecords] = useState(0)

  const [transformedData, setTransformedData] = useState([])

  useEffect(() => {
    fetchData(currentPage, pageSize, username)
  }, [pageSize, currentPage])

  useEffect(() => {
    const data =
      adminUserData.data && adminUserData.data.length
        ? Object.entries(adminUserData.data[0]).map(([key, value], index) => ({
            key: index,
            user: key,
            permissions:
              Array.isArray(value) && value.length
                ? value.filter((permission) => permission !== 'superadmin')
                : [],
          }))
        : []
    setTransformedData(data)
  }, [adminUserData])

  const fetchData = async (page, size, username) => {
    setLoading(true)
    try {
      const response = await fetchAdminUsers(page - 1, size, username)
      setAdminUserData(response)
      setTotalRecords(response.total_items)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  const search = () => {
    setTransformedData([])
    setCurrentPage(1)
    fetchData(1, 10, username)
  }

  const handleTableChange = (pagination: any) => {
    setTransformedData([])
    setCurrentPage(pagination.current)
    setPageSize(pagination.pageSize)
  }

  const handlePermissionChange = async (updatedPermissions, record) => {
    setLoading(true)
    await editAdminUsers(record.user, updatedPermissions)
      .catch((error) => {
        console.error('Error updating permissions:', error)
      })
      .finally(() => {
        setLoading(false)
      })
    fetchData(currentPage, pageSize, username)
  }

  const handleInputChange = (e) => {
    setUsername(e.target.value)
  }

  const columns = [
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: 'Permissions',
      dataIndex: 'permissions',
      key: 'permissions',
      render: (permissions, record) => (
        <Select
          mode="multiple"
          defaultValue={permissions}
          style={{ width: 400 }}
          placeholder={
            permissions.length === 0 ? 'Select permission' : undefined
          }
          onChange={(updatedPermissions) =>
            handlePermissionChange(updatedPermissions, record)
          }
        >
          {availablePermissions.map((permission) => (
            <Option key={permission} value={permission}>
              {permission}
            </Option>
          ))}
        </Select>
      ),
    },
  ]

  return (
    <>
      <Input
        name="Username"
        placeholder="Username"
        allowClear
        value={username}
        onChange={handleInputChange}
        className="search-input"
      />
      <Button
        type="primary"
        icon={<SearchOutlined />}
        onClick={search}
        className="search-btn"
      >
        Search
      </Button>
      <Divider />

      <Spin spinning={loading}>
        <DndProvider backend={HTML5Backend}>
          <Table
            dataSource={transformedData}
            columns={columns}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              showSizeChanger: true,
              pageSizeOptions: ['10', '25', '50', '100'],
              total: totalRecords,
            }}
            onChange={handleTableChange}
          />
        </DndProvider>
      </Spin>
    </>
  )
}

export default Admin
