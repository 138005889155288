import { Table, Button, Divider, Select, Input } from 'antd'
import React from 'react'
import moment from 'moment'
import PrivateImage from 'src/components/PrivateImage'
import { Link } from 'react-router-dom'
import { getAll } from 'src/api/tag'
import { TAG_TYPES } from 'src/constants/tag'
import usePage from 'src/hooks/usePage'

const { Column } = Table
const { Option } = Select
const { Search } = Input

const TagPage: React.FC = () => {
  const { pageData, onQuery, queryObj } = usePage({ getData: getAll })

  return (
    <>
      <Button type="primary">
        <Link to="/tag/new">Create</Link>
      </Button>
      <Divider />
      <Table
        rowKey="id"
        dataSource={pageData.data}
        loading={pageData.loading}
        pagination={{
          current: parseInt(pageData.query.page) + 1,
          total: pageData.total,
          onChange: (p) => onQuery({ page: (p - 1).toString() }),
        }}
        title={() => (
          <>
            <Search
              placeholder="Search by name"
              onSearch={(v) => onQuery({ page: '0', name: v })}
              allowClear
              style={{ width: 250, marginRight: 24 }}
              defaultValue={queryObj['name']}
            />
            <Select
              onChange={(v) => onQuery({ page: '0', type: v })}
              allowClear
              placeholder="Filter by type"
              style={{ width: 250 }}
              defaultValue={queryObj['type']}
            >
              {TAG_TYPES.map((value) => (
                <Option value={value} key={value}>
                  {value}
                </Option>
              ))}
            </Select>
          </>
        )}
      >
        <Column
          width={120}
          dataIndex="image_s3_path"
          title="Cover Image"
          render={(url) => (
            <PrivateImage width={90} height={90} src={url} preview={false} />
          )}
        />
        <Column
          dataIndex="name"
          title="Name"
          render={(data, row: any) => <Link to={`/tag/${row.id}`}>{data}</Link>}
        />
        <Column dataIndex="type" title="Type" />
        <Column
          dataIndex="modified_on"
          title="Last date changed"
          render={(data) => moment(data).format('DD/MM/YYYY hh:mm:ss')}
        />
      </Table>
    </>
  )
}

TagPage.displayName = 'TagPage'
export default TagPage
