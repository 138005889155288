import React, { useEffect, useCallback } from 'react'
import { Divider, Typography, Spin, message } from 'antd'
import { DndProvider } from 'react-dnd'
import CarouselItem from './carousel-item'
import Carousel from './carousel'
import { HTML5Backend } from 'react-dnd-html5-backend'
import useForceRender from 'src/utils/ForceRender'
import { clone } from 'ramda'
 import * as api from 'src/api/game'
 import * as apiMaze from 'src/api/maze'
import s from './index.module.scss'

const { Title } = Typography

const MazeDiscovery: React.FC = () => {
  const forceRender = useForceRender()
  const selectedCategory = React.useRef<GamesCategorySorting>(null)
  const showAdding = React.useRef<boolean>(false)
  const loading = React.useRef<boolean>(true)
  const data = React.useRef<any[]>([])

  const loadPageData = useCallback(() => {
    apiMaze.getMazeCategories().then((categories) => {
      loading.current = false
      data.current = categories
      forceRender()
    })
  }, [])

  useEffect(() => {
    loadPageData()
  }, [])

  const onModalCancel = React.useCallback(() => {
    selectedCategory.current = null
    showAdding.current = false
    forceRender()
  }, [])

  const onAddClick = React.useCallback((category: GamesCategorySorting) => {
    selectedCategory.current = category
    showAdding.current = true
    forceRender()
  }, [])

  const onModalDone = React.useCallback(() => {
    onModalCancel()
    loadPageData()
  }, [])
  const updateOrdering = React.useCallback(
    async (dragId: string, dropId: string, category: GamesCategorySorting) => {
      const categoryNew = data.current.find((cat: GamesCategorySorting) => cat.id === category.id) as GamesCategorySorting;

      const dragIndex = categoryNew.games.findIndex((i) => i.id === dragId);
      const dropIdIndex = categoryNew.games.findIndex((i) => i.id === dropId);

      let dropIndex: number;

      if (dropIdIndex === 0) {
        
        
        dropIndex = dropIdIndex;
      } else if (dragIndex > dropIdIndex) {
      
        dropIndex = dropIdIndex;
      } else {
        
        dropIndex = dropIdIndex+1;
      }
      const dragItem = categoryNew.games[dragIndex];
      const tempItem = { id: 'temp' } as any;
      const cloneItems = [...categoryNew.games];
      cloneItems[dragIndex] = tempItem;
      cloneItems.splice(dropIndex, 0, dragItem);
      const orderedItems = cloneItems.filter((i) => i.id !== 'temp');

    

      orderedItems.forEach((item, index) => {
       
        if (category.id.toUpperCase() === 'MAZE') {
          item.mazeSortIndex = index + 1;
        }

      });

      

      const updatedCategory = {
        ...categoryNew,
        games: orderedItems,
      };
  
      loading.current = true;
      forceRender();
      await api.updateGamesOrder(categoryNew.id, updatedCategory);
      
      message.success(`Updated  ordering of ${categoryNew.type}`);
      loadPageData();
    },
    [data]
  );

  

 

  return (
    <Spin spinning={loading.current}>
      <Title level={3}>Maze</Title>
      <Divider />
      <DndProvider backend={HTML5Backend}>
        {data.current?.map((category) => (
          <Carousel
            key={category.id}
            name={category.type}
            onAddClick={() => onAddClick(category)}
          >
            {category.games?.length ? (
              category.games.map((item) => (
                <CarouselItem
                  key={item.id}
                  game={item}
                  categoryId={category.id}
                  onDrop={(drapId, dropId) =>
                    updateOrdering(drapId, dropId, category)
                  }
                  
                />
              ))
            ) : (
              <span className={s.noData}> No data</span>
            )}
          </Carousel>
        ))}
      </DndProvider>
     
    </Spin>
  )
}

MazeDiscovery.displayName = 'Discovery'

export default MazeDiscovery
