import { message } from 'antd'
import { fetchUtil as fetch } from 'src/utils/fetch'

export const getGuys = async (page, size, name = '') => {
  try {
    const { data } = await fetch('/metaverse-rooms/guys/search/admin', {
      method: 'POST',
      body: JSON.stringify({
        size: size,
        page: page,
        name: name,
      }),
      headers: {
        role: 'ADMIN',
      },
    })
    return data
  } catch (e) {
    message.error('Search Guys Error !')
  }
}

export const saveEditGuys = async (
  id,
  title,
  titleFr,
  desc,
  descFr,
  instruction,
  instructionFr,
  img,
  thumbImage,
  enabled
) => {
  try {
    const input = new FormData()
    input.append(
      'guysEditDto',
      new Blob(
        [
          JSON.stringify({
            id: id,
            title: title,
            titleFr: titleFr,
            description: desc,
            descriptionFr: descFr,
            enabled: enabled,
            instruction: instruction,
            instructionFr: instructionFr,
          }),
        ],
        {
          type: 'application/json',
        }
      )
    )
    input.append('image', img)
    input.append('thumbImage', thumbImage)

    const { data } = await fetch('/metaverse-rooms/guys/edit', {
      method: 'POST',
      body: input,
      headers: {
        role: 'ADMIN',
      },
    })
    message.success('Success Edit Guys!')
    return 'success'
  } catch (e) {
    message.error('Error Edit Guys!')
    return 'failed'
  }
}
