import { message } from 'antd'
import { fetchUtil as fetch, buildQuery } from 'src/utils/fetch'

export const getAll = async (params) => {
  try {
    const query = buildQuery(params)
    const { data } = await fetch(`/playlist/images?${query}`)
    return data
  } catch (e) {
    message.error('getAllImages error')
  }
}

export const create = async (type: ImageType, file: any) => {
  try {
    const body = new FormData()
    body.append('image', file, file.name)
    body.append('type', type)
    const { data } = await fetch(`/playlist/images/upload`, {
      method: 'POST',
      body,
    })
    return data
  } catch (e) {
    message.error('create image error')
  }
}

export const update = async (id: string, file: any) => {
  try {
    const body = new FormData()
    body.append('image', file, file.name)
    const { data } = await fetch(`/playlist/images/${id}`, {
      method: 'PATCH',
      body,
    })
    return data
  } catch (e) {
    message.error('update image error')
  }
}

export const updateOrCreate = async (
  id: string,
  payload: Record<string, any>
) => {
  try {
    let res = null
    if (id && id !== 'new') {
      res = await update(id, payload.image.file)
    } else {
      res = await create(payload.type, payload.image.file)
    }
    return res
  } catch (e) {
    message.error('updateOrCreate image error !')
    throw e
  }
}

export const remove = async (id: string) => {
  try {
    const { data } = await fetch(`/playlist/images/${id}`, {
      method: 'DELETE',
    })
    return data
  } catch (e) {
    message.error('remove images error')
  }
}
