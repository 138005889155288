export const CONTENT_PRIVACY: Privacy[] = ['PUBLIC', 'PRIVATE']
export const AGE_SEARCH_TYPE: AgeSearchType[] = [
  'Between',
  'Greater Than',
  'Less Than',
]
export const SEARCH_QUERY_TYPE: SearchQueryType[] = [
  'By User Name',
  'By Age',
  'By Country',
  'By Gender',
  'By Device Type',
  'By Provider',
  'By Game',
  'By Metaverse',
  'By Maze',
  'Skipped',
  'By Event Date'
]
export const GROUP_QUERY_TYPE = [
  { label: 'By User Name', value: 'USER_NAME' },
];

export const GENDER_TYPE: GenderType[] = ['Male', 'Female']
export const PHONE_MODEL: PhoneModel[] = ['Android', 'ios']
export const EVENT_NAMES: TrackEvents[] = ['TRACK_LISTENING', 'TRACK_SKIPPED']
export const PROVIDER_COUNTRY: ProviderCountry[] = ['TN']
export const COUNTRY_TYPE: Countries[] = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo Democratic Republic of the',
  'Congo Republic of the',
  'Costa Rica',
  "Cote d'Ivoire",
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'East Timor (Timor-Leste)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea North',
  'Korea South',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia Federated States of',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar (Burma)',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States of America',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City (Holy See)',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe',
]

export const MONTHS = [
  { label: 'January', value: 'JANUARY' },
  { label: 'February', value: 'FEBRUARY' },
  { label: 'March', value: 'MARCH' },
  { label: 'April', value: 'APRIL' },
  { label: 'May', value: 'MAY' },
  { label: 'June', value: 'JUNE' },
  { label: 'July', value: 'JULY' },
  { label: 'August', value: 'AUGUST' },
  { label: 'September', value: 'SEPTEMBER' },
  { label: 'October', value: 'OCTOBER' },
  { label: 'November', value: 'NOVEMBER' },
  { label: 'December', value: 'DECEMBER' },
]

export const YEARS = [
  { label: '2023', value: '2023' },
  { label: '2024', value: '2024' },
  { label: '2025', value: '2025' },
  { label: '2026', value: '2026' },
  { label: '2027', value: '2027' },
  { label: '2028', value: '2028' },
  { label: '2029', value: '2029' },
  { label: '2030', value: '2030' },
  { label: '2031', value: '2031' },
  { label: '2032', value: '2032' },
  { label: '2033', value: '2033' },
  { label: '2034', value: '2034' },
  { label: '2035', value: '2035' },
]

export const LEVELS = [
  { label: 'Easy', value: 'EASY' },
  { label: 'Medium', value: 'MEDIUM' },
  { label: 'Hard', value: 'HARD' },
]
export const metaverseMapping = {
  0: 'Rock',
  1: 'Hip Hop',
  2: 'EDM',
  3: 'R&B',
  4: 'Metal',
  5: 'Classic',
  6: 'Arena',
  7: 'Pop',
  8: 'Zouglou',
  9: 'Country',
  10: 'Latino',
  11: 'Kpop',
  12: 'MainStage',
  13: 'CoveBeachNight',
  14: 'Tunisia',
  15: 'CelaviNightRoom',
  16: 'Casino',
  26: 'India',
  30: 'Hotel 5',
  31: 'Library',
}
export const mazeMapping = {
  17: 'Cards',
  18: 'Horror',
  19: 'Inca',
  20: 'Runner',
  21: 'Grass',
  22: 'Castle',
  23: 'Cheese',
  24: 'Ship',
  25: 'Candy',
  27: 'Orange',
  29: 'Back to School',
}

export const STATESOPTIONS = [
  { label: 'Under Process', value: 'UNDER_PROCESS' },
  { label: 'Delivered', value: 'DELIVERED' },
  { label: 'Canceled', value: 'CANCELED' },
]

export const countryCodes = [
  { code: '+1', name: 'USA/Canada' },
  { code: '+7', name: 'Russia' },
  { code: '+20', name: 'Egypt' },
  { code: '+27', name: 'South Africa' },
  { code: '+30', name: 'Greece' },
  { code: '+31', name: 'Netherlands' },
  { code: '+32', name: 'Belgium' },
  { code: '+33', name: 'France' },
  { code: '+34', name: 'Spain' },
  { code: '+36', name: 'Hungary' },
  { code: '+39', name: 'Italy' },
  { code: '+40', name: 'Romania' },
  { code: '+41', name: 'Switzerland' },
  { code: '+43', name: 'Austria' },
  { code: '+44', name: 'United Kingdom' },
  { code: '+45', name: 'Denmark' },
  { code: '+46', name: 'Sweden' },
  { code: '+47', name: 'Norway' },
  { code: '+48', name: 'Poland' },
  { code: '+49', name: 'Germany' },
  { code: '+52', name: 'Mexico' },
  { code: '+53', name: 'Cuba' },
  { code: '+54', name: 'Argentina' },
  { code: '+55', name: 'Brazil' },
  { code: '+56', name: 'Chile' },
  { code: '+57', name: 'Colombia' },
  { code: '+58', name: 'Venezuela' },
  { code: '+60', name: 'Malaysia' },
  { code: '+61', name: 'Australia' },
  { code: '+62', name: 'Indonesia' },
  { code: '+63', name: 'Philippines' },
  { code: '+64', name: 'New Zealand' },
  { code: '+65', name: 'Singapore' },
  { code: '+66', name: 'Thailand' },
  { code: '+81', name: 'Japan' },
  { code: '+82', name: 'South Korea' },
  { code: '+84', name: 'Vietnam' },
  { code: '+86', name: 'China' },
  { code: '+90', name: 'Turkey' },
  { code: '+91', name: 'India' },
  { code: '+92', name: 'Pakistan' },
  { code: '+93', name: 'Afghanistan' },
  { code: '+94', name: 'Sri Lanka' },
  { code: '+95', name: 'Myanmar' },
  { code: '+98', name: 'Iran' },
  { code: '+211', name: 'South Sudan' },
  { code: '+212', name: 'Morocco' },
  { code: '+213', name: 'Algeria' },
  { code: '+216', name: 'Tunisia' },
  { code: '+218', name: 'Libya' },
  { code: '+220', name: 'Gambia' },
  { code: '+221', name: 'Senegal' },
  { code: '+222', name: 'Mauritania' },
  { code: '+223', name: 'Mali' },
  { code: '+224', name: 'Guinea' },
  { code: '+225', name: 'Ivory Coast' },
  { code: '+226', name: 'Burkina Faso' },
  { code: '+227', name: 'Niger' },
  { code: '+228', name: 'Togo' },
  { code: '+229', name: 'Benin' },
  { code: '+230', name: 'Mauritius' },
  { code: '+231', name: 'Liberia' },
  { code: '+232', name: 'Sierra Leone' },
  { code: '+233', name: 'Ghana' },
  { code: '+234', name: 'Nigeria' },
  { code: '+235', name: 'Chad' },
  { code: '+236', name: 'Central African Republic' },
  { code: '+237', name: 'Cameroon' },
  { code: '+238', name: 'Cape Verde' },
  { code: '+239', name: 'São Tomé and Príncipe' },
  { code: '+240', name: 'Equatorial Guinea' },
  { code: '+241', name: 'Gabon' },
  { code: '+242', name: 'Republic of the Congo' },
  { code: '+243', name: 'Democratic Republic of the Congo' },
  { code: '+244', name: 'Angola' },
  { code: '+245', name: 'Guinea-Bissau' },
  { code: '+246', name: 'Diego Garcia' },
  { code: '+247', name: 'Ascension Island' },
  { code: '+248', name: 'Seychelles' },
  { code: '+249', name: 'Sudan' },
  { code: '+250', name: 'Rwanda' },
  { code: '+251', name: 'Ethiopia' },
  { code: '+252', name: 'Somalia' },
  { code: '+253', name: 'Djibouti' },
  { code: '+254', name: 'Kenya' },
  { code: '+255', name: 'Tanzania' },
  { code: '+256', name: 'Uganda' },
  { code: '+257', name: 'Burundi' },
  { code: '+258', name: 'Mozambique' },
  { code: '+260', name: 'Zambia' },
  { code: '+261', name: 'Madagascar' },
  { code: '+262', name: 'Réunion' },
  { code: '+263', name: 'Zimbabwe' },
  { code: '+264', name: 'Namibia' },
  { code: '+265', name: 'Malawi' },
  { code: '+266', name: 'Lesotho' },
  { code: '+267', name: 'Botswana' },
  { code: '+268', name: 'Eswatini' },
  { code: '+269', name: 'Comoros' },
  { code: '+290', name: 'Saint Helena' },
  { code: '+291', name: 'Eritrea' },
  { code: '+297', name: 'Aruba' },
  { code: '+298', name: 'Faroe Islands' },
  { code: '+299', name: 'Greenland' },
  { code: '+350', name: 'Gibraltar' },
  { code: '+351', name: 'Portugal' },
  { code: '+352', name: 'Luxembourg' },
  { code: '+353', name: 'Ireland' },
  { code: '+354', name: 'Iceland' },
  { code: '+355', name: 'Albania' },
  { code: '+356', name: 'Malta' },
  { code: '+357', name: 'Cyprus' },
  { code: '+358', name: 'Finland' },
  { code: '+359', name: 'Bulgaria' },
  { code: '+370', name: 'Lithuania' },
  { code: '+371', name: 'Latvia' },
  { code: '+372', name: 'Estonia' },
  { code: '+373', name: 'Moldova' },
  { code: '+374', name: 'Armenia' },
  { code: '+375', name: 'Belarus' },
  { code: '+376', name: 'Andorra' },
  { code: '+377', name: 'Monaco' },
  { code: '+378', name: 'San Marino' },
  { code: '+379', name: 'Vatican City' },
  { code: '+380', name: 'Ukraine' },
  { code: '+381', name: 'Serbia' },
  { code: '+382', name: 'Montenegro' },
  { code: '+383', name: 'Kosovo' },
  { code: '+384', name: 'Reserved (Not in use)' },
  { code: '+385', name: 'Croatia' },
  { code: '+386', name: 'Slovenia' },
  { code: '+387', name: 'Bosnia and Herzegovina' },
  { code: '+388', name: 'European Telephony Numbering Space' },
  { code: '+389', name: 'North Macedonia' },
  { code: '+40', name: 'Romania' },
  { code: '+41', name: 'Switzerland' },
  { code: '+420', name: 'Czech Republic' },
  { code: '+421', name: 'Slovakia' },
  { code: '+423', name: 'Liechtenstein' },
  { code: '+971', name: 'United Arab Emirates' },
]

export const availablePermissions = [
  'music',
  'report',
  'marketplace',
  'users',
  'unity',
]
export const playlistReportFilters = [
  'By User Name',
  'By Age',
  'By Country',
  'By Gender',
  'By Device Type',
  'By Provider',
  'By Event Date',
]
export const tracksReportFilters = [
  'By User Name',
  'By Age',
  'By Country',
  'By Gender',
  'By Device Type',
  'By Provider',
  'Skipped',
  'By Event Date',
]
export const stationsReportFilters = [
  'By User Name',
  'By Age',
  'By Country',
  'By Gender',
  'By Device Type',
  'By Provider',
  'Skipped',
  'By Event Date',
]
export const appUsageReportFilters = [
  'By User Name',
  'By Age',
  'By Country',
  'By Gender',
  'By Device Type',
  'By Provider',
  'By Event Date',
]
export const metavereseReportFilters = [
  'By User Name',
  'By Age',
  'By Country',
  'By Gender',
  'By Device Type',
  'By Provider',
  'By Metaverse',
  'By Event Date',
]
export const mazetReportFilters = [
  'By User Name',
  'By Age',
  'By Country',
  'By Gender',
  'By Device Type',
  'By Provider',
  'By Maze',
  'By Event Date',
]
export const gameUsageReportFilters = [
  'By User Name',
  'By Age',
  'By Country',
  'By Gender',
  'By Device Type',
  'By Provider',
  'By Game',
  'By Event Date',
]
export const usearsSearchReportFilters = [
  'By User Name',
  'By Age',
  'By Country',
  'By Gender',
  'By Device Type',
  'By Provider',
  'By Event Date',
]
export const profileChangeReportFilters = [
  'By User Name',
  'By Age',
  'By Country',
  'By Gender',
  'By Device Type',
  'By Provider',
  'By Event Date',
]
export const groupingFilters = [
  'By User Name',

]
