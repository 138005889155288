import { useState, useEffect } from 'react'
import { Image } from 'antd'
import { manualFetch } from 'src/utils/fetch'
import placeholder from 'src/assets/images/placeholder.png'
import { LoadingOutlined } from '@ant-design/icons'

export default function PrivateImage({
  src,
  height,
  width,
  preview = false,
  ...props
}) {
  const [image, setImage] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    if (typeof src !== 'string' || src.includes('data:image')) {
      setImage(src)
      setLoading(false)
      return
    }

    manualFetch(src, {
      headers: {
        accept: 'image/*',
      },
    })
      .then((response) => response.blob())
      .then((imageBlob) => {
        const imageUrl = URL.createObjectURL(imageBlob)
        setImage(imageUrl)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [src])

  return (
    <div
      style={{
        height,
        width,
      }}
    >
      {loading ? (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f6f6f6',
          }}
        >
          <LoadingOutlined />
        </div>
      ) : (
        <Image
          height={height}
          width={width}
          preview={preview}
          src={image}
          fallback={placeholder}
          {...props}
        />
      )}
    </div>
  )
}
