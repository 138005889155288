import { Button, Divider, Modal, Table } from 'antd';
import React, { useCallback } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import usePage from 'src/hooks/usePage';
import { getAll, changeUserStatus, search } from 'src/api/user';
import UserSearch from './search';

const { Column } = Table;


interface User {
  id: string;
  username: string;
  first_name?: string;
  last_name?: string;
  created_on?: string;
  phone_number?: {
    countryCode?: string;
    number?: string;
  };
  email?: string;
  gender?: string;
  date_of_birth?: string;
  points?: number;
  provider_country?: string;
}

const UserPage: React.FC = () => {
  const { pageData, onQuery, appendSearchForm, submitSearchForm, fetchList } =
    usePage({
      getData: getAll,
      searchData: search,
      searchType: 'POST',
    });

  const ChangeUserStatus = useCallback(
    async (id: string, active: boolean) => {
      await changeUserStatus(id, active);
      fetchList();
    },
    [pageData.data]
  );

  const onChangeStatusDisable = (data: User) => {
    Modal.confirm({
      title: (
        <span>
          You&apos;re changing active status of <strong>{data.username}</strong>
        </span>
      ),
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        await changeUserStatus(data.id, false);
        fetchList();
      },
    });
  };

  const onChangeStatusEnable = (data: User) => {
    Modal.confirm({
      title: (
        <span>
          You&apos;re changing active status of <strong>{data.username}</strong>
        </span>
      ),
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        await changeUserStatus(data.id, true);
        fetchList();
      },
    });
  };
  const handleTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const sortField = sorter.field || ''; 
    const sortOrder = sorter.order === 'ascend' ? 'asc' : sorter.order === 'descend' ? 'desc' : ''; 


const updatedSortField = sortOrder ? sortField : undefined;
const updatedSortOrder = sortOrder ? sortOrder : undefined;

    
    onQuery({
      page: current - 1, 
      pageSize,
      sortField: updatedSortField,
    sortOrder: updatedSortOrder,
    });
  };

  return (
    <>
      <Divider />
      <DndProvider backend={HTML5Backend}>
        <Table<User>
          className="table-sorting"
          rowKey="id"
          dataSource={pageData.data}
          loading={pageData.loading}
          onChange={handleTableChange}
          pagination={{
            current: parseInt(pageData.query.page) + 1,
            total: pageData.total,
            pageSize: parseInt(pageData.query.pageSize) || 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '25', '50', '100'],
            onChange: (p, ps) =>
              onQuery({ page: (p - 1).toString(), pageSize: ps.toString() }),
          }}
          title={() => (
            <UserSearch
              appendSearchForm={appendSearchForm}
              submitSearchForm={submitSearchForm}
            />
          )}
          footer={() => (
            <div style={{ textAlign: 'left', padding: '10px' }}>
              Total Users: {pageData.total}
            </div>
          )}
        >
          <Column<User>
            dataIndex="username"
            title="User Name"
            sorter
          />
          <Column<User>
            title="Name"
            render={(text, record) => {
              const firstName = record.first_name;
              const lastName = record.last_name;
              return firstName && lastName ? `${firstName} ${lastName}`.trim() : '';
            }}
          />
          <Column<User>
            title="Joined On"
            dataIndex="createdOn"
            sorter
            render={(text, record) => {
              const dateString = record.created_on;
              if (dateString) {
                const date = new Date(dateString);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                return `${year}-${month}-${day}`;
              }
              return '';
            }}
          />
          <Column<User>
            title="Phone Number"
            render={(text, record) => {
              const phoneNumber = record.phone_number;
              return phoneNumber && phoneNumber.countryCode && phoneNumber.number
                ? `${phoneNumber.countryCode} ${phoneNumber.number}`
                : '';
            }}
          />
          <Column<User> dataIndex="email" title="Email" />
          <Column<User> dataIndex="gender" title="Gender" />
          <Column<User> dataIndex="date_of_birth" title="Date Of Birth" />
          <Column<User>
            dataIndex="points"
            title="Points"
            sorter
          />
          <Column<User> dataIndex="provider_country" title="Country Provider" />
        </Table>
      </DndProvider>
    </>
  );
};

UserPage.displayName = 'UserPage';
export default UserPage;
