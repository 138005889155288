import { Select, Space, Tag } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import React, { useRef, useState } from 'react'
import {
  fetchPlaylistByQuery,
  addTrackToPlaylist,
  removeTrackFromPlaylist,
} from 'src/api/track'
import debounce from 'src/utils/debounce'
import useForceRender from 'src/utils/ForceRender'

const { Option } = Select

function PlaylistColumn({ playlists = [], id }) {
  const [showInput, setShowInput] = useState(false)
  const addedPlaylists = useRef<Playlist[]>(playlists)
  const forceRender = useForceRender()

  const loading = React.useRef<boolean>(false)
  const suggestionList = React.useRef([])

  const handleSearch = React.useCallback(async (value) => {
    if (!value) return
    loading.current = true
    forceRender()
    const data = await fetchPlaylistByQuery(value)
    suggestionList.current = data
    loading.current = false
    forceRender()
  }, [])

  const handleSelect = React.useCallback(async (option) => {
    await addTrackToPlaylist({
      trackId: id,
      playlistId: option.value,
    })
    const item = suggestionList.current.find((item) => item.id === option.value)
    addedPlaylists.current.push({ name: item.name, id: item.id })
    setShowInput(false)
  }, [])

  const _removeTrackFromPlaylist = async (val) => {
    await removeTrackFromPlaylist({
      trackId: id,
      playlistId: val,
    })
    addedPlaylists.current = addedPlaylists.current.filter((p) => p.id !== val)
    forceRender()
  }

  const filteredSuggestionList = suggestionList.current.filter((item) => {
    return !addedPlaylists.current.find((i) => i.id === item.id)
  })

  return (
    <Space size={[4, 4]} wrap>
      {addedPlaylists.current.map((item) => (
        <Tag
          key={item.id}
          style={{ margin: 0 }}
          closable
          onClose={(e) => {
            e.preventDefault()
            _removeTrackFromPlaylist(item.id)
          }}
        >
          {item.name}
        </Tag>
      ))}

      {showInput ? (
        <Select
          showSearch
          autoFocus
          autoClearSearchValue={false}
          defaultActiveFirstOption={false}
          filterOption={false}
          notFoundContent={null}
          onSearch={debounce(handleSearch, 500)}
          onSelect={handleSelect}
          onBlur={() => setShowInput(false)}
          style={{ width: 120 }}
          loading={loading.current}
          size="small"
          labelInValue={true}
        >
          {filteredSuggestionList.map((item) => (
            <Option key={item.id}>{item.name}</Option>
          ))}
        </Select>
      ) : (
        <Tag
          onClick={() => setShowInput(true)}
          className="site-tag-plus"
          color="green"
        >
          <PlusOutlined /> Add to playlist
        </Tag>
      )}
    </Space>
  )
}

export default PlaylistColumn
