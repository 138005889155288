import { Button, Input, Select } from 'antd'
import React, { useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { search } from 'src/api/tag'
import debounce from 'src/utils/debounce'
import { fetchPlaylistByQuery, searchArtist } from 'src/api/track'

interface TrackSearchProps {
  appendSearchForm: (obj) => void
  submitSearchForm: (obj?: any) => void
}

const TrackSearch: React.FC<TrackSearchProps> = ({
  appendSearchForm,
  submitSearchForm,
}) => {
  const [tags, setTags] = useState([])
  const [playlists, setPlaylists] = useState([])
  const [artists, setArtists] = useState([])
  const [loadingTag, setLoadingTag] = useState(false)
  const [loadingArtist, setLoadingArtist] = useState(false)
  const [loadingPlaylist, setLoadingPlaylist] = useState(false)

  const handleSearchTag = React.useCallback(async (value) => {
    if (!value) return
    setLoadingTag(true)
    const data = await search({ name: value, size: 50 })
    setTags(data.data)
    setLoadingTag(false)
  }, [])

  const handleSelectTag = React.useCallback((opts: any[]) => {
    const tags = opts?.map((opt) => opt.value).toString()
    appendSearchForm({ tags })
  }, [])

  const handleSearchPlaylist = React.useCallback(async (value) => {
    if (!value) return
    setLoadingPlaylist(true)
    const data = await fetchPlaylistByQuery(value)
    setPlaylists(data)
    setLoadingPlaylist(false)
  }, [])

  const handleSelectPlaylist = React.useCallback((opts: any[]) => {
    const playlists = opts?.map((opt) => opt.value).toString()
    appendSearchForm({ playlists })
  }, [])

  const handleSearchArtist = React.useCallback(async (value) => {
    if (!value) return
    setLoadingArtist(true)
    const data = await searchArtist({ name: value })
    setArtists(data)
    setLoadingArtist(false)
  }, [])

  const handleSelectArtist = React.useCallback((opts: any[]) => {
    const artists = opts?.map((opt) => opt.value).toString()
    appendSearchForm({ artists })
  }, [])

  return (
    <div>
      <Input
        placeholder="Name"
        allowClear
        onChange={(e) => appendSearchForm({ name: e.target.value })}
        className="search-input"
      />

      <Select
        showSearch
        allowClear
        mode="multiple"
        defaultActiveFirstOption={false}
        filterOption={false}
        onSearch={debounce(handleSearchArtist, 500)}
        onChange={handleSelectArtist}
        size="middle"
        placeholder="Select artist"
        labelInValue={true}
        className="search-input"
        loading={loadingArtist}
      >
        {artists.map((item) => (
          <Select.Option key={item.id}>{item.name}</Select.Option>
        ))}
      </Select>

      <Select
        showSearch
        allowClear
        mode="multiple"
        defaultActiveFirstOption={false}
        filterOption={false}
        onSearch={debounce(handleSearchTag, 500)}
        onChange={handleSelectTag}
        size="middle"
        placeholder="Select tag"
        labelInValue={true}
        className="search-input"
        loading={loadingTag}
      >
        {tags.map((item) => (
          <Select.Option key={item.id}>{item.name}</Select.Option>
        ))}
      </Select>

      <Select
        showSearch
        allowClear
        mode="multiple"
        defaultActiveFirstOption={false}
        filterOption={false}
        onSearch={debounce(handleSearchPlaylist, 500)}
        onChange={handleSelectPlaylist}
        size="middle"
        placeholder="Select playlist"
        labelInValue={true}
        className="search-input"
        loading={loadingPlaylist}
      >
        {playlists.map((item) => (
          <Select.Option key={item.id}>{item.name}</Select.Option>
        ))}
      </Select>

      <Button
        type="primary"
        icon={<SearchOutlined />}
        onClick={() => submitSearchForm()}
        className="search-btn"
      >
        Search
      </Button>
    </div>
  )
}

TrackSearch.displayName = 'TrackSearch'
export default TrackSearch
