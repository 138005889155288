import {
  Button,
  Input,
  Select,
  Divider,
  Dropdown,
  Menu,
  DatePicker,
} from 'antd'
import React, { useEffect, useState } from 'react'
import {
  SearchOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
} from '@ant-design/icons'
import {
  AGE_SEARCH_TYPE,
  SEARCH_QUERY_TYPE,
  COUNTRY_TYPE,
  GENDER_TYPE,
  PHONE_MODEL,
  PROVIDER_COUNTRY,
  usearsSearchReportFilters,
  GROUP_QUERY_TYPE,
  groupingFilters,
} from 'src/constants'
import { searchUserQueriesReport } from 'src/api/reports'

interface UserQueriesSearchProps {
  appendSearchForm: (obj: any) => void
  submitSearchForm: () => void
}

const UserQueriesSearch: React.FC<UserQueriesSearchProps> = ({
  appendSearchForm,
  submitSearchForm,
}) => {
  const [loading, setLoading] = useState(false)
  const [searchType, setSearchType] = useState('Between')
  const [minAge, setMinAge] = useState('')
  const [maxAge, setMaxAge] = useState('')
  const [country, setCountry] = useState('')
  const [searchQueryType, setSearchQueryType] = useState([])
  const [gender, setGender] = useState('Male')
  const [deviceType, setdeviceType] = useState('')
  const [clientName, setClientName] = useState('')
  const [buttonAction, setButtonAction] = useState('Select')
  const [providerCountry, setProviderCountry] = useState('')
  const [userName, setUserName] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const handlePdfClick = () => {
    appendSearchForm({ buttonAction: 'Generate Pdf' })
    submitSearchForm()
  }

  const handleCsvClick = () => {
    appendSearchForm({ buttonAction: 'Generate Csv' })
    submitSearchForm()
  }
  const handleSearchClick = () => {
    appendSearchForm({ buttonAction: ' ' })
    submitSearchForm()
    setButtonAction(null)
  }

  const handleSearchTypeChange = (value: string) => {
    setSearchType(value)
    setMinAge('')
    setMaxAge('')
    setProviderCountry('')
    setUserName('')
    setStartDate('')
    setEndDate('')
  }
  const handleSearchQueryTypeChange = React.useCallback((opts: any[]) => {
    setSearchQueryType(opts)
    appendSearchForm({ searchQueryType })
  }, [])

  const filterCountryOption = (inputValue, option) => {
    return option.children.toLowerCase().includes(inputValue.toLowerCase())
  }
  const handleSearchQueryTypeDeselect = React.useCallback(
    (deselectedOption: any) => {
      const updatedOptions = searchQueryType.filter(
        (option) => option !== deselectedOption
      )

      switch (deselectedOption) {
        case 'By Age':
          appendSearchForm({ maxAge: '', minAge: '' })
          break
        case 'By Country':
          appendSearchForm({ country: '' })
          break
        case 'By Gender':
          appendSearchForm({ gender: '' })
          break
        case 'By Device Type':
          appendSearchForm({ deviceType: '' })
          break
        case 'By Provider':
          appendSearchForm({ providerCountry: '' })
          break
        case 'By User Name':
          appendSearchForm({ userName: '' })
          break
        case 'By Event Date':
          appendSearchForm({ startDate: '', endDate: '' })
          break
        default:
          break
      }

      appendSearchForm(updatedOptions)
      submitSearchForm()
    },
    [searchQueryType]
  )

  const handleSearchQueryTypeClear = React.useCallback(() => {
    appendSearchForm({
      searchQueryType: '',
      country: '',
      deviceType: '',
      gender: '',
      maxAge: '',
      minAge: '',
      buttonAction: '',
      providerCountry: '',
      userName: '',
      startDate: '',
      endDate: '',
    })

    submitSearchForm()
  }, [])
  const handleSearchTypeClear = React.useCallback(() => {
    appendSearchForm({
      maxAge: '',
      minAge: '',
      searchType: '',
      providerCountry: '',
      userName: '',
      startDate: '',
      endDate: '',
    })

    submitSearchForm()
  }, [])
  useEffect(() => {
    appendSearchForm({ maxAge: '' })
    appendSearchForm({ minAge: '' })
    appendSearchForm({ searchType })
    appendSearchForm({ providerCountry: '' })
    appendSearchForm({ userName: '' })
    appendSearchForm({ startDate: '' })
    appendSearchForm({ endDate: '' })
  }, [searchType])
  const handleGroupByClear = React.useCallback(() => {
    appendSearchForm({
     groupBy:''
    })

    submitSearchForm()
  }, [])
  return (
    <>
      <Select
        placeholder="Select Search Criteria"
        value={searchQueryType}
        mode="multiple"
        allowClear
        onChange={handleSearchQueryTypeChange}
        onClear={handleSearchQueryTypeClear}
        onDeselect={handleSearchQueryTypeDeselect}
        className="search-input"
      >
        {SEARCH_QUERY_TYPE.filter((option) =>
          usearsSearchReportFilters.includes(option)
        ).map((val) => (
          <Select.Option value={val} key={val}>
            {val}
          </Select.Option>
        ))}
      </Select>
      <Select
  placeholder="Select Grouping Criteria"
  allowClear
  onChange={(value) => appendSearchForm({ groupBy: value })}
  onClear={handleGroupByClear}
  className="search-input"
>
  {GROUP_QUERY_TYPE.filter((option) =>
    groupingFilters.includes(option.label)
  ).map((option) => (
    <Select.Option value={option.value} key={option.value}>
      {option.label}
    </Select.Option>
  ))}
</Select>
      <Divider />
      {searchQueryType.includes('By User Name') && (
        <>
          <Input
            type="text"
            placeholder="User Name"
            allowClear
            onChange={(e) => appendSearchForm({ userName: e.target.value })}
            className="search-input"
          />
        </>
      )}
      {searchQueryType.includes('By Age') && (
        <Select
          placeholder="Select Range"
          value={searchType}
          allowClear
          onChange={handleSearchTypeChange}
          onClear={handleSearchTypeClear}
          className="search-input"
        >
          {AGE_SEARCH_TYPE.map((val) => (
            <Select.Option value={val} key={val}>
              {val}
            </Select.Option>
          ))}
        </Select>
      )}
      {searchQueryType.includes('By Age') && searchType === 'Between' && (
        <>
          <Input
            type="number"
            placeholder="Minimum Age"
            allowClear
            onChange={(e) => appendSearchForm({ minAge: e.target.value })}
            className="search-input"
          />

          <Input
            type="number"
            placeholder="Maxiumum Age"
            allowClear
            onChange={(e) => appendSearchForm({ maxAge: e.target.value })}
            className="search-input"
          />
        </>
      )}
      {searchQueryType.includes('By Age') && searchType === 'Greater Than' && (
        <Input
          type="number"
          placeholder="Age Greater Than"
          onChange={(e) => appendSearchForm({ minAge: e.target.value })}
          className="search-input"
        />
      )}
      {searchQueryType.includes('By Age') && searchType === 'Less Than' && (
        <Input
          type="number"
          placeholder="Age Less Than"
          onChange={(e) => appendSearchForm({ maxAge: e.target.value })}
          className="search-input"
        />
      )}
      {searchQueryType.includes('By Country') && (
        <Select
          placeholder="Select Country"
          onChange={(value) => appendSearchForm({ country: value })}
          onClear={() => appendSearchForm({ country: '' })}
          filterOption={filterCountryOption}
          allowClear
          className="search-input"
          showSearch
        >
          {COUNTRY_TYPE.map((val) => (
            <Select.Option value={val} key={val}>
              {val}
            </Select.Option>
          ))}
        </Select>
      )}
      {searchQueryType.includes('By Gender') && (
        <Select
          placeholder="Select Gender"
          onChange={(value) => appendSearchForm({ gender: value })}
          allowClear
          onClear={() => appendSearchForm({ gender: '' })}
          className="search-input"
        >
          {GENDER_TYPE.map((val) => (
            <Select.Option value={val} key={val}>
              {val}
            </Select.Option>
          ))}
        </Select>
      )}
      {searchQueryType.includes('By Device Type') && (
        <Select
          placeholder="Select Device Type"
          onChange={(value) => appendSearchForm({ deviceType: value })}
          allowClear
          onClear={() => appendSearchForm({ deviceType: '' })}
          className="search-input"
        >
          {PHONE_MODEL.map((val) => (
            <Select.Option value={val} key={val}>
              {val}
            </Select.Option>
          ))}
        </Select>
      )}
      {searchQueryType.includes('By Provider') && (
        <Select
          placeholder="Select Provider"
          onChange={(value) => appendSearchForm({ providerCountry: value })}
          allowClear
          onClear={() => appendSearchForm({ providerCountry: '' })}
          className="search-input"
        >
          {PROVIDER_COUNTRY.map((val) => (
            <Select.Option value={val} key={val}>
              {val}
            </Select.Option>
          ))}
        </Select>
      )}
      {searchQueryType.includes('By Event Date') && (
        <>
          <DatePicker.RangePicker
            bordered={false}
            format="YYYY-MM-DD"
            placeholder={['Start Date', 'End Date']}
            onChange={(dates) => {
              appendSearchForm({
                startDate: dates[0] ? dates[0].format('YYYY-MM-DD') : null,
                endDate: dates[1] ? dates[1].format('YYYY-MM-DD') : null,
              })
            }}
          />
        </>
      )}
      <Button
        type="primary"
        icon={<SearchOutlined />}
        onClick={handleSearchClick}
        className="search-btn"
      >
        Search
      </Button>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '10px',
        }}
      >
        <FilePdfOutlined
          title="export to pdf"
          style={{ fontSize: '24px', cursor: 'pointer', marginLeft: '10px' }}
          onClick={handlePdfClick}
        />
        <FileExcelOutlined
          title="export to csv"
          style={{ fontSize: '24px', cursor: 'pointer', marginLeft: '10px' }}
          onClick={handleCsvClick}
        />
      </div>
    </>
  )
}

UserQueriesSearch.displayName = 'UserQueriesSearch'
export default UserQueriesSearch
