import { message } from 'antd'
import { pick } from 'ramda'
import { fetchUtil as fetch, buildQuery } from 'src/utils/fetch'
import { getImagePath } from 'src/utils/url'

export const getAll = async (params) => {
  try {
    const query = buildQuery(params)
    const { data } = await fetch(`/station/searchStation?${query}`)
    return data
  } catch (e) {
    message.error('getAll station error')
  }
}

export const order = async (stationId, newIndex) => {
  try {
    const body = {
      order_id: newIndex,
    }
    return fetch(`/station/${stationId}/order`, {
      method: 'PATCH',
      body: JSON.stringify(body),
    })
  } catch (e) {
    message.error('order station error')
  }
}

export const update = async (data) => {
  try {
    return fetch(`/station`, {
      method: 'PUT',
      body: JSON.stringify(data),
    })
  } catch (error) {
    message.error('update station error')
  }
}

export const create = async (data) => {
  try {
    return await fetch(`/station`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
  } catch (e) {
    message.error('create station error')
  }
}

export const getById = async (id) => {
  try {
    const { data } = await fetch(`/station/getStationById/${id}`)
    return data
  } catch (e) {
    message.error('getById station error')
  }
}

export const updateOrCreate = async (
  id: string,
  payload: Record<string, any>
) => {
  try {
    let res = null
    if (id && id !== 'new') {
      let coverUrl = getImagePath(payload.coverUrl)
      if (payload.image?.file) {
        const imageRes = await uploadImage(payload.image?.file)
        coverUrl = imageRes.name
      }
      res = await update({
        coverUrl,
        ...pick(
          ['name', 'type', 'description', 'tags', 'id', 'order_id'],
          payload
        ),
      })
    } else {
      let coverUrl = ''
      if (payload.image?.file) {
        const imageRes = await uploadImage(payload.image?.file)
        coverUrl = imageRes.name
      }
      res = await create({
        coverUrl,
        ...pick(['name', 'type', 'description', 'tags'], payload),
      })
    }
    return res
  } catch (e) {
    message.error('updateOrCreate station error !')
    throw e
  }
}

export const uploadImage = async (file: any) => {
  try {
    const body = new FormData()
    body.append('coverImage', file, file.name)
    const { data } = await fetch(`/station/uploadCoverImage`, {
      method: 'POST',
      body,
    })
    return data
  } catch (e) {
    message.error('uploadImage station error')
  }
}
