import { Table, Tag } from 'antd'
import moment from 'moment'
import PlaylistColumn from './PlaylistColumn'
import TagColumn from './TagColumn'
import PrivateAudioPlayer from 'src/components/PrivateAudioPlayer'
import PrivateImage from 'src/components/PrivateImage'
import usePage from 'src/hooks/usePage'
import { getAll, search } from 'src/api/track'
import TrackSearch from './search'

const { Column } = Table

const TrackPage: React.FC = () => {
  const { pageData, onQuery, appendSearchForm, submitSearchForm } = usePage({
    getData: getAll,
    searchData: search,
    searchType: 'POST',
  })

  return (
    <>
      <Table
        rowKey="id"
        dataSource={pageData.data}
        loading={pageData.loading}
        pagination={{
          current: parseInt(pageData.query.page) + 1,
          total: pageData.total,
          onChange: (p) => onQuery({ page: (p - 1).toString() }),
        }}
        title={() => (
          <TrackSearch
            appendSearchForm={appendSearchForm}
            submitSearchForm={submitSearchForm}
          />
        )}
      >
        <Column
          width={120}
          dataIndex="image_s3_path"
          title="Track Image"
          render={(img) => (
            <PrivateImage width={90} height={90} src={img} preview={false} />
          )}
        />

        <Column
          dataIndex="artists"
          title="Artists"
          render={(items) => {
            return items.map((i) => (
              <Tag key={i.id} color="blue">
                {i.name}
              </Tag>
            ))
          }}
        />

        <Column dataIndex="title" title="Track Name" />

        <Column
          width={500}
          dataIndex="playlists"
          title="Playlist"
          render={(data, row: any) => (
            <PlaylistColumn id={row.id} playlists={row.playlists} />
          )}
        />

        <Column
          width={500}
          dataIndex="tags"
          title="Tags"
          render={(data, row: any) => <TagColumn id={row.id} tags={row.tags} />}
        />

        <Column
          dataIndex="modified_on"
          title="Last date changed"
          render={(data) => moment(data).format('DD/MM/YYYY hh:mm:ss')}
        />

        <Column
          dataIndex="s3_path"
          title=""
          className="sound-player-column"
          render={(data) => (
            <PrivateAudioPlayer src={data} controls preload="none" />
          )}
        />
      </Table>
    </>
  )
}

TrackPage.displayName = 'TrackPage'
export default TrackPage
