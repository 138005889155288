import React from 'react'
import { Button, message, Modal } from 'antd'
import s from './index.module.scss'
import Uploader from 'src/components/Uploader'
import { updateOrCreate } from 'src/api/image'
import useDetail from 'src/hooks/useDetail'

interface AddingModalProps {
  type: ImageType
  image: Image
  visible: boolean
  onDone: () => void
  onCancel: () => void
}

const AddingModal: React.FC<AddingModalProps> = ({
  type,
  image,
  visible,
  onDone,
  onCancel,
}) => {
  const {
    onFinish,
    onSelectImage,
    onDeleteImage,
    appendForm,
    resetForm,
    loading,
    form,
  } = useDetail({ data: image, updateOrCreate, backOnFinish: false })

  const handleOk = React.useCallback(async () => {
    if (!form.image) {
      message.warning('No image selected !')
      return
    }
    appendForm({ type })
    await onFinish()
    resetForm()
    onDone && onDone()
  }, [image, form])

  const handleCancel = React.useCallback(() => {
    resetForm()
    onCancel && onCancel()
  }, [])

  return (
    <>
      <Modal
        title={(image.id ? 'Editing' : 'Adding') + ' image'}
        visible={visible}
        onCancel={handleCancel}
        footer={[
          <Button type="primary" key="add" onClick={handleOk} loading={loading}>
            Ok
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <div className={s.modalForm}>
          <div style={{ maxWidth: 250 }}>
            <Uploader
              key={image.id || '_uploader'}
              onChange={onSelectImage}
              onRemove={onDeleteImage}
              defaultImageUrl={image.url || form.image?.file?.name}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

AddingModal.displayName = 'AddingModal'
export default AddingModal
