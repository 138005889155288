import { fetchUtil as fetch } from 'src/utils/fetch'
import { message } from 'antd'

export const getAll = async () => {
  try {
    const res = await fetch(`/playlist/categories`)
    if (Array.isArray(res?.data)) {
      return res.data
    }
    throw 'Get all categories response wrong format'
  } catch (e) {
    message.error('getAll categories error ')
  }
}

export const updatePlaylists = async (
  categoryId: string,
  playlistIds: string[]
) => {
  try {
    await fetch(`/playlist/category/${categoryId}`, {
      method: 'PATCH',
      body: JSON.stringify({ playlistIds }),
    })
  } catch (e) {
    message.error('updatePlaylists category error ')
  }
}
