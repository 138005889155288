import { useState } from 'react'
import { Button, Divider, Input, Typography } from 'antd'
import { saveEditMetaverse } from 'src/api/metaverse'
import { useNavigate } from 'react-router-dom'
const { Title } = Typography
import { useParams } from 'react-router-dom'
import './EditMetaverse.css'
import { message } from 'antd'

const EditMetaverse = () => {
  const { id, img, thumbImg, title, titleFr, desc } = useParams()
  const [editedTitle, setEditedTitle] = useState(title)
  const [editedDesc, setEditedDesc] = useState(desc)
  const [editedTitleFr, setEditedTitleFr] = useState(titleFr)
  const [editedImg, setEditedImg] = useState(img)
  const [editedThumbImg, setEditedThumbImg] = useState(thumbImg)
  const [newImage, setNewImage] = useState(null)
  const [newThumbImage, setNewThumbImage] = useState(null)
  const navigate = useNavigate()

  const handleImageUpload = (event) => {
    const file = event.target.files?.[0]
    if (file) {
      const imageUrl = URL.createObjectURL(file)
      setNewImage(file)
      setEditedImg(imageUrl)
    }
  }

  const handleSave = () => {
    if(editedTitle!=='' && editedTitleFr !=='' && editedDesc!=='' && newImage !=='' && newThumbImage !==''){
    saveEditMetaverse(
      id,
      editedTitle,
      editedTitleFr,
      editedDesc,
      newImage,
      newThumbImage
    ).then((response) => {
      navigate('/metaverse')
    })
  }
  else{
    message.error('All fields are required!')
  }
  }
  const onCancel = () => {
    navigate('/metaverse')
  }

  const handleThumbImageUpload = (event) => {
    const file = event.target.files?.[0]
    if (file) {
      const imageUrl = URL.createObjectURL(file)
      setNewThumbImage(file)
      setEditedThumbImg(imageUrl)
    }
  }

  return (
    <div className="edit-metaverse-container">
      <Title level={3}>Edit Metaverse</Title>
      <Divider />
      <div>
        <div className="inline-form-item">
          <label>Title:</label>
          <Input
            placeholder="Title"
            value={editedTitle}
            onChange={(e) => setEditedTitle(e.target.value)}
          />
        </div>
        <div className="inline-form-item">
          <label>Title French:</label>
          <Input
            placeholder="Title French"
            value={editedTitleFr}
            onChange={(e) => setEditedTitleFr(e.target.value)}
          />
        </div>
        <div className="inline-form-item">
          <label>Description:</label>
          <Input.TextArea
            placeholder="Description"
            value={editedDesc}
            onChange={(e) => setEditedDesc(e.target.value)}
          />
        </div>
        <div className="button-container">
          <Button
            onClick={() => document.getElementById('upload-image')?.click()}
            className="edit-img-button"
          >
            Edit Image
          </Button>
          <input
            id="upload-image"
            type="file"
            hidden
            accept="image/*"
            onChange={handleImageUpload}
          />
          <Button
            onClick={() => document.getElementById('upload-thumbnail')?.click()}
            className="edit-thumb-button"
          >
            Edit Thumbnail
          </Button>
          <input
            id="upload-thumbnail"
            type="file"
            hidden
            accept="image/*"
            onChange={handleThumbImageUpload}
          />
        </div>
        <div className="image-preview">
          <img src={editedImg} alt="Edited Image" />
          <img
            src={editedThumbImg}
            alt="Edited Thumbnail"
            className="thumb-img"
          />
        </div>
        <div className="button-container" style={{ marginTop: 16 }}>
          <Button className="save-button" onClick={handleSave}>
            Save
          </Button>
          <Button className="edit-thumb-button" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}

export default EditMetaverse
