import { Button, Typography, Space, Row, Col, Card } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import {
  SmileOutlined,
  PlayCircleOutlined,
  LogoutOutlined,
} from '@ant-design/icons'
import AppContext from 'src/hooks/AppContext'
import { destroyCookie } from 'nookies'

const { Title, Text } = Typography

const Welcome = () => {
  const { userInfo } = useContext(AppContext)
  const navigate = useNavigate()

  const handleExplore = () => {
    navigate('/discovery')
  }

  const deleteToken = () => {
    sessionStorage.removeItem('token')
    destroyCookie({} as any, 'token', { path: '/', secure: true })
  }

  const handleLogout = () => {
    deleteToken()
    navigate('/login')
  }

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f0f2f5',
      }}
    >
      <Card style={{ maxWidth: '600px', textAlign: 'center', padding: '40px' }}>
        <Space direction="vertical" size="large">
          <Row justify="center">
            <Col>
              <SmileOutlined style={{ fontSize: '48px', color: '#1890ff' }} />
            </Col>
          </Row>
          <Title level={2}>Welcome {userInfo?.username || 'User'}!</Title>
          <Text type="secondary">
            We're excited to have you here. Explore and enjoy your experience
            with us.
          </Text>
          <Space direction="vertical" size="middle">
            <Button
              size="large"
              danger
              icon={<LogoutOutlined />}
              onClick={handleLogout}
            >
              Logout
            </Button>
          </Space>
        </Space>
      </Card>
    </div>
  )
}

export default Welcome
