import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Typography,
  Select,
} from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Uploader from 'src/components/Uploader'
import { TAG_TYPES } from 'src/constants/tag'
import { updateOrCreate, uploadImage, getById } from 'src/api/tag'
import useDetail from 'src/hooks/useDetail'

const { Title, Text } = Typography

const TagDetail: React.FC = () => {
  const { onFinish, onSelectImage, onDeleteImage, appendForm, loading, id, form } =
    useDetail({ getById, updateOrCreate, uploadImage })
  const navigate = useNavigate()

  const normalizeFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  return (
    <>
      <Title level={5}>
        <Text>You're {id === 'new' ? 'creating' : 'editing'} tag</Text>
        <Spin spinning={loading} style={{ marginLeft: 20 }} />
      </Title>
      <Divider />
      {!loading && (
        <Form layout="vertical" onFinish={onFinish}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Cover Image" getValueFromEvent={normalizeFile}>
                <Uploader
                  onChange={onSelectImage}
                  onRemove={onDeleteImage}
                  defaultImageUrl={form.image_s3_path}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Name">
                <Input
                  defaultValue={form.name}
                  onChange={(e) => appendForm({ name: e.target.value })}
                  placeholder="Tag name"
                />
              </Form.Item>
              <Form.Item label="Type">
                <Select
                  defaultValue={form.type}
                  onChange={(value) => appendForm({ type: value })}
                >
                  {TAG_TYPES.map((value) => (
                    <Select.Option value={value} key={value}>
                      {value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Form.Item>
            <Space align="end">
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={loading}
              >
                {id === 'new' ? 'Create' : 'Update'}
              </Button>
              <Button danger onClick={() => navigate(-1)}>
                Cancel
              </Button>
            </Space>
          </Form.Item>
        </Form>
      )}
    </>
  )
}

TagDetail.displayName = 'TagDetail'
export default TagDetail
