import React from 'react'
import ReactDOM from 'react-dom'
import App from 'src/pages/App'
import 'antd/dist/antd.css'
import 'src/assets/scss/main.scss'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
