import { Button, Divider, Input, Table, Tag } from 'antd'
import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import PrivateImage from 'src/components/PrivateImage'
import DragTableRow from 'src/components/DragTableRow'
import StreamAudioPlayer from 'src/components/StreamAudioPlayer'
import TagColumn from './TagColumn'
import { getAll, order } from 'src/api/station'
import usePage from 'src/hooks/usePage'

const { Column } = Table
const { Search } = Input

const StationPage: React.FC = () => {
  const { pageData, onQuery, queryObj, fetchList } = usePage({
    getData: getAll,
  })

  const moveRow = useCallback(
    async (dragIndex, dropIndex) => {
      const playlistId = pageData.data[dragIndex].id
      const newOrderId = pageData.data[dropIndex]?.order_id || dropIndex
      await order(playlistId, newOrderId)
      fetchList()
    },
    [pageData.data]
  )
  const components = {
    body: {
      row: DragTableRow,
    },
  }

  return (
    <>
      <Button type="primary">
        <Link to="/station/new">Create</Link>
      </Button>
      <Divider />
      <DndProvider backend={HTML5Backend}>
        <Table
          className="table-sorting"
          rowKey="id"
          dataSource={pageData.data}
          loading={pageData.loading}
          pagination={{
            current: parseInt(pageData.query.page) + 1,
            total: pageData.total,
            onChange: (p) => onQuery({ page: (p - 1).toString() }),
          }}
          title={() => (
            <Search
              placeholder="Search by name"
              onSearch={(v) => onQuery({ page: '0', text: v })}
              allowClear
              style={{ width: 250, marginRight: 24 }}
              defaultValue={queryObj['text']}
            />
          )}
          components={components}
          onRow={(_, index) =>
            ({
              index,
              moveRow,
            } as any)
          }
        >
          <Column
            width={120}
            dataIndex="coverUrl"
            title="Cover Image"
            render={(img) => (
              <PrivateImage width={90} height={90} src={img} preview={false} />
            )}
          />

          <Column
            dataIndex="name"
            title="Name"
            render={(data, row: any) => (
              <Link to={`/station/${row.id}`}>{data}</Link>
            )}
          />

          <Column width={500} dataIndex="description" title="Description" />

          <Column
            width={500}
            dataIndex="tags"
            title="Tags"
            render={(_, row: any) => <TagColumn id={row.id} tags={row.tags} />}
          />

          <Column
            dataIndex="modified_on"
            title="Last date changed"
            render={(data) => moment(data).format('DD/MM/YYYY hh:mm:ss')}
          />

          <Column
            dataIndex="type"
            title="Type"
            render={(data) => {
              if (data) {
                return (
                  <Tag color={data === 'private' ? 'blue' : 'green'}>
                    {data?.toUpperCase()}
                  </Tag>
                )
              }
              return null
            }}
          />
          <Column
            dataIndex="icecast_url"
            title=""
            className="sound-player-column"
            render={(data) => <StreamAudioPlayer src={data} />}
          />
        </Table>
      </DndProvider>
    </>
  )
}

StationPage.displayName = 'StationPage'
export default StationPage
