import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
  Select,
} from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Uploader from 'src/components/Uploader'
import AssignTag from 'src/components/AssignTag'
import useDetail from 'src/hooks/useDetail'
import { getById, updateOrCreate } from 'src/api/station'
import { CONTENT_PRIVACY } from 'src/constants'

const { Title, Text } = Typography

const StationDetail: React.FC = () => {
  const {
    onFinish,
    onSelectImage,
    onDeleteImage,
    appendForm,
    loading,
    id,
    form,
  } = useDetail({ getById, updateOrCreate })
  const navigate = useNavigate()

  const normalizeFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }

    return e && e.fileList
  }

  const handleAddTag = React.useCallback(
    (tag) => {
      const tags = [...(form.tags || [])]
      tags.push(tag)
      appendForm({ tags }, true)
    },
    [form]
  )

  const handleRemoveTag = React.useCallback(
    (index) => {
      const tags = [...(form.tags || [])]
      tags.splice(index, 1)
      appendForm({ tags }, true)
    },
    [form]
  )

  return (
    <>
      <Title level={5}>
        <Text>You're {id === 'new' ? 'creating' : 'editing'} station</Text>
        <Spin spinning={loading} style={{ marginLeft: 20 }} />
      </Title>
      <Divider />
      {!loading && (
        <Form layout="vertical" onFinish={onFinish}>
          <Row gutter={16}>
            <Col span={4}>
              <Form.Item label="Cover Image" getValueFromEvent={normalizeFile}>
                <Uploader
                  onChange={onSelectImage}
                  onRemove={onDeleteImage}
                  defaultImageUrl={form.coverUrl}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Name">
                <Input
                  defaultValue={form.name}
                  onChange={(e) => appendForm({ name: e.target.value })}
                  placeholder="Station name"
                />
              </Form.Item>

              <Form.Item label="Type">
                <Select
                  defaultValue={form.type}
                  onChange={(value) => appendForm({ type: value })}
                  placeholder="Select type"
                >
                  {CONTENT_PRIVACY.map((val) => (
                    <Select.Option value={val.toLowerCase()} key={val}>
                      {val}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label="Assigned Tags">
                {form.tags?.length > 0 && (
                  <Space size={[4, 4]} wrap>
                    {form.tags.map((tag, index) => (
                      <Tag
                        key={tag.id}
                        closable
                        onClose={(e) => {
                          e.preventDefault()
                          handleRemoveTag(index)
                        }}
                        style={{ margin: 0 }}
                        color={
                          tag.type === 'MOOD'
                            ? 'blue'
                            : tag.type === 'ACTIVITY'
                            ? 'orange'
                            : 'green'
                        }
                      >
                        {tag.name}
                      </Tag>
                    ))}
                  </Space>
                )}
              </Form.Item>

              <Form.Item label="Description">
                <Input.TextArea
                  defaultValue={form.description}
                  onChange={(e) => appendForm({ description: e.target.value })}
                  placeholder="Station Description"
                  rows={4}
                  maxLength={500}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Available Tags">
                <AssignTag onAddTag={handleAddTag} selectedTags={form.tags} />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Form.Item>
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={loading}
              >
                {id === 'new' ? 'Create' : 'Update'}
              </Button>
              <Button danger onClick={() => navigate(-1)}>
                Cancel
              </Button>
            </Space>
          </Form.Item>
        </Form>
      )}
    </>
  )
}
StationDetail.displayName = 'StationDetail'
export default StationDetail
