import { useState, useEffect, useCallback } from 'react'
import MazeCard from './mazecards'
import { manualFetch } from 'src/utils/fetch'
import { Spin, Pagination } from 'antd'
import './Sectionmaze.css'
import { getMazes } from 'src/api/maze'

const SectionMaze = ({ searchTerm }) => {
  const [mazes, setMazes] = useState([])
  const [dataLoaded, setDataLoaded] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalMazes, setTotalMazes] = useState(0)
  const pageSize = 10

  const fetchImage = useCallback(async (url) => {
    const response = await manualFetch(url, {
      headers: {
        accept: 'image/*',
      },
    })
    const imageBlob = await response.blob()
    return URL.createObjectURL(imageBlob)
  }, [])

  const fetchData = useCallback(
    async (page, term) => {
      setDataLoaded(false)
      try {
        const data = await getMazes(page, pageSize, term)
        const mazeData = await Promise.all(
          data.data.map(async (element) => {
            const imageUrl = await fetchImage(element.fullS3Path)
            const thumbnailImageUrl = await fetchImage(element.thumbnailS3Path)
            return {
              ...element,
              imageUrl,
              thumbnailImageUrl,
            }
          })
        )
        setMazes(mazeData)
        setTotalMazes(data.total_items)
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setDataLoaded(true)
      }
    },
    [fetchImage, pageSize]
  )

  useEffect(() => {
    fetchData(currentPage - 1, searchTerm)
  }, [currentPage, searchTerm, fetchData])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  return (
    <div>
      {dataLoaded ? (
        mazes.length > 0 ? (
          <div>
            <div className="maze-container">
              {mazes.map((maze) => (
                <MazeCard
                  key={maze.id}
                  id={maze.id}
                  img={maze.imageUrl}
                  thumbImg={maze.thumbnailImageUrl}
                  title={maze.title}
                  titleFr={maze.titleFr}
                  desc={maze.description}
                  descFr={maze.descriptionFr}
                  instruction={maze.instruction}
                  instructionFr={maze.instructionFr}
                  enabled={maze.enabled}
                  gameLevel={maze.gameLevel}
                />
              ))}
            </div>
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={totalMazes}
              onChange={handlePageChange}
              showSizeChanger={false}
              style={{ marginTop: 10 }}
            />
          </div>
        ) : null
      ) : (
        <div className="spinner-container">
          <Spin size="large" />
        </div>
      )}
    </div>
  )
}

export default SectionMaze
