import { message } from 'antd'

import { fetchUtil as fetch, buildQuery } from 'src/utils/fetch'

export const get = async () => {
  try {
    const { data } = await fetch('/user-profile/abuseReport/reasons', {
      headers: {
        role: 'ADMIN',
      },
    })
    return data
  } catch (e) {
    message.error('Get Abuse Reason error!')
  }
}

export const deleteReason = async (id) => {
  try {
    const { data } = await fetch(`/user-profile/abuseReport/${id}`, {
      method: 'DELETE',
      headers: {
        role: 'ADMIN',
      },
    })
    return 'success'
  } catch (e) {
    message.error('Delete Abuse Reason error!')
    return 'failure'
  }
}

export const save = async ({ id, title, description }) => {
  try {
    const { data } = await fetch('/user-profile/abuseReport/save', {
      method: 'POST',
      body: JSON.stringify({
        title: title,
        description: description,
        id: id,
      }),
      headers: {
        role: 'ADMIN',
      },
    })
    return 'success'
  } catch (e) {
    message.error('Save Abuse Reason error!')
    return 'failure'
  }
}
