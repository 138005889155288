import { Result } from 'antd'
import { useNavigate } from 'react-router-dom'
import { LockOutlined } from '@ant-design/icons'

const Unauthorized = () => {
  const navigate = useNavigate()

  const handleBackToHome = () => {
    navigate('/')
  }

  return (
    <div
      className="unauthorized-page"
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Result
        icon={<LockOutlined style={{ fontSize: '48px', color: '#ff4d4f' }} />}
        status="403"
        title="Access Denied"
        subTitle="Sorry, you do not have the required privileges to view this page."
      />
    </div>
  )
}

export default Unauthorized
