import React, { useRef } from 'react'
import { Button, Modal, Typography } from 'antd'
import { useDrag, useDrop } from 'react-dnd'
import PrivateImage from 'src/components/PrivateImage'
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import s from './index.module.scss'

const { Text } = Typography

interface CarouselItemProps {
  game: Games
  categoryId: string
  onDrop: (drapId: string, dropId: string) => void
  
}

const CarouselItem: React.FC<CarouselItemProps> = ({
  game,
  categoryId,
  onDrop = () => null,
 
}) => {
  const ref = useRef(null)
  const dndType = `card_${categoryId}`
  const [, drag] = useDrag(() => ({
    type: dndType,
    item: {
      id: game.id,
    },
  }))
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: dndType,
      drop(item: any) {
        onDrop(item.id, game.id)
      },
      collect: (monitor) => {
        return {
          isOver: monitor.isOver({ shallow: true }),
        }
      },
    }),
    []
  )

  

  drop(drag(ref))

  

  return (
    <div
      
      className={s.carouselItem}
    >
      <div ref={ref} className={s.carouselItemCard}>
        <PrivateImage
          src={game.thumbnailS3Path}
          width={'100%'}
          height={140}
          preview={false}
        />
        <div className={s.carouselItemMeta}>
          <Text strong>{game.name}</Text>
          <Text italic type="secondary">
            Rating: {game.rating}
          </Text>
        </div>
        
      </div>
    </div>
  )
}
export default CarouselItem
