import { useState, useEffect } from 'react'
import { Divider, Table, Select, Tooltip } from 'antd'
import { getMonthlyWinners } from 'src/api/marketplace'
import { fetchCountryProvider } from 'src/api/authen'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import usePage from 'src/hooks/usePage'

const { Column } = Table

interface Winner {
  username: string
  pricePoints: number
  priceName: string
}

interface MonthlyWinners {
  year: string
  month: number
  first?: Winner
  second?: Winner
  third?: Winner
}

const MonthlyWinners = () => {
  const [providerCountry, setProviderCountry] = useState('TN')
  const [pageData, setPageData] = useState<{ data: MonthlyWinners[] }>({
    data: [],
  })
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchParams, setSearchParams] = useState({
    name: '',
    phone: '',
    email: '',
    marketPlaceName: '',
    country: '',
  })

  const [countries, setCountries] = useState([])

  const fetchData = async (page: number, size: number, providedCountry) => {
    setLoading(true)
    try {
      const response = await getMonthlyWinners(providedCountry, page - 1, size)
      setPageData({ data: response.data })
      setTotalRecords(response.total_items)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData(currentPage, pageSize, providerCountry)
  }, [currentPage, pageSize])

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const data = await fetchCountryProvider()
        const countryOptions = data.data.map((item) => ({
          label: item.countryName,
          value: item.countryCode,
        }))
        setCountries(countryOptions)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchCountry()
  }, [])

  const handleTableChange = (pagination: any) => {
    setCurrentPage(pagination.current)
    setPageSize(pagination.pageSize)
  }

  const handleProviderCountryChange = (value) => {
    setCurrentPage(1)
    setProviderCountry(value)
    fetchData(1, pageSize, value)
  }

  return (
    <>
      <Select
        placeholder="Select Country"
        value={providerCountry}
        onChange={handleProviderCountryChange}
        options={countries}
        style={{ marginLeft: '15px' }}
      />
      <Divider />
      <DndProvider backend={HTML5Backend}>
        <Table<MonthlyWinners>
          className="table-sorting"
          rowKey="id"
          dataSource={pageData.data}
          loading={loading}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            showSizeChanger: true,
            pageSizeOptions: ['10', '25', '50', '100'],
            total: totalRecords,
          }}
          onChange={handleTableChange}
          scroll={{ x: 'max-content' }}
        >
          <Column<MonthlyWinners> dataIndex="year" title="Year" />
          <Column<MonthlyWinners>
            title="Month"
            render={(record: MonthlyWinners) =>
              record.month.toString().toLowerCase()
            }
          />{' '}
          <Column<MonthlyWinners>
            title="1st Winner"
            render={(record: MonthlyWinners) => {
              const username = record.first?.username
              return username ? (
                <Tooltip title={username}>
                  <span>
                    {username.length > 10
                      ? `${username.substring(0, 10)}...`
                      : username}
                  </span>
                </Tooltip>
              ) : (
                '-'
              )
            }}
          />
          <Column<MonthlyWinners>
            title="1st Price"
            render={(record: MonthlyWinners) => {
              const priceName = record.first?.priceName
              return priceName ? (
                <Tooltip title={priceName}>
                  <span>
                    {priceName.length > 10
                      ? `${priceName.substring(0, 10)}...`
                      : priceName}
                  </span>
                </Tooltip>
              ) : (
                '-'
              )
            }}
          />
          <Column<MonthlyWinners>
            title="1st Price Point"
            render={(record: MonthlyWinners) =>
              record.first?.pricePoints || '-'
            }
          />
          <Column<MonthlyWinners>
            title="2nd Winner"
            render={(record: MonthlyWinners) => {
              const username = record.second?.username
              return username ? (
                <Tooltip title={username}>
                  <span>
                    {username.length > 10
                      ? `${username.substring(0, 10)}...`
                      : username}
                  </span>
                </Tooltip>
              ) : (
                '-'
              )
            }}
          />
          <Column<MonthlyWinners>
            title="2nd Price"
            render={(record: MonthlyWinners) => {
              const priceName = record.second?.priceName
              return priceName ? (
                <Tooltip title={priceName}>
                  <span>
                    {priceName.length > 10
                      ? `${priceName.substring(0, 10)}...`
                      : priceName}
                  </span>
                </Tooltip>
              ) : (
                '-'
              )
            }}
          />
          <Column<MonthlyWinners>
            title="2nd Price Point"
            render={(record: MonthlyWinners) =>
              record.second?.pricePoints || '-'
            }
          />
          <Column<MonthlyWinners>
            title="3rd Winner"
            render={(record: MonthlyWinners) => {
              const username = record.third?.username
              return username ? (
                <Tooltip title={username}>
                  <span>
                    {username.length > 10
                      ? `${username.substring(0, 10)}...`
                      : username}
                  </span>
                </Tooltip>
              ) : (
                '-'
              )
            }}
          />
          <Column<MonthlyWinners>
            title="3rd Price"
            render={(record: MonthlyWinners) => {
              const priceName = record.third?.priceName
              return priceName ? (
                <Tooltip title={priceName}>
                  <span>
                    {priceName.length > 10
                      ? `${priceName.substring(0, 10)}...`
                      : priceName}
                  </span>
                </Tooltip>
              ) : (
                '-'
              )
            }}
          />
          <Column<MonthlyWinners>
            title="3rd Price Point"
            render={(record: MonthlyWinners) =>
              record.third?.pricePoints || '-'
            }
          />
        </Table>
      </DndProvider>
    </>
  )
}

export default MonthlyWinners
