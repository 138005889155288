import { useState, useEffect, useRef } from 'react'
import {
  Button,
  Divider,
  Input,
  Typography,
  Switch,
  Select,
  Upload,
  Spin,
  message,
  Form,
} from 'antd'
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons'
import {
  saveEditMarketplace,
  getMarketplaceCategory,
} from 'src/api/marketplace'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import './EditMarketplace.css'
import { manualFetch } from 'src/utils/fetch'

const { Title, Text } = Typography

const EditMarketplace = () => {
  const { id } = useParams()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const initialImages = params.get('img') ? params.get('img').split(',') : []
  const originalImage = params.get('originalImages')
    ? params.get('originalImages').split(',')
    : []
  const [editedTitle, setEditedTitle] = useState(params.get('subTitle'))
  const [editedTitleFr, setEditedTitleFr] = useState(params.get('subTitleFr'))
  const [editedDesc, setEditedDesc] = useState(params.get('desc'))
  const [editedDescFr, setEditedDescFr] = useState(params.get('descFr'))
  const [editedName, setEditedName] = useState(params.get('name'))
  const [editedNameFr, setEditedNameFr] = useState(params.get('nameFr'))
  const [editedPoint, setEditedPoint] = useState(params.get('points'))
  const [editedPrice, setEditedPrice] = useState(params.get('price'))
  const [categories, setCategories] = useState(params.get('category'))
  const [country, setCountry] = useState(params.get('country'))

  const [editImages, setEditImages] = useState(initialImages)
  const newImages = useRef([])
  const [category, setCategory] = useState([])
  const [enabled, setEnabled] = useState(params.get('enabled') === 'true')
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getMarketplaceCategory()
        const categoryOptions = data.map((item) => ({
          label: removeUnderscores(item),
          value: item.toUpperCase(),
        }))
        setCategory(categoryOptions)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
    fetchAllImages()

    const setNewImages = async () => {
      if (initialImages.length > 0) {
        for (let i = 0; i < initialImages.length; i++) {
          const response = await fetch(initialImages[i])
          const blob = await response.blob()
          const file = new File([blob], 'example' + i + '.png', {
            type: blob.type,
          })
          newImages.current = [...newImages.current, file]
        }
      }
    }
    setNewImages()
  }, [])
  const fetchImage = async (url) => {
    const response = await manualFetch(url, {
      headers: {
        accept: 'image/*',
      },
    })
    const imageBlob = await response.blob()
    return URL.createObjectURL(imageBlob)
  }

  const fetchAllImages = async () => {
    try {
      const imagePromises = originalImage.map((url) => fetchImage(url))
      const fetchedImages = await Promise.all(imagePromises)
      setEditImages(fetchedImages)
    } catch (error) {
      console.error('Error fetching images:', error)
    }
  }

  const removeUnderscores = (text) => {
    return text.replace(/_/g, ' ')
  }

  const navigate = useNavigate()

  const onChangeSwitch = (checked) => {
    setEnabled(checked)
  }

  const handleSave = async () => {
    const values = await form.validateFields()
    setLoading(true)
    saveEditMarketplace(
      id,
      values.editedName,
      values.editedNameFr,
      values.points,
      values.price,
      values.editedTitle,
      values.editedTitleFr,
      values.editedDesc,
      values.editedDescFr,
      enabled,
      categories,
      newImages.current,
      'IN'
    ).then(() => {
      navigate('/marketplace')
    })
  }
  const defaultValuesOption = categories

  const onCancel = () => {
    navigate('/marketplace')
  }

  const handleChange = (value) => {
    setCategories(value)
  }

  const handleDeleteImage = (index) => {
    const file = editImages.filter((_, i) => i !== index)
    newImages.current = newImages.current.filter((_, i) => i !== index)
    setEditImages(file)
  }

  const handleAddImage = ({ file, fileList }) => {
    if (file && file.originFileObj) {
      const fileAlreadyAdded = newImages.current.some(
        (img) => img === file.originFileObj
      )

      if (fileAlreadyAdded) {
        return
      }

      const reader = new FileReader()
      reader.onload = () => {
        const newImageURL = URL.createObjectURL(file.originFileObj)

        setEditImages((prevImages) => [...prevImages, newImageURL])

        newImages.current = [...newImages.current, file.originFileObj]
      }
      reader.readAsDataURL(file.originFileObj)
    } else {
      message.error('Invalid file object.')
    }
  }

  const handlePointChange = (e) => {
    const value = e.target.value.replace(/\D/g, '')
    setEditedPoint(value)
  }

  return (
    <div className="edit-metaverse-container">
      <Title level={3}>Edit Marketplace</Title>
      <Divider />
      <Form
        form={form}
        layout="horizontal"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 20 }}
      >
        <Form.Item
          label="Name"
          name="editedName"
          initialValue={params.get('name')}
          rules={[{ required: true, message: 'Please enter the name' }]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item
          label="French Name"
          name="frenchName"
          initialValue={params.get('nameFr')}
          rules={[{ required: true, message: 'Please enter the French name' }]}
        >
          <Input placeholder="French Name" />
        </Form.Item>
        <Form.Item
          label="Points:"
          name="points"
          initialValue={params.get('points')}
          rules={[{ required: true, message: 'Please input the points' }]}
        >
          <Input
            placeholder="Point"
            type="number"
            onChange={(e) => {
              const value = e.target.value
              const newValue = value.replace(/[^\d]/g, '')
              form.setFieldsValue({ editedPoint: newValue })
            }}
          />
        </Form.Item>
        <Form.Item
          label="Price:"
          name="price"
          initialValue={editedPrice}
          rules={[{ required: true, message: 'Please enter the price' }]}
        >
          <Input
            placeholder="Price"
            type="number"
            onChange={(e) => setEditedPrice(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label="Sub Title"
          name="editedTitle"
          initialValue={params.get('subTitle')}
          rules={[{ required: true, message: 'Please enter the sub title' }]}
        >
          <Input.TextArea placeholder="SubTitle" />
        </Form.Item>
        <Form.Item
          label="French Sub Title"
          name="editedTitleFr"
          initialValue={params.get('subTitleFr')}
          rules={[
            { required: true, message: 'Please enter the French sub title' },
          ]}
        >
          <Input.TextArea placeholder="French SubTitle" />
        </Form.Item>
        <Form.Item
          label="Description"
          name="editedDesc"
          initialValue={params.get('desc')}
          rules={[{ required: true, message: 'Please enter the description' }]}
        >
          <Input.TextArea placeholder="Description" />
        </Form.Item>
        <Form.Item
          label="Description French"
          name="editedDescFr"
          initialValue={params.get('descFr')}
          rules={[
            { required: true, message: 'Please enter the French description' },
          ]}
        >
          <Input.TextArea placeholder="Description French" />
        </Form.Item>
        <Form.Item
          label="Category"
          name="category"
          initialValue={defaultValuesOption}
          rules={[{ required: true, message: 'Please select a category' }]}
        >
          <Select
            allowClear
            style={{
              width: '100%',
            }}
            placeholder="Please select Category"
            defaultValue={defaultValuesOption}
            onChange={handleChange}
            options={category}
          />
        </Form.Item>
        <Form.Item
          label="Enabled"
          name="enabled"
          valuePropName="checked"
          initialValue={enabled}
          rules={[
            { required: true, message: 'Please select the enabled state' },
          ]}
        >
          <Switch onChange={onChangeSwitch} />
        </Form.Item>

        <div className="inline-form-item image-row">
          <div className="images-wrapper">
            {editImages.map((imageUrl, index) => (
              <div key={index} className="image-container">
                <img
                  src={imageUrl}
                  className="card-image"
                  alt={`Image ${index}`}
                />

                <DeleteOutlined
                  onClick={() => handleDeleteImage(index)}
                  className="delete-icon1"
                />
              </div>
            ))}
          </div>
        </div>
        <div className="add-image">
          <Upload
            onChange={handleAddImage}
            showUploadList={false}
            accept="image/*"
          >
            <Button icon={<UploadOutlined />}>Add Image</Button>
          </Upload>
        </div>
        <div className="button-container" style={{ marginTop: 16 }}>
          <Button className="save-button" onClick={handleSave}>
            {loading ? <Spin /> : 'Save'}
          </Button>
          <Button className="cancel-button" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default EditMarketplace
