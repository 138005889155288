import React, { useState, useEffect } from 'react'
import { Input, Divider, Button, Modal, Typography, Select } from 'antd'
import SectionGame from './sectiongame'
import { RobotOutlined, TrophyOutlined } from '@ant-design/icons'
import {
  getGamesByCategory,
  getGameofTheDay,
  saveGameofTheDay,
} from 'src/api/game'
import './Gameindex.css'
import { useNavigate } from 'react-router-dom'

const { Search } = Input

const Game = () => {
  const navigate = useNavigate()

  const [searchTerm, setSearchTerm] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [game, setGame] = useState([])
  const userType = [
    { label: 'User', value: 'SUBSCRIBE' },
    { label: 'Guest', value: 'GUEST' },
  ]
  const [gameOfTheDay, setGameOfTheDay] = useState(null)
  const [selectedUserType, setSelectedUserType] = useState('SUBSCRIBE')

  const handleSearch = (term) => {
    setSearchTerm(term)
  }

  const setGameOfTheWeek = () => {
    setSelectedUserType('SUBSCRIBE')
    const fetchGameOfTheDay = async () => {
      try {
        const data = await getGameofTheDay()
        setGameOfTheDay(data.data.id)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchGameOfTheDay()
    setIsModalVisible(true)
  }

  const handleModalOk = () => {
    saveGameofTheDay(selectedUserType, gameOfTheDay)
    setIsModalVisible(false)
  }

  const handleModalCancel = () => {
    setIsModalVisible(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getGamesByCategory(0, 200, '')
        const category = data.data.map((game) => ({
          label: game.name,
          value: game.id,
        }))
        setGame(category)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
  }, [])

  const handleSelectChange = (value) => {
    setGameOfTheDay(value)
  }
  const handleSelectUserType = (value) => {
    setSelectedUserType(value)
  }

  return (
    <div>
      <div className="container">
        <Search
          placeholder="Search by name"
          onSearch={handleSearch}
          style={{ width: '16.5%' }}
          allowClear
        />
        <div className="button-group">
          <Button
            type="primary"
            onClick={setGameOfTheWeek}
            className="add-button"
          >
            <RobotOutlined />
            Set Game of the Week
          </Button>
        </div>
      </div>
      <Divider style={{ margin: '10px 0' }} />
      <div className="sections-container">
        <SectionGame searchTerm={searchTerm} />
      </div>

      <Modal
        title="Set Game of the Week"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <div className="edit-games-container">
          <div>
            <Select
              allowClear
              style={{ width: '100%' }}
              placeholder="Please select Type"
              value={selectedUserType}
              onChange={handleSelectUserType}
              options={userType}
            />
          </div>
          <div>
            <Select
              allowClear
              showSearch
              style={{ width: '100%' }}
              placeholder="Please select game"
              value={gameOfTheDay}
              onChange={handleSelectChange}
              options={game}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}

Game.displayName = 'Game'

export default Game
