import { Divider, Table } from 'antd'
import React from 'react'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import DragTableRow from 'src/components/DragTableRow'
import usePage from 'src/hooks/usePage'
import { searchMazeReport } from 'src/api/reports'
import MazeReportSearch from './mazeReportSearch'
import { mazeMapping } from 'src/constants'

const { Column } = Table

const MazeReportPage: React.FC = () => {
  const { pageData, onQuery, appendSearchForm, submitSearchForm, fetchList } =
    usePage({
      getData: searchMazeReport,
      searchData: searchMazeReport,
      searchType: 'POST',
    })

  

  return (
    <>
      <Divider />
      <DndProvider backend={HTML5Backend}>
        <Table
          className="table-sorting"
          rowKey="eventId"
          dataSource={pageData.data}
          loading={pageData.loading}
          pagination={{
            current: parseInt(pageData.query.page) + 1,
            total: pageData.total,
            pageSize: parseInt(pageData.query.pageSize) || 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '25', '50', '100'],
            onChange: (p, ps) => {
              appendSearchForm({ buttonAction: '' })
              onQuery({ page: (p - 1).toString(), pageSize: ps.toString() })
            },
          }}
          title={() => (
            <MazeReportSearch
              appendSearchForm={appendSearchForm}
              submitSearchForm={submitSearchForm}
            />
          )}
          components={{
            body: {
              row: DragTableRow,
            },
          }}
        >
          <Column
            width={120}
            dataIndex="metaverseRoomId"
            title="Maze Room"
            render={(mazeRoomId) => mazeMapping[mazeRoomId] || 'Unknown'}
          />
          <Column dataIndex="userName" title="User Name" />
          <Column dataIndex="gender" title="Gender" />
          <Column dataIndex="age" title="Age" />
          <Column dataIndex="country" title="Country" />

          <Column dataIndex="deviceType" title="Device Type" />

          <Column
            dataIndex="eventDuration"
            title="Event Duration"
            render={(text) => <span>{text} sec</span>}
          />
          <Column dataIndex="eventDate" title="Event Date" />
          <Column dataIndex="clientName" title="Client Name" />
          <Column dataIndex="providerCountry" title="Provider" />
        </Table>
      </DndProvider>
    </>
  )
}

MazeReportPage.displayName = 'MazeReportPage'
export default MazeReportPage
