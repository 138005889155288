import { isEmpty, omit, pickBy } from 'ramda'
import React, { useCallback, useEffect, useState } from 'react'
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import getHash from 'object-hash'

interface PageData {
  loading: boolean
  data: any
  total?: number
  query?: Record<string, any>
}

type SearchParams = Record<string, string | number>

interface UsePageProps {
  getData: (
    query: Record<string, any>
  ) => Promise<{ data: any[]; total_items: number }>
  searchData?: (
    query: Record<string, any>
  ) => Promise<{ data: any[]; total_items: number }>
  searchType?: 'GET' | 'POST'
  query?: Record<string, any>
}

const usePage = ({
  getData,
  searchData,
  query = {},
  searchType = 'GET',
}: UsePageProps) => {
  const defaultData: PageData = {
    loading: false,
    data: [],
    total: 0,
    query: { page: 0, size: 10, ...query },
  }
  const [pageData, setPageData] = useState<PageData>(defaultData)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const queryObj = React.useRef<Record<string, any>>(
    Object.fromEntries(searchParams)
  )
  const searchForm = React.useRef<Record<string, any>>({})

  const fetchList = useCallback(async () => {
    setPageData((p) => ({ ...p, loading: true }))
    const query = {
      ...defaultData.query,
      ...omit(['searchHash'], queryObj.current),
    }
    const shouldSearch =
      searchData &&
      (Object.keys(query).some((i) => i !== 'page' && i !== 'size') ||
        Object.keys(searchForm.current).length > 0)
    let res = {} as any
    if (shouldSearch) {
      const searchPayload =
        searchType === 'GET' ? query : { ...query, ...searchForm.current }
      res = await searchData(searchPayload)
    } else {
      res = await getData(query)
    }
    setPageData({
      loading: false,
      data: res?.data,
      total: res?.total_items,
      query,
    })
  }, [searchParams, pageData])

  useEffect(() => {
    queryObj.current = Object.fromEntries(searchParams)
    fetchList()
  }, [searchParams])

  const onQuery = React.useCallback(
    (params?: SearchParams) => {
      const query = { ...queryObj.current, ...(params || {}) }
      const validQuery: Record<string, string> = pickBy(
        (value) => !(value === undefined || isEmpty(value)),
        query
      )
      navigate({
        search: createSearchParams(validQuery).toString(),
      })
    },
    [searchParams]
  )

  const appendQuery = React.useCallback(
    (params: SearchParams) => {
      const query = { ...queryObj.current, ...params }
      const validQuery: Record<string, string> = pickBy(
        (value) => !(value === undefined || isEmpty(value)),
        query
      )
      queryObj.current = validQuery
    },
    [searchParams]
  )

  const appendSearchForm = React.useCallback(
    (params: SearchParams) => {
      const form = { ...searchForm.current, ...params }
      const validForm: Record<string, string> = pickBy(
        (value) => !(value === undefined || isEmpty(value)),
        form
      )
      searchForm.current = validForm
    },
    [searchForm.current]
  )

  const submitSearchForm = React.useCallback(() => {
    const searchHash =
      Object.keys(searchForm.current).length > 0
        ? getHash(searchForm.current)
        : ''
    onQuery({ page: 0, searchHash })
  }, [searchForm.current])

  return {
    pageData,
    onQuery,
    appendQuery,
    appendSearchForm,
    submitSearchForm,
    queryObj: queryObj.current,
    fetchList,
  }
}

export default usePage
