import { Card, Spin, Carousel, Modal, message } from 'antd'
import { useState, useEffect, useCallback } from 'react'
import { EditOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import './Guyscard.css'

const GuysCard = ({
  id,
  img,
  name,
  nameFr,
  desc,
  descFr,
  enabled,
  instruction,
  instructionFr,
  title,
  titleFr,
  category,
  thumbnailImage,
}) => {
  const navigate = useNavigate()

  const [dataLoaded, setDataLoaded] = useState(true)

  const params = new URLSearchParams()
  params.append('img', img)
  params.append('name', name)
  params.append('nameFr', nameFr)
  params.append('desc', desc)
  params.append('descFr', descFr)
  params.append('enabled', enabled)
  params.append('instruction', instruction)
  params.append('instructionFr', instructionFr)
  params.append('title', title)
  params.append('titleFr', titleFr)
  params.append('category', category)
  params.append('thumbnailImage', thumbnailImage)

  const handleClickOpen = () => {
    navigate(`/guys/edit/${id}?${params.toString()}`)
  }

  return (
    <div>
      {dataLoaded ? (
        <Card
          hoverable
          className={`card-container ${enabled ? '' : 'faded-card'}`}
          cover={
            <div className="card-cover">
              <img alt={title} src={img} className="card-image" />
              <EditOutlined onClick={handleClickOpen} className="edit-icon" />
            </div>
          }
        >
          <Card.Meta
            title={title}
            description={<div className="card-description">{desc}</div>}
          />
        </Card>
      ) : (
        <div className="spinner-container">
          <Spin size="large" />
        </div>
      )}
    </div>
  )
}

export default GuysCard
